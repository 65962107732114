import clsx from "clsx";
import {Box, Card, Grid, makeStyles, Typography,} from "@material-ui/core";
import React from "react";
import {empty} from "../../../../../state/utils/Common";
import Loading from "../../../../atoms/Loading";
import {commonStyles} from "../../../../theme/user/styles/common";
import globalTheme from "../../../../theme/globalTheme";
import AutoSizer from "react-virtualized-auto-sizer";
import {FixedSizeList} from "react-window";
import {AddressesListRow} from "./AddressListRow";

/**
 * コンポーネントスティール
 * @type {(props?: any) => ClassNameMap<"paper"|"root">}
 */
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        border: "1px #CCCCCC solid",
        boxShadow: "none"
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

/**
 * /address コンポーネント (user/address 一覧)
 *
 * @param {Object} className - 親からのスティール
 * @param {boolean} loading - 一覧ロードの状態。
 * @param {Object} addresses - 取得した住所データ
 * @param {*} viewRegisterToggle - 新規住所モーダル表示・非表示状態。
 * @param {*} viewDeleteToggle - 住所削除モーダル表示・非表示状態。
 * @param {number} currentPage - ページ番号 ※ まだ使わていない。
 * @param {*} t - Translation object - 翻訳ファイル
 * @returns {JSX.Element}
 * @constructor
 */
const AddressesList = (
    {
        className,
        loading,
        addresses,
        viewRegisterToggle,
        viewDeleteToggle,
        currentPage,
        t
    }) => {
    // アプリケーションテーマ
    const classes = useStyles();
    const common = commonStyles()
    const _globalTheme = globalTheme._default();

    // 住所一覧の編集選択アクション
    function handleEditClick(id) {
        viewRegisterToggle(id)
    }

    // 住所一覧の削除選択
    function handleDeleteClick(id) {
        viewDeleteToggle(id)
    }

    return (
        <Card
            className={clsx(classes.root, className)}
        >
            <Box p={2}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                            style={{fontWeight: 900}}
                        >
                            {t('addresses.list')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                            style={{'textAlign': 'right'}}
                        >
                            {t('general.list_amount', {amount: !empty(addresses) ? addresses.length : 0})}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Grid container className={common.tableHeader}>
                <Grid item xs={2}>
                    <Typography style={{fontWeight: "bold"}}>
                        {t('addresses.list_headers.postal_code')}
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography style={{fontWeight: "bold"}}>
                        {t('addresses.list_headers.address')}
                    </Typography>
                </Grid>
                <Grid item xs={1} style={{textAlign: "center"}}>
                    <Typography style={{fontWeight: "bold"}}>
                        {t('addresses.list_headers.edit')}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography style={{fontWeight: "bold", textAlign: "center"}}>
                        {t('addresses.list_headers.delete')}
                    </Typography>
                </Grid>
            </Grid>
            {loading === true ?
                <Box className={_globalTheme.list} display="flex" alignItems="center">
                    <Grid container
                          direction="row"
                          justify="center"
                          alignItems="center"
                          data-testid="loading"
                    >
                        <Grid item xs={12}>
                            <Loading/>
                        </Grid>
                    </Grid>
                </Box>
                : !empty(addresses) &&
                <Box className={_globalTheme.list} data-testid="has-addresses">
                    <AutoSizer>
                        {({height, width}) => (
                            <FixedSizeList
                                width={width}
                                height={height}
                                itemCount={addresses.length}
                                itemSize={45}
                                itemData={{
                                    addresses: addresses,
                                    t: t,
                                    handleEditClick: (id) => handleEditClick(id),
                                    handleDeleteClick: (id) => handleDeleteClick(id)
                                }}
                            >
                                {AddressesListRow}
                            </FixedSizeList>
                        )}
                    </AutoSizer>
                </Box>
            }
        </Card>
    );
};

export default AddressesList;

/***
 * /////////////////////////////////////////
 * フォーム妥当性確認  (Using the validate.js library)
 * -------------------------
 *
 * /service_contracts 用のフォームバリデータです。
 *
 * Validate.js と /util/ValidateUtil.js を使ってバリデーションを設定します。
 * バリデーションルールについてはこちらをご覧ください。
 * https://validatejs.org/#validators
 *
 * すべてのフォーム検証属性はここに格納され、ビューコンポーネントから直接呼び出されます。
 * //////////////////////////////////////////
 */

/**
 * 連絡先妥当性確認
 */
export const terminateServiceContractValidation = {
    cancel_prefferd_ym: {
        presence: true,
        datetime: {
            dateOnly: true
        }
    },

    cancel_reasons1: {
        presence: true,
        typeOfArray: {
            numericality: true
        }
    },
    cancel_reason2: {
        presence: false
    },
    cancel_reason3: {
        presence: false
    },
}

export const terminateServiceContractWithoutPreferredDay = {
    cancel_reasons1: {
        presence: true,
        typeOfArray: {
            numericality: true
        }
    },
    cancel_reason2: {
        presence: false
    },
    cancel_reason3: {
        presence: false
    },
}

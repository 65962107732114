import React from 'react';
import {NavLink as RouterLink, useLocation} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Button, ListItem, makeStyles} from '@material-ui/core';
import {empty} from "../../../../state/utils/Common";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {managerRequestOperations} from "../../../../state/ducks/manager/managerRequests";
import {requestOperations} from "../../../../state/ducks/user/requests";

/**
 * コンポーネントスタイル
 */
const useStyles = makeStyles((theme) => ({
    item: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0
    },
    button: {
        color: theme.palette.text.secondary,
        fontWeight: theme.typography.fontWeightMedium,
        justifyContent: 'flex-start',
        border: "None",
        letterSpacing: 0,
        padding: '8px 8px',
        textTransform: 'none',
        width: '100%',
        '&:hover': {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.highlight,
            '& $title': {
                fontWeight: theme.typography.fontWeightHeavy
            },
            '& $icon': {
                color: theme.palette.text.primary
            }
        }
    },
    icon: {
        marginRight: theme.spacing(1)
    },
    title: {
        marginRight: 'auto',
        fontSize: '13px'
    },
    active: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.highlight,
        border: "None",
        '& $title': {
            fontWeight: theme.typography.fontWeightHeavy
        },
        '& $icon': {
            color: theme.palette.text.primary
        }
    }
}));



/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        sideBarContractName: state.serviceContract.sideBarContractName,
        allowedDelete: state.serviceContract.allowedDelete
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    sendContractNameToSearchRequest: requestOperations.sendContractNameToSearchRequest,
}

/**
 * シングルナビアイテムコンポーネント
 */
const _navItem = (
    {
        className,
        href,
        icon: Icon,
        activeOverride,
        title,
        isActive,
        id,
        sendContractNameToSearchRequest,
        sideBarContractName,
        allowedDelete,
        currentLevel,
        activeLevel,
        buttonStyles,
        logoutFunc = null,
        ...rest
    }) => {
    const classes = useStyles();
    const location = useLocation();
    return (
        <ListItem
            className={clsx(classes.item, className)}
            disableGutters
            {...rest}
        >
            {!empty(logoutFunc) ?
                <Button
                    style={buttonStyles}
                    activeClassName={classes.active}
                    className={classes.button}
                    onClick={() => logoutFunc()}
                >
                    {Icon && (
                        <Icon
                            className={classes.icon}
                            size="20"
                        />
                    )}
                    <span className={classes.title}>{title}</span>
                </Button>
            : activeOverride && location.pathname.includes(activeOverride) ?
                <Button
                    id={id}
                    style={buttonStyles}
                    disabled={id === 'requestsUrl' && empty(sideBarContractName)}
                    activeClassName={clsx({
                        [classes.active]: true,
                    })}
                    className={classes.button}
                    onClick={(e) => {
                        if (e.currentTarget.id === 'requestsUrl') {
                            sendContractNameToSearchRequest(sideBarContractName)
                        }
                    }}
                    component={RouterLink}
                    to={href}
                >
                    {Icon && (
                        <Icon
                            className={classes.icon}
                            size="20"
                        />
                    )}
                    <span className={classes.title}>{title}</span>
                </Button>
                :

                <Button
                    id={id}
                    style={buttonStyles}
                    activeClassName={clsx({
                        [classes.active]: isActive
                    })}
                    className={classes.button}
                    component={RouterLink}
                    disabled={(id === 'requestsUrl' && empty(sideBarContractName)) || (id === 'canCancelLink' && allowedDelete === false)}
                    onClick={(e) => {
                        if (e.currentTarget.id === 'requestsUrl') {
                            sendContractNameToSearchRequest(sideBarContractName)
                        }
                    }}
                    to={href}>
                    {Icon && (
                        <Icon
                            className={classes.icon}
                            size="20"
                        />
                    )}
                    <span className={classes.title}>{title}</span>
                </Button>
            }
        </ListItem>
    );
};

const NavItem = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_navItem)

export default NavItem;

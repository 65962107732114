import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {useAuth0} from "@auth0/auth0-react";
import React, {useEffect} from "react";
import {rootOperations} from "../../../../state/ducks/root";
import userSideBarLinks from "../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json"
import {empty} from "../../../../state/utils/Common";
import Auth0Utils from "../../../../state/utils/Auth0Utils";
import {requestOperations} from "../../../../state/ducks/user/requests";
import {useHistory, useParams} from "react-router";
import RequestDetailView from "../../../components/user/requests/detail/Page";
import {managerRequestOperations} from "../../../../state/ducks/manager/managerRequests";


/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,

        requestDetail: state.request.requestDetail,
        requestDetailLoading: state.request.requestDetailLoading,

        isCancelConfirmationOpen: state.request.isCancelConfirmationOpen,
        cancelRequestLoading: state.request.cancelRequestLoading,
        cancelRequestError: state.request.cancelRequestError,

        isCancelConfirmedModalOpen: state.request.isCancelConfirmedModalOpen
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    loadSidebarItems: rootOperations.loadSidebarItems,
    requestsDetailLoad: requestOperations.requestsDetailLoad,

    toggleCancelConfirmation: requestOperations.toggleCancelConfirmation,
    cancelRequestSend: requestOperations.cancelRequestSend
}

/***
 * ///////////////////////////////////////////////////////////
 * CONTAINER VS COMPONENT (コンテイナ対コンポーネント)
 * --------------------------------
 * コンテナは、任意のアクションを *実行* し、任意の redux ストアやオペレーションをロードして、コンポーネントにプッシュします。
 * コンポーネントは、可能な限りダムでなければなりません。つまり、目に見えるデータのみを表示する必要があります。
 * ///////////////////////////////////////////////////////////
 */

/*
通常ユーザー側の申込詳細コンテイナー
*/
const _requestsDetailContainer = (
    {
        managerProfile,
        loadSidebarItems,
        requestsDetailLoad,
        requestDetail,
        requestDetailLoading,

        isCancelConfirmationOpen,
        cancelRequestLoading,
        cancelRequestError,

        toggleCancelConfirmation,
        cancelRequestSend,

        isCancelConfirmedModalOpen,

        t
    }) => {
    // auth0 アクセストークン取得
    const {getAccessTokenSilently} = useAuth0();
    let {rid} = useParams();
    const history = useHistory();
    // 利用契約一覧をAPIから取得する。
    useEffect(() => {
        // ログインしたユーザープロフィール取得したかどうかのチェック
        if (!empty(managerProfile)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    },
                );
                requestsDetailLoad(access_token, managerProfile?.account?.uuid, rid)
            })()
        }
    }, [rid, getAccessTokenSilently, requestsDetailLoad, managerProfile])
    // 利用契約一覧をAPIから取得する。
    useEffect(() => {
        loadSidebarItems(userSideBarLinks.navigation.requests)
    }, [loadSidebarItems])
    return (
        <RequestDetailView
            managerProfile={managerProfile}
            requestDetailLoading={requestDetailLoading}
            requestDetail={requestDetail}
            requestId={rid}
            isCancelConfirmationOpen={isCancelConfirmationOpen}
            cancelRequestLoading={cancelRequestLoading}
            cancelRequestError={cancelRequestError}
            history={history}
            toggleCancelConfirmation={toggleCancelConfirmation}
            cancelRequestSend={cancelRequestSend}
            isCancelConfirmedModalOpen={isCancelConfirmedModalOpen}
            t={t}
        />
    )
}

/**
 *
 * * Redux
 * * i18next Translations
 * を
 * UserView コンポーネントに追加する。
 */
const RequestsDetailContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_requestsDetailContainer)

export default RequestsDetailContainer;

import React from 'react';
import {Box, Grid, makeStyles, Typography} from '@material-ui/core';
import globalTheme from "../../../../../../theme/globalTheme";
import clsx from "clsx";

/**
 * /manager/notices コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    },
    button: {
        fontWeight: 900,
    },
    strong: {
        fontWeight: "bold"
    }
}));
/**
 *
 * /noticesのメインコンポーネント
 * @param managerLoadNoticeList
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const BasicInformationQuoteSegment = (
    {
        requestId,
        quoteId,
        requestQuoteLoading,
        requestQuote,
        t
    }) => {
    const classes = useStyles();
    const globalClass = globalTheme._default();
    return (
        <Box className={clsx(classes.root)}>
            <Typography variant="h4"> {t('requests.detail.quotes.basic_information')}</Typography>
            <Grid container style={{marginTop: 26}}>
                <Grid item xs={4} sm={1}>
                    <Typography variant="body1"
                                className={classes.strong}>{t('requests.detail.quotes.quote_name')}</Typography>
                </Grid>
                <Grid item xs={8} sm={11} className={globalClass.detail_items}>
                    <Typography variant="body1">{ requestQuote.name }</Typography>
                </Grid>
            </Grid>
            <Grid container style={{marginTop: 15}}>
                <Grid item xs={4} sm={1}>
                    <Typography variant="body1"
                                className={classes.strong}>{t('requests.detail.quotes.quote_number')}</Typography>
                </Grid>
                <Grid item xs={8} sm={11} className={globalClass.detail_items}>
                    <Typography variant="body1">{ requestQuote.number }</Typography>
                </Grid>
            </Grid>
            <Grid container style={{marginTop: 15}}>
                <Grid item xs={4} sm={1}>
                    <Typography variant="body1"
                                className={classes.strong}>{t('requests.detail.quotes.quote_status')}</Typography>
                </Grid>
                <Grid item xs={8} sm={11} className={globalClass.detail_items}>
                    <Typography variant="body1">{ requestQuote.status_name}</Typography>
                </Grid>
            </Grid>
            <Grid container style={{marginTop: 15}}>
                <Grid item xs={4} sm={1}>
                    <Typography variant="body1"
                                className={classes.strong}>{t('requests.detail.quotes.quote_day')}</Typography>
                </Grid>
                <Grid item xs={8} sm={11} className={globalClass.detail_items}>
                    <Typography variant="body1">{ requestQuote.quote_at }</Typography>
                </Grid>
            </Grid>
            <Grid container style={{marginTop: 15}}>
                <Grid item xs={4} sm={1}>
                    <Typography variant="body1"
                                className={classes.strong}>{t('requests.detail.quotes.quote_contract_start_date')}</Typography>
                </Grid>
                <Grid item xs={8} sm={11} className={globalClass.detail_items}>
                    <Typography variant="body1">{ requestQuote.start_at }</Typography>
                </Grid>
            </Grid>
            <Grid container style={{marginTop: 15}}>
                <Grid item xs={4} sm={1}>
                    <Typography variant="body1"
                                className={classes.strong}>{t('requests.detail.quotes.quote_contract_duration')}</Typography>
                </Grid>
                <Grid item xs={8} sm={11} className={globalClass.detail_items}>
                    <Typography variant="body1">{ requestQuote.period }ヶ月</Typography>
                </Grid>
            </Grid>
            <Grid container style={{marginTop: 15}}>
                <Grid item xs={4} sm={1}>
                    <Typography variant="body1"
                                className={classes.strong}>{t('requests.detail.quotes.quote_condition')}</Typography>
                </Grid>
                <Grid item xs={8} sm={11} className={globalClass.detail_items}>
                    <Typography variant="body1">{ requestQuote.payment_term }</Typography>
                </Grid>
            </Grid>
        </Box>
    );
}

export default BasicInformationQuoteSegment;

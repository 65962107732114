import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import React from "react";
import applicationCategories from "../../../../../state/utils/data/application_category.json";
import applicationStatus from "../../../../../state/utils/data/application_status.json";
import RequestsSearchBox from "../../../../components/user/requests/search/RequestsSearchBox";
import {requestOperations} from "../../../../../state/ducks/user/requests";


/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,
        searchFormInformation: state.request.searchFormInformation
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    loadRequestSearch: requestOperations.loadRequestSearch,
    formEditEvent: requestOperations.formEditEvent
}

/***
 * ///////////////////////////////////////////////////////////
 * CONTAINER VS COMPONENT (コンテイナ対コンポーネント)
 * --------------------------------
 * コンテナは、任意のアクションを *実行* し、任意の redux ストアやオペレーションをロードして、コンポーネントにプッシュします。
 * コンポーネントは、可能な限りダムでなければなりません。つまり、目に見えるデータのみを表示する必要があります。
 * ///////////////////////////////////////////////////////////
 */

/*
利用契約 -> 連絡先コンテイナ
 */
const _SearchContainer = (
    {
        managerProfile,
        loadRequestSearch,
        searchFormInformation,
        formEditEvent,
        t
    }) => {

    // このステートをReducksに入れたらREDUX-DEVTOOLSがクラッシュするから、こちらのコンポーネントで検索非表示・表示ステートを管理する。
    const [anchorEl, setAnchorEl] = React.useState(null);

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl);
    return (
        <RequestsSearchBox
            LoadNoticeList={handleClick}
            searchFieldOpen={open}
            searchFieldCloseEvent={handleClose}
            anchorEl={anchorEl}
            managerProfile={managerProfile}
            applicationCategories={applicationCategories}
            applicationStatus={applicationStatus}
            loadRequestSearch={loadRequestSearch}
            searchFormInformation={searchFormInformation}
            formEditEvent={formEditEvent}
            t={t}
        />
    )
}


/**
 *
 * * Redux
 * * i18next Translations
 * を
 * UserView コンポーネントに追加する。
 */
const SearchContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_SearchContainer)

export default SearchContainer;

import clsx from "clsx";
import {Box, Card, Grid, makeStyles,} from "@material-ui/core";
import React from "react";
import {empty} from "../../../../../state/utils/Common";
import Loading from "../../../../atoms/Loading";
import {commonStyles} from "../../../../theme/user/styles/common";
import globalTheme from "../../../../theme/globalTheme";
import {NotificationsRow} from "./NotificationsRow";

/**
 * コンポーネントスティール
 * @type {(props?: any) => ClassNameMap<"paper"|"root">}
 */
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        border: "1px #CCCCCC solid",
        boxShadow: "none"
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

/**
 * /notification コンポーネント (user/notification 一覧)
 *
 * @param {Object} className - 親からのスティール
 * @param {boolean} loading - 一覧ロードの状態。
 * @param {Object} notifications - 取得した住所データ
 * @param {*} t - Translation object - 翻訳ファイル
 * @returns {JSX.Element}
 * @constructor
 */
const NotificationsList = (
    {
        className,
        loading,
        notifications,
        t
    }) => {
    // アプリケーションテーマ
    const classes = useStyles();
    const common = commonStyles()
    const _globalTheme = globalTheme._default();

    return (
        <Card
            className={clsx(classes.root, className)}
        >
            {loading === true ?
                <Box className={_globalTheme.list} display="flex" alignItems="center">
                    <Grid container
                          direction="row"
                          justify="center"
                          alignItems="center"
                          data-testid="loading"
                    >
                        <Grid item xs={12}>
                            <Loading/>
                        </Grid>
                    </Grid>
                </Box>
                : !empty(notifications) &&
                notifications.map((notification) => (
                    <NotificationsRow notification={notification}/>
                ))
            }
        </Card>
    );
};

export default NotificationsList;

/**
 * //////////////////////////////////
 * フォームテンプレー
 * /////////////////////////////////
 *
 * コンポーネントのフォームテンプレはここに書いています。
 * ＨＴＭＬフォームで未入力状態でフォーム送信を実行すればフォームの入力キーが無くなってしまうので、nullフィールドがあっても、
 * 必ずフォームテンプレを送信するようにここにファイルが必要です。
 */

const searchForm = {
    ebis_client_name: null,
    application_at_from: null,
    application_at_to: null,
    status: null,
    type: null,
    application_complete_at_from: null,
    application_complete_at_to: null
}

const ebisAccountForm = {
    ebis_log_id: null,
    ebis_client_name: null,
    ebis_host_names: null,
    industry_type: null,
    business_model: null,
    official_company_name: null
}

const forms = {
    searchForm,
    ebisAccountForm
}

export default forms

import Page from "../../../Page";
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    Checkbox,
    Container,
    FormControlLabel,
    Grid,
    Typography
} from "@material-ui/core";
import React from "react";
import ModifiedPlansContainer
    from "../../../../../containers/user/serviceContracts/contents/modify/ModifiedPlansContainer";
import Auth0Utils from "../../../../../../state/utils/Auth0Utils";
import {useAuth0} from "@auth0/auth0-react";
import {empty} from "../../../../../../state/utils/Common";
import EditableCheck from "../../../../../atoms/EditableCheck";
import {Link} from "react-router-dom";
import urlLinks from "../../../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json";

export const ConfirmPage = (
    {
        contractBackToUpdatePage,
        managerProfile,
        confirmContractUpdate,
        scid,
        confirmChangePlanLoading,
        serviceContractContentData,
        confirmChangePlanError,
        serviceContractContentAlterBasicPlan,
        serviceContractContentAlterPlan,
        t,
        inquiry,
        setInquiryEvent,
        notes,

        terms_agreed,
        toggleTerms

    }
) => {
    const {getAccessTokenSilently} = useAuth0();
    return (
        <Page
            title={t('service_contracts.contents.modify.title', {contract_name: !empty(serviceContractContentData?.service_contract?.service_account_name) ? serviceContractContentData?.service_contract?.service_account_name : ""})}
        >
            <Container maxWidth={false}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography style={{marginBottom: 6}}
                                    variant="h2">{t('service_contracts.contents.modify.title', {contract_name: !empty(serviceContractContentData?.service_contract?.service_account_name) ? serviceContractContentData?.service_contract?.service_account_name : ""})}</Typography>
                    </Grid>
                </Grid>
                <Grid container alignContent={"center"} alignItems={"center"}>
                    <Grid item xs={12} md={12}>
                        <Breadcrumbs separator={">"} aria-label="breadcrumb">
                            <Link to={urlLinks.navigation.top.index.href}>
                                <Typography color="textPrimary">{t('general.top')}</Typography>
                            </Link>
                            <Link to={urlLinks.navigation.service_contracts.index.href}>
                                <Typography color="textPrimary">{t('service_contracts.title')}</Typography>
                            </Link>
                            <Link
                                to={urlLinks.navigation.service_contracts.index.sub_links.contents.href.replace('{SCID}', scid)}>
                                <Typography
                                    color="textPrimary">{t('service_contracts.contents.breadcrumb')}</Typography>
                            </Link>
                            <Link
                                to={urlLinks.navigation.service_contracts.index.sub_links.contents.sub_links.modify.href.replace('{SCID}', scid)}>
                                <Typography
                                    color="textPrimary">{t('service_contracts.contents.modify.breadcrumb')}</Typography>
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Grid container style={{marginTop: 10}}>
                    <Grid item md={7} style={{paddingRight: 28}}>
                        <Typography variant={"body1"} style={{fontSize: "13px", whiteSpace: "pre-line"}}>
                            {t('service_contracts.contents.modify.confirm.description')}
                        </Typography>
                        <Card style={{marginBottom: 15, marginTop: 15}}>
                            <Box p={3}>
                                <Typography variant={"body1"} style={{marginBottom: 8, fontWeight: "bold"}}>
                                    対象アカウント
                                </Typography>
                                <Typography variant={"body1"} style={{display: "inline", paddingRight: 10}}>
                                    {!empty(serviceContractContentData?.service_contract?.service_account_id) ? serviceContractContentData?.service_contract?.service_account_id : ""}
                                </Typography>
                                <Typography variant={"body1"} style={{display: "inline", paddingRight: 10}}>
                                    {!empty(serviceContractContentData?.service_contract?.service_account_name) ? serviceContractContentData?.service_contract?.service_account_name : ""}
                                </Typography>
                                <Typography variant={"body1"} style={{display: "inline", paddingRight: 10}}>
                                    {t('service_contracts.terminate.terminate_form.next_update_date', {update_date: !empty(serviceContractContentData?.service_contract?.service_account_name) ? serviceContractContentData?.renewal_date : ""})}
                                </Typography>
                                <Typography variant={"body1"} style={{marginBottom: 8, paddingTop: 8, fontWeight: "bold"}}>
                                    備考
                                </Typography>
                                <Typography variant={"body1"} style={{display: "inline", whiteSpace: "pre-wrap" , paddingRight: 10}}>
                                    {empty(notes) ? "" : notes}
                                </Typography>

                                <Box style={{backgroundColor: "#EDE8DF", marginTop: 20, borderRadius: 3, padding: 13}}>
                                    <Typography variant={"body1"}>
                                        変更希望内容を弊社にて確認の上、担当者よりご案内をさせていただきます。<br/>
                                        内容やタイミングによっては、ご希望に添えない場合がございます。ご了承ください。
                                    </Typography>
                                </Box>
                                <FormControlLabel style={{ paddingTop: 10 }} control={<Checkbox value={terms_agreed} onChange={(e) => toggleTerms(e.target.checked)} color={"primary"}/>} label="上記内容に同意します。" />
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item md={5} style={{paddingLeft: 28}}>
                        <ModifiedPlansContainer/>
                        <Box mt={4} style={{textAlign: "right"}}>
                            <Button
                                onClick={() => contractBackToUpdatePage()} disabled={confirmChangePlanLoading}
                                variant={"contained"}
                                color={"secondary"}>
                                {t('service_contracts.contents.modify.confirm.back_to_plan_change')}
                            </Button>
                            <EditableCheck>
                                <Button
                                    onClick={() => (async () => {
                                        const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                                            () => {
                                                return getAccessTokenSilently();
                                            },
                                        );
                                        confirmContractUpdate(access_token, managerProfile?.account?.uuid, scid, [
                                            ...serviceContractContentAlterPlan,
                                            serviceContractContentAlterBasicPlan,
                                        ], inquiry)
                                    })()}
                                    disabled={confirmChangePlanLoading||!terms_agreed}
                                    variant={"contained"}
                                    color={"primary"}
                                    style={{marginLeft: 20}}>
                                    {t('service_contracts.contents.modify.confirm.confirm_plan_change')}
                                </Button>
                            </EditableCheck>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )
}

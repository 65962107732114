import clsx from "clsx";
import {Box, Card, Grid, makeStyles, Typography,} from "@material-ui/core";
import React from "react";
import {empty} from "../../../../../state/utils/Common";
import Loading from "../../../../atoms/Loading";
import {managerCommonStyles} from "../../../../theme/manager/styles/common";
import {FixedSizeList} from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import globalTheme from "../../../../theme/globalTheme";
import {RequestsRow} from "./RequestsRow";

/**
 * コンポーネントスタイル
 *
 * @type {(props?: any) => ClassNameMap<"paper"|"root">}
 */
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        border: "1px #CCCCCC solid",
        boxShadow: "none"
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));
/**
 * 通常ユーザー側の申込一覧
 *
 * @param {makeStyles} className - コンポーネントのスタイル
 * @param requests
 * @param requestListLoading
 * @param {*} t - 翻訳オブジェクト
 * @returns {JSX.Element}
 * @constructor
 */
const RequestsList =
    ({
         className,
         requests,
         requestListLoading,
         t
     }) => {
        const classes = useStyles();
        const common = managerCommonStyles();
        const _globalTheme = globalTheme._default();
        return (
            <Card
                className={clsx(classes.root, className)}
            >
                <Box p={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                                style={{fontWeight: 900}}
                            >
                                {t('requests.list')}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                                style={{'textAlign': 'right'}}
                            >
                                {t('general.list_amount', {amount: !empty(requests) ? requests.length : 0})}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Grid container className={common.tableHeader}>
                    <Grid item xs={1}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('requests.list_headers.user_application_number')}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('requests.list_headers.status')}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('requests.list_headers.application_category')}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('requests.list_headers.account_id')}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('requests.list_headers.account_name')}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('requests.list_headers.applicant')}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('requests.list_headers.application_completion_date')}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('requests.list_headers.received_date')}
                        </Typography>
                    </Grid>
                </Grid>
                {requestListLoading === true ?
                    <Box className={_globalTheme.list} display="flex" alignItems="center" data-testid={"loading"}>
                        <Grid container
                              direction="row"
                              justify="center"
                              alignItems="center"
                        >
                            <Grid item xs={12}>
                                <Loading/>
                            </Grid>
                        </Grid>
                    </Box>
                    : !empty(requests) &&
                    <Box className={_globalTheme.list} data-testid={"has-users"}>
                        <AutoSizer>
                            {({height, width}) => (
                                <FixedSizeList
                                    width={width}
                                    height={height}
                                    itemCount={requests.length}
                                    itemSize={45}
                                    itemData={{
                                        requests: requests,
                                        t: t,
                                    }}
                                >
                                    {RequestsRow}
                                </FixedSizeList>
                            )}
                        </AutoSizer>
                    </Box>
                }
            </Card>
        );
    };

export default RequestsList;

import actions from "./actions";

/***
 * /////////////////////////////////////////////////////////
 * OPERATIONS オペレーション
 * --------------------
 *
 * ミドルウェアを必要とするすべてのアクションまたはアクションのためのバインドで、一緒にバインドされます。
 * このファイルでは、ミドルウェアのインジェクションを必要とするアクションはありませんが、その例がここにあります。
 * 例: https://github.com/alexnm/re-ducks#operations (英語)
 * ////////////////////////////////////////////////////////
 */

const managerLoadRequestSearch = actions.managerLoadRequestSearch

const managerRequestsLoad = actions.managerRequestsLoad
const managerRequestsLoading = actions.managerRequestsLoading
const managerRequestsSuccess = actions.managerRequestsSuccess
const managerRequestsFailed = actions.managerRequestsFailed
const formEditEvent = actions.formEditEvent
const managerChangePageAccountList = actions.managerChangePageAccountList
const downloadRequestList = actions.downloadRequestList
const managerRequestsDetailLoad = actions.managerRequestsDetailLoad
const managerRequestsDetailQuoteDetailLoad = actions.managerRequestsDetailQuoteDetailLoad
const managerCloseTermsAndConditionsModal = actions.managerCloseTermsAndConditionsModal
const managerOpenTermsAndConditionsModal = actions.managerOpenTermsAndConditionsModal
const managerDownloadQuotePDF = actions.managerDownloadQuotePDF
const toggleCancelConfirmation = actions.toggleCancelConfirmation
const cancelRequestSend = actions.cancelRequestSend
const toggleApproveTerminateRequest = actions.toggleApproveTerminateRequest
const approveTerminateRequest = actions.approveTerminateRequest
const managerSetAccountNameToSearchRequest = actions.managerSetAccountNameToSearchRequest


const operations = {
    managerLoadRequestSearch,

    managerRequestsLoad,
    managerRequestsLoading,
    managerRequestsSuccess,
    managerRequestsFailed,

    formEditEvent,
    managerChangePageAccountList,

    downloadRequestList,

    managerRequestsDetailLoad,

    managerRequestsDetailQuoteDetailLoad,

    managerCloseTermsAndConditionsModal,
    managerOpenTermsAndConditionsModal,

    managerDownloadQuotePDF,

    toggleCancelConfirmation,
    cancelRequestSend,

    toggleApproveTerminateRequest,
    approveTerminateRequest,
    managerSetAccountNameToSearchRequest
};
export default operations;

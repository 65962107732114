import React from 'react';
import {Box, Breadcrumbs, Button, Card, Container, Divider, Grid, makeStyles, Typography} from '@material-ui/core';
import Page from "../../Page";
import clsx from "clsx";
import {empty} from "../../../../../state/utils/Common";
import EstimatesListContainer from "../../../../containers/user/requests/detail/EstimatesListContainer";
import EditableCheck from "../../../../atoms/EditableCheck";
import requestType from "../../../../../state/utils/data/application_request_type_flat.json"
import urlLinks from "../../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json";
import {Link} from "react-router-dom";
import {ConfirmationModal} from "../../../../atoms/ConfirmationModal";
import {AlertModal} from "../../../../atoms/AlertModal";
import NotEditableCheck from "../../../../atoms/NotEditableCheck";

/**
 * /manager/notices コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    },
    button: {
        fontWeight: 900,
    },
    strong: {
        fontWeight: "bold"
    }
}));
/**
 *
 * /noticesのメインコンポーネント
 * @param managerLoadNoticeList
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const RequestDetailView = (
    {
        managerProfile,

        requestDetailLoading,
        requestDetail,
        requestId,

        isCancelConfirmationOpen,
        cancelRequestLoading,
        cancelRequestError,
        cancelRequestSend,
        toggleCancelConfirmation,
        isCancelConfirmedModalOpen,
        history,

        t
    }) => {
    const classes = useStyles();
    return (
        <Page
            className={classes.root}
            title={t('requests.detail.title')}
        >
            {requestDetailLoading !== true &&
            <Container maxWidth={false}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h2">{t('requests.detail.title')}</Typography>
                    </Grid>
                </Grid>
                <Grid container alignContent={"center"} alignItems={"center"}>
                    <Grid item xs={12} md={12} style={{marginTop: 7, marginBottom: 15}}>
                        <Breadcrumbs separator={">"} aria-label="breadcrumb">
                            <Link to={urlLinks.navigation.top.index.href}>
                                <Typography color="textPrimary">{t('general.top')}</Typography>
                            </Link>
                            <Link to={urlLinks.navigation.requests.index.href}>
                                <Typography color="textPrimary">{t('requests.title_user')}</Typography>
                            </Link>
                            <Link
                                to={urlLinks.navigation.requests.index.sub_links.detail.href.replace('{RID}', requestId)}>
                                <Typography color="textPrimary">{t('requests.detail.title')}</Typography>
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Box mt={1}>
                    <Card>
                        <Box className={clsx(classes.root)}>
                            <Typography variant="h4"> {t('requests.detail.information')}</Typography>
                            <Grid container style={{marginTop: 26}}>
                                {/** 申込担当者 */}
                                <Grid item xs={2} xl={1}>
                                    <Typography variant="body1"
                                                className={classes.strong}>{t('requests.detail.request_manager')}</Typography>
                                </Grid>
                                <Grid item xs={10} xl={11}>
                                    <Typography variant="body1">{requestDetail.application_user_name}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container style={{marginTop: 15}}>
                                {/** 申込年月日 */}
                                <Grid item xs={2} xl={1}>
                                    <Typography variant="body1"
                                                className={classes.strong}>{t('requests.detail.request_date')}</Typography>
                                </Grid>
                                <Grid item xs={10} xl={11}>
                                    <Typography variant="body1">{requestDetail.application_at}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container style={{marginTop: 15}}>
                                {/** 申込種別 */}
                                <Grid item xs={2} xl={1}>
                                    <Typography variant="body1"
                                                className={classes.strong}>{t('requests.detail.request_category')}</Typography>
                                </Grid>
                                <Grid item xs={10} xl={11}>
                                    <Typography variant="body1">{requestDetail.type_name}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container style={{marginTop: 15}}>
                                {/** 申込ステータス */}
                                <Grid item xs={2} xl={1}>
                                    <Typography variant="body1"
                                                className={classes.strong}>{t('requests.detail.request_status')}</Typography>
                                </Grid>
                                <Grid item xs={10} xl={11}>
                                    <Typography variant="body1">{requestDetail.status_name}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container style={{marginTop: 15}}>
                                {/** 申込手続き完了日 */}
                                <Grid item xs={2} xl={1}>
                                    <Typography variant="body1"
                                                className={classes.strong}>{t('requests.detail.request_completed_date')}</Typography>
                                </Grid>
                                <Grid item xs={10} xl={11}>
                                    <Typography variant="body1">{requestDetail.application_complete_at}</Typography>
                                </Grid>
                            </Grid>
                        </Box>

                        {requestDetail.type !== requestType.NEW && !empty(requestDetail.ebis_log_id) &&
                        <Divider/>
                        }

                        {requestDetail.type !== requestType.NEW && !empty(requestDetail.ebis_log_id) &&
                        <Box className={clsx(classes.root)}>
                            {/** 新規+エビスログID未設定（アカウント設定前）以外の場合 */}
                            <Typography variant="h4"> {t('requests.detail.detail')}</Typography>

                            {!empty(requestDetail.ebis_log_id) &&
                            <Grid container style={{marginTop: 26}}>
                                {/** アカウント情報 */}
                                <Grid item xs={2} xl={1}>
                                    <Typography variant="body1"
                                                className={classes.strong}>{t('requests.detail.related_account')}</Typography>
                                </Grid>
                                <Grid item xs={10} xl={11}>
                                    <Typography variant="body1">
                                        {requestDetail.ebis_log_id}&nbsp;&nbsp;
                                        {requestDetail.ebis_client_name}&nbsp;&nbsp;
                                        {/** 解約でない場合・自動更新の場合・requestDetail.renewal_ymが設定されている場合 */}
                                        {!empty(requestDetail.renewal_at) &&
                                        t('requests.detail.next_update') + ' ' + requestDetail.renewal_at
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                            }
                            <Grid container style={{marginTop: 15}}>
                                {/** サービス名 */}
                                <Grid item xs={2} xl={1}>
                                    <Typography variant="body1"
                                                className={classes.strong}>{t('requests.detail.service_name')}</Typography>
                                </Grid>
                                <Grid item xs={10} xl={11}>
                                    <Typography variant="body1">
                                        {requestDetail.service_names.map((data) => {
                                            return (<p>{data}</p>)
                                        })}
                                    </Typography>
                                </Grid>
                            </Grid>

                            {/*{(requestDetail.type === requestType.CHANGE || requestDetail.type === requestType.CANCEL) &&*/}
                            {/*    /!** */}
                            {/*        <Grid container style={{marginTop: 15}}>*/}
                            {/*            <Grid item xs={2} xl={1}>*/}
                            {/*                <Typography variant="body1"*/}
                            {/*                            className={classes.strong}>{t('requests.detail.service_detail')}</Typography>*/}
                            {/*            </Grid>*/}
                            {/*            <Grid item xs={10} xl={11}>*/}
                            {/*                <Typography variant="body1">????</Typography>*/}
                            {/*            </Grid>*/}
                            {/*        </Grid>*/}
                            {/*    *!/*/}
                            {/*}*/}

                            {(!empty(requestDetail.reflect_at)) &&
                            <Grid container style={{marginTop: 15}}>
                                {/** 反映日 */}
                                <Grid item xs={2} xl={1}>
                                    <Typography variant="body1"
                                                className={classes.strong}>{t('requests.detail.responded_date')}</Typography>
                                </Grid>
                                <Grid item xs={10} xl={11}>
                                    <Typography variant="body1">{requestDetail.reflect_at}</Typography>
                                </Grid>
                            </Grid>
                            }
                            {(requestDetail.type === requestType.CANCEL) &&
                            <Grid container style={{marginTop: 15}}>
                                {/** 解約希望日 */}
                                <Grid item xs={2} xl={1}>
                                    <Typography variant="body1"
                                                className={classes.strong}>{t('requests.detail.intended_cancel_date')}</Typography>
                                </Grid>
                                <Grid item xs={10} xl={11}>
                                    <Typography variant="body1">{requestDetail?.cancel_prefferd_ym}</Typography>
                                </Grid>
                            </Grid>
                            }
                            {(requestDetail.type === requestType.CANCEL) &&
                            <Grid container style={{marginTop: 15}}>
                                {/** 解約理由１ */}
                                <Grid item xs={2} xl={1}>
                                    <Typography variant="body1"
                                                className={classes.strong}>{t('requests.detail.cancel_reason')}</Typography>
                                </Grid>
                                <Grid item xs={10} xl={11}>
                                    {!empty(requestDetail?.cancel_reasons1) && requestDetail?.cancel_reasons1.map((value) => {
                                        return (
                                            <Box paddingBottom={.5}>
                                                <Typography variant="body1">{value}</Typography>
                                            </Box>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                            }
                            {(requestDetail.type === requestType.CANCEL) &&
                            <Grid container style={{marginTop: 15}}>
                                {/** 解約理由２ */}
                                <Grid item xs={2} xl={1}>
                                    <Typography variant="body1"
                                                className={classes.strong}>{t('requests.detail.cancel_expected_functionality_reason')}</Typography>
                                </Grid>
                                <Grid item xs={10} xl={11}>
                                    <Typography variant="body1"
                                                style={{whiteSpace: "pre-line"}}>{requestDetail?.cancel_reason2}</Typography>
                                </Grid>
                            </Grid>
                            }
                            {(requestDetail.type === requestType.CANCEL) &&
                            <Grid container style={{marginTop: 15}}>
                                {/** 解約理由３ */}
                                <Grid item xs={2} xl={1}>
                                    <Typography variant="body1"
                                                className={classes.strong}>{t('requests.detail.cancel_next_tool')}</Typography>
                                </Grid>
                                <Grid item xs={10} xl={11}>
                                    <Typography variant="body1"
                                                style={{whiteSpace: "pre-line"}}>{requestDetail?.cancel_reason3}</Typography>
                                </Grid>
                            </Grid>
                            }
                        </Box>
                        }

                        {requestDetail.type !== requestType.CANCEL && !empty(requestDetail?.related_quotes?.length) &&
                        <>
                            <Divider/>
                            <Box className={clsx(classes.root)} paddingBottom={2} paddingX={3}>
                                <Grid container>
                                    <Grid item sm={6}>
                                        <Typography variant="h4">{t('requests.detail.quote_list')}</Typography>
                                    </Grid>
                                    <Grid item sm={6}>
                                        <Typography variant={"body1"} style={{textAlign: "right"}}>
                                            {t('general.list_amount', {amount: requestDetail?.related_quotes?.length})}
                                        </Typography>
                                    </Grid>
                                </Grid>

                            </Box>
                            {requestDetail.related_quotes.length > 0 &&
                            /** 見積り一覧 */
                            <EstimatesListContainer requestUUID={requestDetail.uuid}/>
                            }
                        </>
                        }
                        {requestDetail.cancelable &&
                        /** キャンセル関係 */
                        <Box className={clsx(classes.root)} marginY={1} alignContent="middle">
                            <Grid container justify="center" alignContent={"center"} alignItems={"center"}>
                                {/** キャンセル期限日 */}
                                {requestDetail.cancelable && !empty(requestDetail?.cancel_limit_at) &&

                                <Typography
                                    variant="body1">{t('requests.detail.request_cancel_final_date')}：{requestDetail.cancel_limit_at}</Typography>
                                }
                                {requestDetail.cancelable &&

                                /** キャンセル確定ボタン＋モーダル */
                                /** 本申込完了移行～アクティベーション待ちまでキャンセル可能 */
                                <>
                                    <EditableCheck>
                                        <Button variant={"outlined"}
                                                onClick={() => toggleCancelConfirmation(true)}
                                                style={{marginLeft: 15, fontWeight: 900}}
                                                color={"primary"}>
                                            {t('requests.detail.request_cancel')}
                                        </Button>
                                        <ConfirmationModal
                                            isOpen={isCancelConfirmationOpen}
                                            setClose={() => toggleCancelConfirmation(false)}
                                            confirmationTitle={t('requests.manager.detail.quote.detailed.confirm_cancel_message')}
                                            cancelText={t('actions.cancel')}
                                            buttonText={t('actions.ok')}
                                            loadingText={t('actions.sending')}
                                            isLoading={cancelRequestLoading}
                                            error={cancelRequestError}
                                            id={requestDetail.uuid}
                                            profile={managerProfile}
                                            event={(id, access_token, uuid) => cancelRequestSend(access_token, uuid, id)}
                                        />
                                        <AlertModal
                                            isOpen={isCancelConfirmedModalOpen}
                                            event={() => history.push('/requests')}
                                            buttonText={t('actions.ok')}
                                            confirmationTitle={t('requests.manager.detail.quote.detailed.confirmed_cancel_message')}
                                        />
                                    </EditableCheck>
                                    <NotEditableCheck>
                                        <Button variant={"outlined"}
                                                disabled={true}
                                                style={{marginLeft: 15, fontWeight: 900}}
                                                color={"primary"}>
                                            {t('requests.detail.request_cancel')}
                                        </Button>
                                    </NotEditableCheck>
                                </>
                                }
                            </Grid>
                        </Box>
                        }

                    </Card>
                </Box>
            </Container>
            }
        </Page>
    );
}

export default RequestDetailView;

import ApiUtils from "../../../utils/ApiUtils";
import {
    GET_CONFIRM_LOG_ID,
    GET_REQUEST_DETAIL_API_PATH,
    GET_REQUEST_DETAIL_QUOTE_DETAIL_PATH,
    GET_REQUEST_DETAIL_QUOTE_DETAIL_PATH_PDF_DOWNLOAD,
    GET_REQUESTS_API_PATH,
    PUT_REQUEST_DETAIL_CANCEL,
    PUT_REQUEST_EBIS_SETTING_INFORMATION
} from "./constants";

/***
 * ///////////////////////////////////////////////
 * API
 * -----------------
 *
 * /requestsのすべてのAPIイベントは、ここで呼び出されます。これらのメソッドは、
 * 主にSAGASから呼び出され、APIイベントは、エラーまたはデータオブジェクトを戻します。
 * ///////////////////////////////////////////////
 */

/**
 * 申込一覧取得APIリクエスト
 * @param accessToken
 * @param params
 * @param account_id
 * @returns {AxiosPromise}
 */
export function callRequests(accessToken, params, account_id) {
    return new ApiUtils(accessToken).get(
        GET_REQUESTS_API_PATH.replace('{UID}', account_id),
        params,
        // requestsSchema
    )
}

/**
 * 申込詳細取得APIリクエスト
 * @param accessToken
 * @param params
 * @param account_id
 * @param request_id
 * @returns {AxiosPromise}
 */
export function callRequestsDetail(accessToken, params, account_id, request_id) {
    return new ApiUtils(accessToken).get(
        GET_REQUEST_DETAIL_API_PATH.replace('{UID}', account_id).replace('{RID}', request_id),
        params,
        null
    )
}

/**
 * 申込見積詳細取得APIリクエスト
 * @param accessToken
 * @param params
 * @param account_id
 * @param request_id
 * @param quote_id
 * @returns {AxiosPromise}
 */
export function callRequestQuote(accessToken, params, account_id, request_id, quote_id) {
    return new ApiUtils(accessToken).get(
        GET_REQUEST_DETAIL_QUOTE_DETAIL_PATH
            .replace('{UID}', account_id)
            .replace('{RID}', request_id)
            .replace('{QID}', quote_id),
        params,
        null
    )
}

export function callRequestQuotePDFDownload(accessToken, params, account_id, request_id, quote_id, quote_no) {
    return new ApiUtils(accessToken).get(
        GET_REQUEST_DETAIL_QUOTE_DETAIL_PATH_PDF_DOWNLOAD
            .replace('{UID}', account_id)
            .replace('{RID}', request_id)
            .replace('{QID}', quote_id),
        params,
        null
    ).then(response => {
        let userAgentString = navigator.userAgent;
        if (userAgentString.indexOf("Firefox") > -1) {
            const url = response.data.url;
            window.open(url, '_blank');
        } else {
            const a = document.createElement("a"); //Create <a>
            a.href = response.data.url
            a.click(); //Downloaded file
        }
        return response;
    });
}

/**
 *
 * @param accessToken
 * @param postData
 * @param account_id
 * @param request_id
 * @param quote_id
 * @returns {AxiosPromise}
 */
export function putEbisInformationAPI(accessToken, postData, account_id, request_id, quote_id) {
    return new ApiUtils(accessToken).put(
        PUT_REQUEST_EBIS_SETTING_INFORMATION
            .replace('{UID}', account_id)
            .replace('{RID}', request_id)
            .replace('{QID}', quote_id),
        "",
        postData
    )
}

export function putChangeContractApproveAPI(accessToken, account_id, request_id, quote_id, additional_information) {
    return new ApiUtils(accessToken).put(
        PUT_REQUEST_EBIS_SETTING_INFORMATION
            .replace('{UID}', account_id)
            .replace('{RID}', request_id)
            .replace('{QID}', quote_id),
        "",
        additional_information
    )
}

export function cancelRequestAPI(accessToken, account_id, request_id) {
    return new ApiUtils(accessToken).put(
        PUT_REQUEST_DETAIL_CANCEL
            .replace('{RID}', request_id)
            .replace('{UID}', account_id)
        ,
        "",
        null
    )
}

export function confirmLogIdInput(accessToken, account_id, request_id, quote_id, logId, clientName, host_names) {
    return new ApiUtils(accessToken).put(
        GET_CONFIRM_LOG_ID
            .replace('{UID}', account_id)
            .replace('{RID}', request_id)
            .replace('{QID}', quote_id),
        "",
        {
            'ebis_log_id': logId,
            'ebis_client_name': clientName,
            'ebis_host_names': host_names
        }
    )
}

/***
 * /////////////////////////////////////////////////////////////////
 * CONSTANTS (定数）
 * ----------------------------
 *
 * TYPEではないserviceContractsに関連するすべての定数について
 * 例を挙げると
 * API エンドポイント
 * /////////////////////////////////////////////////////////////////
 *
 */
export const MANAGER_GET_REQUESTS_API_PATH = "/manager/requests/";
export const MANAGER_DOWNLOAD_REQUESTS_API_PATH = "/manager/requests/csv";
export const MANAGER_GET_REQUEST_DETAIL_API_PATH = "/manager/requests/{RID}";
export const MANAGER_GET_REQUEST_DETAIL_QUOTE_DETAIL_PATH = "/manager/requests/{RID}/quotes/{QID}"
export const MANAGER_GET_REQUEST_DETAIL_QUOTE_DETAIL_PATH_PDF_DOWNLOAD = "/manager/requests/{RID}/quotes/{QID}/pdf"
export const MANAGER_SEND_CANCEL_REQUEST_API_PATH = "/manager/requests/{RID}/cancel"
export const MANAGER_SEND_APPROVE_TERMINATE_REQUEST_API_PATH = "/manager/requests/{RID}/cancel/accept"

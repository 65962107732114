import React from 'react';
import {Box, Breadcrumbs, Button, Container, Grid, makeStyles, Typography} from '@material-ui/core';
import Page from "../Page";
import UsersList from "./list/UsersList";
import Toolbar from "./Toolbar";
import UserModal from "./modal/UserModal";
import {ConfirmationModal} from "../../../atoms/ConfirmationModal";
import {AlertModal} from "../../../atoms/AlertModal";
import EditableCheck from "../../../atoms/EditableCheck";
import urlLinks from "../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json";
import {Link} from "react-router-dom";

/**
 * /manager/users コンポーネントスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(2)
    },
    button: {
        fontWeight: 900,
    }
}));
/**
 * ユーザー一覧画面コンポーネント
 *
 * @param {boolean} loading - ユーザー一覧のロード状態
 * @param {Object} activeProfile - ローグインユーザー
 * @param {Object} profile - ログインユーザープロフィール。
 * @param {*} updateUser - 更新ユーザーアクション
 * @param {Object} error - ページエラー
 * @param {boolean} isRegisterView = "" "Is the register modal open?" flag
 * @param {boolean} toggleRegisterView - 新規ユーザーアカウントのモダールトグル
 * @param {*} userRegistrationLoad - ユーザーの登録ロード状態
 * @param {Object} registerError - モーダルのエラー
 * @param {*} registerUser - 新規ユーザー登録リクエスト
 * @param {boolean} searchLoad - ユーザー検索中のアクション
 * @param {Object} searchUserResult - user list from result
 * @param {boolean} reLoad - double load checker - ダブルクリック対策
 * @param {*} searchUser - ユーザー検索アクション
 * @param {Object} users - 現在のユーザー
 * @param {boolean} deleteView - 削除の表示・非表示状態
 * @param {boolean} deleteLoading - 削除中の確認
 * @param {Object} deleteError  - 削除エラー
 * @param {*} viewUserDeleteToggle - 削除モーダルの表示・非表示状態
 * @param {*} deleteUsers - ユーザー削除アクション
 * @param {*} t - translation
 * @returns {JSX.Element}
 * @constructor
 */
const UserView = (
    {
        loading,
        activeProfile,
        profile,
        updateUser,
        access_token,
        account_id,
        isRegisterView,
        toggleRegisterView,
        userRegistrationLoad,
        registerError,
        registerUser,
        searchLoad,
        searchUserResult,
        searchUser,
        users,
        deleteView,
        deleteLoading,
        deleteError,
        viewUserDeleteToggle,
        deleteUsers,
        unlockAuth0User,
        unblockNotificationShow,
        closeUnlockAuth0UserModal,
        resendNotificationShow,
        resendAuth0VerifyMail,
        resendAuth0VerifyMailCloseNotification,
        t
    }) => {
    const classes = useStyles();
    return (
        <Page
            className={classes.root}
            title={t('users.title')}
        >
            <Container maxWidth={false}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h2">{t('users.title')}</Typography>
                    </Grid>
                    <Grid container item xs={12} md={6} justify="flex-end">
                        <EditableCheck>
                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.button}
                                data-testid="create-button"
                                onClick={() => toggleRegisterView()}
                            >
                                {t('users.new_account')}
                            </Button>
                        </EditableCheck>
                    </Grid>
                </Grid>
                <Grid container alignContent={"center"} alignItems={"center"} style={{paddingTop: 0}}>
                    <Grid item xs={12} md={12}>
                        <Breadcrumbs separator={">"} aria-label="breadcrumb">
                            <Link to={urlLinks.navigation.top.index.href}>
                                <Typography color="textPrimary">{t('general.top')}</Typography>
                            </Link>
                            <Link to={urlLinks.navigation.user.index.href}>
                                <Typography color="textPrimary">{t('users.title')}</Typography>
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                {/* 作成/編集 モーダル コンポーネント */}
                <UserModal
                    isOpen={isRegisterView}
                    setClose={toggleRegisterView}
                    userRegister={registerUser}
                    registerLoading={userRegistrationLoad}
                    activeUser={activeProfile}
                    profile={profile}
                    registerError={registerError}
                    userEdit={updateUser}
                    t={t}
                />

                <AlertModal
                    isOpen={resendNotificationShow}
                    setClose={resendAuth0VerifyMailCloseNotification}
                    cancelText={null}
                    confirmationTitle={t('users.alert.resend_verification_email_title')}
                    buttonText={t('actions.ok')}
                    event={resendAuth0VerifyMailCloseNotification}
                />

                <AlertModal
                    isOpen={unblockNotificationShow}
                    setClose={closeUnlockAuth0UserModal}
                    cancelText={null}
                    confirmationTitle={t('users.alert.password_lock_title')}
                    buttonText={t('actions.ok')}
                    event={closeUnlockAuth0UserModal}
                />

                {/* 削除モーダルコンポーネント */}
                <ConfirmationModal
                    isOpen={deleteView}
                    isLoading={deleteLoading}
                    error={deleteError?.translation_error_key}
                    profile={profile}
                    loadingText={t('actions.sending')}
                    setClose={viewUserDeleteToggle}
                    confirmationTitle={t('users.delete.title')}
                    confirmationDescription={t('users.delete.description')}
                    buttonText={t('actions.delete')}
                    id={activeProfile !== null && activeProfile.uuid}
                    deleteInformation={t('users.delete.delete_account_title', {user: activeProfile !== null ? (activeProfile.last_name + " " + activeProfile.first_name) : null})}
                    event={deleteUsers}
                    cancelText={t('actions.cancel')}
                />
                <Toolbar toggleRegisterView={toggleRegisterView} searchLoad={searchLoad}
                         searchUserResult={searchUserResult} searchUser={searchUser}/>
                <Box mt={3}>
                    {/* ユーザー一覧 */}
                    <UsersList
                        loginUserID={profile.id}
                        unlockAuth0User={unlockAuth0User}
                        resendAuth0VerifyMail={resendAuth0VerifyMail}
                        account_id={account_id}
                        loading={loading}
                        users={users}
                        viewRegisterToggle={toggleRegisterView}
                        viewDeleteToggle={viewUserDeleteToggle}
                        t={t}/>
                </Box>
            </Container>
        </Page>
    );
};

export default UserView;

import types, {TRANSFER_RESET_TERMS_AND_CONDITIONS, TRANSFER_TOGGLE_TERMS_AND_CONDITIONS} from "./types";

/***
 * /////////////////////////////////////////
 * REDUCERS (リデューサー)
 * -------------------
 *
 * すべてのリアクトのマネージャーアカウントの一時的なデータが保存される領域。
 * アプリケーションのどこでもデータを使用することができます。
 *
 * Reducerイベントは、ステートへのセッター命令のみを **実行すべき** です。
 *
 * このReducerのステートは、/src/state/ducks/index.jsに設定されているservice_contractsグループに束縛されています。
 * //////////////////////////////////////////
 */
export const serviceContractTransferState = {
    transferForm: {},
    transfer_accepted_terms: false,

    transferRequestLoading: false,
    transferRequestError: {},

    transferDataLoading: true,
    transferData: {},
    transferDataError: {}

}

export default function serviceContractTransferReducer(state = serviceContractTransferState, action) {
    switch (action.type) {
        case(types.SAVE_TRANSFER_FORM_INFORMATION):
            return {
                ...state,
                transferForm: action.payload.transferForm
            }
        case(types.TRANSFER_TOGGLE_TERMS_AND_CONDITIONS):
            return {
                ...state,
                transfer_accepted_terms: state.transfer_accepted_terms = !state.transfer_accepted_terms
            }
        case(types.TRANSFER_RESET_TERMS_AND_CONDITIONS):
            return {
                ...state,
                transfer_accepted_terms: state.transfer_accepted_terms = false
            }
        /*************
         * 契約移管データの取得リクエスト
         *************/
        case(types.GET_SERVICE_CONTRACT_TRANSFER_DATA_LOADING):
            return {
                ...state,
                transferDataLoading: state.transferDataLoading = true,
                transferDataError: state.transferDataError = {},
                transferData: state.transferData = {},
            }
        case(types.GET_SERVICE_CONTRACT_TRANSFER_DATA_SUCCESS):
            return {
                ...state,
                transferDataLoading: state.transferDataLoading = false,
                transferData: state.transferData = action.payload.reasons
            }
        case(types.GET_SERVICE_CONTRACT_TRANSFER_DATA_FAILED):
            return {
                ...state,
                transferDataLoading: state.transferDataLoading = false,
                transferDataError: state.transferDataError = action.payload.error
            }
        /*************
         * 移管申込の送信
         *************/
        case(types.SEND_TRANSFER_FORM_REQUEST_LOADING):
            return {
                ...state,
                transferRequestLoading: state.transferRequestLoading = true,
                transferRequestError: state.transferRequestError = {}
            }
        case(types.SEND_TRANSFER_FORM_REQUEST_SUCCESS):
            return {
                ...state,
                transferForm: state.transferForm = {},
                transferRequestLoading: state.transferRequestLoading = false,
            }
        case(types.SEND_TRANSFER_FORM_REQUEST_FAILURE):
            return {
                ...state,
                transferRequestLoading: state.transferRequestLoading = false,
                transferRequestError: state.transferRequestError = action.payload.error
            }
        default:
            return state
    }
}

import React from 'react';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    Link as LinkMaterial,
    makeStyles,
    Typography
} from '@material-ui/core';
import clsx from "clsx";
import globalTheme from "../../../../../../../../theme/globalTheme";
import CorrespondenceQuoteSegment from "../../../segments/Correspondence";
import BasicInformationQuoteSegment from "../../../segments/BasicInformation";
import ServiceChargeQuoteSegment from "../../../segments/ServiceCharge";
import NotesQuoteSegment from "../../../segments/Notes";
import Auth0Utils from "../../../../../../../../../state/utils/Auth0Utils";
import {useAuth0} from "@auth0/auth0-react";
import EditableCheck from "../../../../../../../../atoms/EditableCheck";
import {empty} from "../../../../../../../../../state/utils/Common";
import NotEditableCheck from "../../../../../../../../atoms/NotEditableCheck";
import {Trans} from "react-i18next";

/**
 * /manager/notices コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    },
    button: {
        fontWeight: 900,
    },
    strong: {
        fontWeight: "bold"
    }
}));
/**
 *
 * /noticesのメインコンポーネント
 * @param managerLoadNoticeList
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const ConfirmView = (
    {
        requestStartUpInformation,
        settingConfirmEbisFormInformation,
        formattedAdditionalOptionInformation,
        additionalOptionInformation,
        requestQuote,
        requestDetail,
        requestApplyAcceptTerms,
        applyToggleTerms,

        scrolledToTermsBottom,
        hasScrolledToTermsBottom,

        settingEbisBackButton,
        termsAndConditionsIsOpen,
        closeTermsAndConditionsModal,
        openTermsAndConditionsModal,
        ebisAccountConfirmedLoading,
        additionalFieldsArray,
        managerProfile,
        quoteId,
        requestId,
        t
    }) => {
    const classes = useStyles();
    const formClasses = globalTheme.defaultFormStyle();
    const globalClass = globalTheme._default();
    const {getAccessTokenSilently} = useAuth0();
    return (
        <>
            <Box className={clsx(classes.root)}>
                <Typography
                    variant="body1">{t('requests.detail.quotes.apply_new.step_2_confirm.description')}</Typography>
            </Box>
            <Divider/>
            <CorrespondenceQuoteSegment requestQuote={requestQuote} requestDetail={requestDetail} t={t}/>
            <Divider/>
            <BasicInformationQuoteSegment requestQuote={requestQuote} t={t}/>
            <Divider/>
            <ServiceChargeQuoteSegment requestQuote={requestQuote} requestDetail={requestDetail} t={t}/>
            <Divider/>
            <NotesQuoteSegment
                requestDetail={requestDetail}
                requestQuote={requestQuote}
                t={t}
                termsAndConditionsIsOpen={termsAndConditionsIsOpen}
                closeTermsAndConditionsModal={closeTermsAndConditionsModal}
                openTermsAndConditionsModal={openTermsAndConditionsModal}
                isModal={false}
                scrolledToTermsBottom={scrolledToTermsBottom}
                hasScrolledToTermsBottom={hasScrolledToTermsBottom}
            />
            <Divider/>
            <Box className={clsx(classes.root)}>

                <Typography variant="h4"> {t('requests.detail.quotes.apply_new.step_2_confirm.ebis_account')}</Typography>
                <Grid container style={{marginTop: 20}}>
                    <Grid item xs={4} sm={1}>
                        <Typography variant="body1"
                                    className={classes.strong}>{t('requests.detail.quotes.setting.step_1_ebis.id')}</Typography>
                    </Grid>
                    <Grid item xs={8} sm={11} className={globalClass.detail_items}>
                        <Typography variant={"body1"}>{requestStartUpInformation?.ebis_log_id}</Typography>
                    </Grid>
                </Grid>
                <Grid container style={{marginTop: 15, marginBottom: 15}}>
                    <Grid item xs={4} sm={1}>
                        <Typography variant="body1"
                                    className={classes.strong}>{t('requests.detail.quotes.setting.step_1_ebis.account_name')}</Typography>
                    </Grid>
                    <Grid item xs={8} sm={11} className={globalClass.detail_items}>
                        <Typography variant={"body1"}>{requestStartUpInformation?.ebis_client_name}</Typography>
                    </Grid>
                </Grid>
                <Grid container style={{marginTop: 15, marginBottom: 15}}>
                    <Grid item xs={4} sm={1}>
                        <Typography variant="body1"
                                    className={classes.strong}> {t('requests.detail.quotes.setting.step_1_ebis.calculate_sites')}</Typography>
                    </Grid>
                    <Grid item xs={8} sm={11} className={globalClass.detail_items}>
                        {requestStartUpInformation?.ebis_host_names?.map(function (value) {
                            return (<Typography variant={"body1"}>{value.ebis_host_name}</Typography>)
                        })}
                    </Grid>
                </Grid>
                <Grid container style={{marginTop: 20}}>
                    <Grid item xs={4} sm={1}>
                        <Typography variant="body1"
                                    className={classes.strong}>{t('requests.detail.quotes.setting.step_1_ebis.industry_type')}</Typography>
                    </Grid>
                    <Grid item xs={8} sm={11} className={globalClass.detail_items}>
                        <Typography variant={"body1"}>{requestStartUpInformation?.industry_type}</Typography>
                    </Grid>
                </Grid>
                <Grid container style={{marginTop: 20}}>
                    <Grid item xs={4} sm={1}>
                        <Typography variant="body1"
                                    className={classes.strong}>{t('requests.detail.quotes.setting.step_1_ebis.business_model')}</Typography>
                    </Grid>
                    <Grid item xs={8} sm={11} className={globalClass.detail_items}>
                        <Typography variant={"body1"}>{requestStartUpInformation?.business_model}</Typography>
                    </Grid>
                </Grid>
                <Grid container style={{marginTop: 20, marginBottom: 20}}>
                    <Grid item xs={4} sm={1}>
                        <Typography variant="body1"
                                    className={classes.strong}>{t('requests.detail.quotes.setting.step_1_ebis.official_company_name')}</Typography>
                    </Grid>
                    <Grid item xs={8} sm={11} className={globalClass.detail_items}>
                        <Typography
                            variant={"body1"}>{requestStartUpInformation?.official_company_name}</Typography>
                    </Grid>
                </Grid>
                {!empty(formattedAdditionalOptionInformation) &&
                <>
                    {Object.keys(formattedAdditionalOptionInformation).map((value) => {
                        return (
                            <>
                                <Divider style={{marginTop: 10}}/>
                                <Grid container style={{marginTop: 20}}>
                                    <Grid item xs={12} sm={12}>
                                        <Typography variant="body1"
                                                    className={classes.strong}>{t('additional_option_label.form_block_headers.' + value)}</Typography>
                                        {Object.keys(formattedAdditionalOptionInformation[value]).map((value2) => {
                                            return (
                                                <Grid container style={{margin: "10px 0"}}>
                                                    <Grid item xs={4} sm={1}>
                                                        <Typography variant="body1"
                                                                    className={classes.strong}>{t('additional_option_label.form_labels.' + value2)}</Typography>
                                                    </Grid>
                                                    <Grid item xs={8} sm={11} className={globalClass.detail_items}>
                                                        <Typography style={{ whiteSpace: "pre-wrap" }}
                                                            variant={"body1"}>{formattedAdditionalOptionInformation[value][value2]}</Typography>
                                                    </Grid>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                            </>
                        );
                    })}
                </>
                }
                <Divider style={{marginTop: 10}}/>
                <Box className={clsx(classes.root)} marginY={1}>
                    <Grid container justify="center" alignItems={"center"} spacing={5}>
                        <Grid item>
                            <FormControlLabel color={"primary"} disabled={ebisAccountConfirmedLoading || !hasScrolledToTermsBottom}
                                              control={<Checkbox color={"primary"} disabled={!hasScrolledToTermsBottom} onChange={() => applyToggleTerms()}
                                                                 checked={requestApplyAcceptTerms}/>}
                                              label={<Trans i18nKey="general.agree_terms">
                                                  { /** TODO: 時間があれば下記のリンクを環境設定変数に */}
                                                  上記申込内容、お客様への注意事項、
                                                  「<LinkMaterial target="_blank" style={{ color: "#0D66D0" }} href="https://www.ebis.ne.jp/policy/rule.php">AD EBiS 利用契約約款</LinkMaterial>」、
                                                  「<LinkMaterial target="_blank" style={{ color: "#0D66D0" }} href="https://support.ebis.ne.jp/search_want/218/">ご利用に際して</LinkMaterial>」、
                                                  「<LinkMaterial target="_blank" style={{ color: "#0D66D0" }} href="https://www.yrglm.co.jp/policy/">個人情報保護方針</LinkMaterial> 」 ならびに
                                                  「<LinkMaterial target="_blank" style={{ color: "#0D66D0" }} href="https://www.yrglm.co.jp/policy/privacy_f/">個人情報の取扱い</LinkMaterial>」
                                                  に同意の上、申し込みます。
                                              </Trans>}/>
                            <Typography variant={"body2"} style={{paddingLeft: 20}}>
                                {t('requests.detail.quotes.apply_new.step_2_confirm.agree_help')}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box className={clsx(classes.root)} marginY={1} alignContent="middle">
                    <Grid container justify="center" alignItems={"center"} spacing={5}>
                        <Button
                            variant={"contained"}
                            color={"secondary"}
                            style={{marginRight: 20, fontWeight: 900}}
                            disabled={ebisAccountConfirmedLoading}
                            onClick={() => settingEbisBackButton(!empty(additionalFieldsArray.length) ? 1: 2)}
                        >
                            {t('actions.back')}
                        </Button>
                        <EditableCheck>
                            <Button variant={"contained"} style={{fontWeight: 900}}
                                    color={"primary"}
                                    disabled={!requestApplyAcceptTerms || ebisAccountConfirmedLoading || !hasScrolledToTermsBottom}
                                    onClick={() => (async () => {
                                        const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                                            () => {
                                                return getAccessTokenSilently();
                                            },
                                        );

                                        settingConfirmEbisFormInformation(access_token, managerProfile?.account?.uuid, requestId, quoteId, requestStartUpInformation, additionalOptionInformation)
                                    })()}
                            >
                                {t('actions.send')}
                            </Button>
                        </EditableCheck>
                        <NotEditableCheck>
                            <Button variant={"contained"} style={{fontWeight: 900}}
                                    color={"primary"}
                                    disabled={true}>
                                {t('actions.send')}
                            </Button>
                        </NotEditableCheck>
                    </Grid>
                </Box>
            </Box>
        </>
    );
}

export default ConfirmView;

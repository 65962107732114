import React from 'react';
import {Box, makeStyles, Typography} from '@material-ui/core';
import {TransferRow} from "../../../../../../atoms/rows/transferList/TransferRow";
import globalTheme from "../../../../../../theme/globalTheme";
import {empty} from "../../../../../../../state/utils/Common";
import {PlanTransferRow} from "../../../../../../atoms/rows/transferList/PlanTransferRow";

/**
 * /manager/notices コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(1)
    },
    button: {
        fontWeight: 900,
    }
}));
/**
 *
 * /noticesのメインコンポーネント
 * @param managerLoadNoticeList
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const CurrentPlanContentsView = (
    {
        t,
        serviceContractContentData,
        serviceContractContentLoading,
        removePlanAction,
        changeItemAction,
        changePlanAction,
        serviceContractContentAlterPlan,
        serviceContractContentAllPlanData,
        savedTierItem,
        undoBasicPlanUpdate
    }) => {
    const classes = useStyles();
    const allTheme = globalTheme.defaultFormStyle();
    console.log(changeItemAction);
    console.log(serviceContractContentData);
    return (
        <>
            <Box mt={1} className={allTheme.groupListRounded}>
                <Typography style={{fontSize: 13, marginTop: 15, marginBottom: 10, fontWeight: 900}}>
                    {t('service_contracts.contents.modify.currently_contracted_to')}
                </Typography>
                {serviceContractContentLoading === true &&
                    <TransferRow y={2} DropDownOptions={{}} RowTitle={""} allowRounded={true}/>
                }
                {serviceContractContentLoading === false &&
                    !empty(serviceContractContentData?.plans?.base_plan) &&
                    <PlanTransferRow
                        y={2}
                        t={t}
                        CurrentPlan={serviceContractContentData?.plans?.base_plan?.product_rate_plan_name}
                        undoBasicPlanUpdate={undoBasicPlanUpdate}
                        data={serviceContractContentData?.plans?.base_plan}
                        changePlanAction={changePlanAction}
                        defaultTier={serviceContractContentData?.plans?.base_plan?.product_rate_plan_id}
                        DropDownOptions={!empty(serviceContractContentAllPlanData) ? serviceContractContentAllPlanData?.base_plans : []}
                        RowTitle={t('service_contracts.contents.modify.basic_plan_title')}
                        allowRounded={true}
                    />
                }
            </Box>
            {serviceContractContentLoading === false &&
                !empty(serviceContractContentData?.plans?.options) &&
                <Box mt={1} className={allTheme.groupListRounded}>
                    {serviceContractContentData.plans.options.map((item) => {
                        if(serviceContractContentAlterPlan.some((plan) => plan.PlanData.id === item.id)) return "";
                        console.log(item.tiers.filter((item_tier) => {
                            return item.quantity === item_tier.ending_unit
                        }))
                        if(item?.tiers.length > 1) {
                            return (
                                <TransferRow
                                    data={item}
                                    changeItemAction={changeItemAction}
                                    defaultTier={item.tiers.filter((item_tier) => item.quantity === item_tier.ending_unit)[0]?.id}
                                    DropDownOptions={item.tiers}
                                    RowTitle={item.product_rate_plan_name}
                                    removePlanEvent={removePlanAction}
                                    savedTierItem={savedTierItem.filter((items, index) => {
                                        return items.product_rate_plan_id === item.product_rate_plan_id
                                    })}
                                />
                            )
                        }
                        return (
                            <TransferRow
                                data={item}
                                DropDownOptions={null}
                                RowTitle={item.product_rate_plan_name }
                                removePlanEvent={removePlanAction}
                        />)
                    })}
                </Box>
            }
        </>
    );
}

export default CurrentPlanContentsView;

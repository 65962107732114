/***
 * /////////////////////////////////////////
 * フォーム妥当性確認  (Using the validate.js library)
 * -------------------------
 *
 * /service_contracts 用のフォームバリデータです。
 *
 * Validate.js と /util/ValidateUtil.js を使ってバリデーションを設定します。
 * バリデーションルールについてはこちらをご覧ください。
 * https://validatejs.org/#validators
 *
 * すべてのフォーム検証属性はここに格納され、ビューコンポーネントから直接呼び出されます。
 * //////////////////////////////////////////
 */

/**
 * 連絡先妥当性確認
 */
export const transferValidation = {
    preferred_ym: {
        presence: true,
        datetime: {
            dateOnly: true
        }
    },
    company_name: {
        presence: true
    },
    last_name: {
        presence: true
    },
    first_name: {
        presence: true
    },
    phone: {
        presence: true,
        phone_validation: true
    },
    email: {
        presence: true,
        email: true
    }
}

/***
 * /////////////////////////////////////////
 * フォーム妥当性確認  (Using the validate.js library)
 * -------------------------
 *
 * /requests 用のフォームバリデータです。
 *
 * Validate.js と /util/ValidateUtil.js を使ってバリデーションを設定します。
 * バリデーションルールについてはこちらをご覧ください。
 * https://validatejs.org/#validators
 *
 * すべてのフォーム検証属性はここに格納され、ビューコンポーネントから直接呼び出されます。
 * //////////////////////////////////////////
 */

export const ebisAccountValidation = {
    ebis_log_id: {
        presence: true,
        smallLetterAndNumbersValidation: true,
        firstCharacterMustBeHalfEnglishCharacter: true,
        length: {maximum: 20}
    },
    ebis_client_name: {
        presence: true,
        databaseSafe: true,
        length: {maximum: 32}
    },
    ebis_host_names: {
        presence: true,
    },
    industry_type: {
        presence: true
    },
    business_model: {
        presence: true
    },
    official_company_name: {
        presence: false
    }
}

export const ebisAccountValidationWebsites = {
    ebis_host_name: {
        presence: true,
        url: true
    }
}

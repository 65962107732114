import React, {useEffect} from 'react';
import {Box, Container, Grid, makeStyles, Typography} from '@material-ui/core';
import Page from "../Page";
import {useAuth0} from "@auth0/auth0-react";
import {useDispatch} from "react-redux";
import {empty} from "../../../../state/utils/Common";
import Auth0Utils from "../../../../state/utils/Auth0Utils";
import NotificationsList from "./list/NotificationsList";
import {Link} from "react-router-dom";
import globalTheme from "../../../theme/globalTheme";
import urlLinks from "../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json";

/**
 * /notifications コンポーネントスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(2)
    },
    button: {
        fontWeight: 900,
    }
}));
/**
 * お知らせ一覧画面コンポーネント
 *
 * @param {boolean} loading - お知らせ一覧のロード状態
 * @param {Object} activeProfile - ローグインユーザー
 * @param {Object} profile - ログインユーザープロフィール。
 * @param {boolean} reLoad - double load checker - ダブルクリック対策
 * @param {Object} notifications - 現在のユーザー
 * @param {*} t - translation
 * @returns {JSX.Element}
 * @constructor
 */
const NotificationView = (
    {
        t,
        loading,
        notifications,
    }) => {
    const classes = useStyles();
    // アプリケーションテーマ
    const globalClass = globalTheme._default();
    return (
        <Page
            className={classes.root}
            title={t('notification.title')}
        >
            <Container maxWidth={false}>
                <Box>
                    <Grid style={{paddingBottom: 10}} container>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h2">{t('notification.title')}</Typography>
                            <Link to={urlLinks.navigation.top.index.href}>
                                <Typography style={{paddingTop:6,color:"#000000"}} variant={"subtitle1"}>{t('general.top')}</Typography>
                            </Link>
                        </Grid>
                    </Grid>
                    {/* お知らせ一覧 */}
                    <NotificationsList
                        loading={loading}
                        notifications={notifications}
                        t={t}/>
                </Box>
                <Link to="/notifications/all">
                    <Typography variant={"body1"} className={globalClass.a} style={{marginTop: 10}}>
                        {t('notification.to_previous_notifications')}
                    </Typography>
                </Link>
            </Container>
        </Page>
    );
};

export default NotificationView;

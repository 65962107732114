import React from 'react';
import {Box, Button, FormLabel, Grid, makeStyles, Typography} from '@material-ui/core';
import clsx from "clsx";
import globalTheme from "../../../../../../../../theme/globalTheme";
import {useAuth0} from "@auth0/auth0-react";
import Auth0Utils from "../../../../../../../../../state/utils/Auth0Utils";
import EditableCheck from "../../../../../../../../atoms/EditableCheck";
import NotEditableCheck from "../../../../../../../../atoms/NotEditableCheck";

/**
 * /manager/notices コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    },
    button: {
        fontWeight: 900,
    },
    strong: {
        fontWeight: "bold"
    }
}));
/**
 *
 * /noticesのメインコンポーネント
 * @param managerLoadNoticeList
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const ConfirmView = (
    {
        managerProfile,
        requestId,
        quoteId,
        requestStartUpInformation,
        settingConfirmEbisFormInformation,
        ebisAccountConfirmedLoading,
        ebisAccountConfirmedError,
        settingEbisBackButton,
        t
    }) => {
    const classes = useStyles();
    const formClasses = globalTheme.defaultFormStyle();

    const {getAccessTokenSilently} = useAuth0();

    return (
        <>
            <Box className={clsx(classes.root)}>
                <Typography
                    variant="body1">{t('requests.detail.quotes.setting.step_2_confirm.description')}</Typography>
            </Box>
            <Box className={clsx(classes.root)}>
                <Grid container>
                    <Grid item xs={12} style={{marginTop: -10, marginBottom: 8}}>
                        <FormLabel>
                            {t('requests.detail.quotes.setting.step_1_ebis.id')}
                        </FormLabel>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant={"body1"}>{requestStartUpInformation?.ebis_log_id}</Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} style={{marginTop: 20, marginBottom: 8}}>
                        <FormLabel>
                            {t('requests.detail.quotes.setting.step_1_ebis.account_name')}
                        </FormLabel>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant={"body1"}>{requestStartUpInformation?.ebis_client_name}</Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} style={{marginTop: 20, marginBottom: 8}}>
                        <FormLabel>
                            {t('requests.detail.quotes.setting.step_1_ebis.calculate_sites')}
                        </FormLabel>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        {requestStartUpInformation?.ebis_host_names?.map(function (value) {
                            return (<Typography variant={"body1"}>{value.ebis_host_name}</Typography>)
                        })}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} style={{marginTop: 20, marginBottom: 8}}>
                        <FormLabel>
                            {t('requests.detail.quotes.setting.step_1_ebis.industry_type')}
                        </FormLabel>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant={"body1"}>{requestStartUpInformation?.industry_type}</Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} style={{marginTop: 20, marginBottom: 8}}>
                        <FormLabel>
                            {t('requests.detail.quotes.setting.step_1_ebis.business_model')}
                        </FormLabel>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant={"body1"}>{requestStartUpInformation?.business_model}</Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} style={{marginTop: 20, marginBottom: 8}}>
                        <FormLabel>
                            {t('requests.detail.quotes.setting.step_1_ebis.official_company_name')}
                        </FormLabel>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant={"body1"}>{requestStartUpInformation?.official_company_name}</Typography>
                    </Grid>
                </Grid>
                <Box className={clsx(classes.root)} marginY={1} alignContent="middle">
                    <Grid container justify="center" alignItems={"center"} spacing={5}>
                        <Button
                            variant={"contained"}
                            color={"secondary"}
                            disabled={ebisAccountConfirmedLoading}
                            style={{marginRight: 20, fontWeight: 900}}
                            onClick={() => settingEbisBackButton()}
                        >
                            {t('actions.back')}
                        </Button>
                        <EditableCheck>
                            <Button variant={"contained"} style={{fontWeight: 900}}
                                    disabled={ebisAccountConfirmedLoading}
                                    color={"primary"}
                                    onClick={() => (async () => {
                                        const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                                            () => {
                                                return getAccessTokenSilently();
                                            },
                                        );
                                        settingConfirmEbisFormInformation(access_token, managerProfile?.account?.uuid, requestId, quoteId, requestStartUpInformation)
                                    })()}
                            >
                                {t('actions.send')}
                            </Button>
                        </EditableCheck>
                        <NotEditableCheck>
                            <Button variant={"contained"} style={{fontWeight: 900}}
                                    color={"primary"}
                                    disabled={true}>
                                {t('actions.send')}
                            </Button>
                        </NotEditableCheck>
                    </Grid>
                </Box>
            </Box>
        </>
    )
        ;
}

export default ConfirmView;

import {Grid, Typography} from "@material-ui/core";
import React from "react";
import globalTheme from "../../../../../../theme/globalTheme";
import {currencyFormatter} from "../../../../../../../state/utils/Common";

export const ManagerServiceChargeRow =
    ({
         service_charge,
         requestQuote
     }) => {
        const commonTheme = globalTheme._default();
        return (
            <Grid container key={service_charge.uuid} style={{
                paddingLeft: 32,
                paddingTop: 13,
                paddingBottom: 13,
                paddingRight: 98,
                borderBottom: "1px #CFCFCF solid"
            }}>
                <Grid item xs={4} style={{textAlign: "left"}}>
                    <Typography
                        color="textPrimary"
                        variant="body1"
                        className={commonTheme.breakWrap}
                    >
                        {service_charge.rate_plan_name}&nbsp;
                    </Typography>
                </Grid>
                <Grid item xs={3} style={{textAlign: "center"}}>
                    <Typography
                        color="textPrimary"
                        variant="body1"
                        className={commonTheme.breakWrap}
                    >
                        {currencyFormatter(service_charge.price_per_month)}
                    </Typography>
                </Grid>
                <Grid item xs={2} style={{textAlign: "center"}}>
                    <Typography
                        color="textPrimary"
                        variant="body1"
                        className={commonTheme.breakWrap}
                    >
                        {requestQuote.period}
                    </Typography>
                </Grid>
                <Grid item xs={3} style={{textAlign: "right"}}>
                    <Typography
                        color="textPrimary"
                        variant="body1"
                        className={commonTheme.breakWrap}
                    >
                        {currencyFormatter(service_charge.billing_sub_total)}
                    </Typography>
                </Grid>
            </Grid>
        )
    }

import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {serviceContractContentOperations} from "../../../../../../state/ducks/user/service_contracts/contents";
import userSideBarLinks from "../../../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json"
import {rootOperations} from "../../../../../../state/ducks/root";
import {useParams} from "react-router";
import ServiceContractsContentsModifyView from "../../../../../components/user/serviceContracts/contents/modify/Page";
import {ConfirmPage} from "../../../../../components/user/serviceContracts/contents/modify/ConfirmPage";
import {CompletedPage} from "../../../../../components/user/serviceContracts/contents/modify/CompletedPage";


/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,

        updatePlanStep: state.serviceContractContent.updatePlanStep,

        confirmChangePlanLoading: state.serviceContractContent.confirmChangePlanLoading,
        confirmChangePlanError: state.serviceContractContent.confirmChangePlanError,

        serviceContractContentAlterBasicPlan: state.serviceContractContent.serviceContractContentAlterBasicPlan,
        serviceContractContentAlterPlan: state.serviceContractContent.serviceContractContentAlterPlan,
        serviceContractContentData: state.serviceContractContent.serviceContractContentData,
        note: state.serviceContractContent.note,
        terms_agreed: state.serviceContractContent.terms_agreed
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    loadSidebarItems: rootOperations.loadSidebarItems,

    contractUpdateToConfirmPage: serviceContractContentOperations.contractUpdateToConfirmPage,
    contractBackToUpdatePage: serviceContractContentOperations.contractBackToUpdatePage,
    confirmContractUpdate: serviceContractContentOperations.confirmContractUpdate,
    toggleTerms: serviceContractContentOperations.toggleTerms
}

/***
 * ///////////////////////////////////////////////////////////
 * CONTAINER VS COMPONENT (コンテイナ対コンポーネント)
 * --------------------------------
 * コンテナは、任意のアクションを *実行* し、任意の redux ストアやオペレーションをロードして、コンポーネントにプッシュします。
 * コンポーネントは、可能な限りダムでなければなりません。つまり、目に見えるデータのみを表示する必要があります。
 * ///////////////////////////////////////////////////////////
 */

/*
通常ユーザー側の申込コンテイナー
*/
const _modifyServiceContractContentContainer = (
    {
        managerProfile,
        loadSidebarItems,
        updatePlanStep,

        contractUpdateToConfirmPage,
        contractBackToUpdatePage,
        confirmContractUpdate,

        confirmChangePlanLoading,
        confirmChangePlanError,

        serviceContractContentAlterBasicPlan,
        serviceContractContentAlterPlan,
        serviceContractContentData,
        note,

        terms_agreed,
        toggleTerms,

        t,
    }) => {
    // 利用契約一覧をAPIから取得する。
    useEffect(() => {
        loadSidebarItems(userSideBarLinks.navigation.service_contracts)
    }, [loadSidebarItems])

    let {scid} = useParams();

    if (updatePlanStep === 0) {
        return (
            <ServiceContractsContentsModifyView
                serviceContractContentAlterBasicPlan={serviceContractContentAlterBasicPlan}
                serviceContractContentAlterPlan={serviceContractContentAlterPlan}
                contractUpdateToConfirmPage={contractUpdateToConfirmPage}
                serviceContractContentData={serviceContractContentData}
                serviceContractId={scid}
                t={t}
            />
        )
    } else if (updatePlanStep === 1) {
        return (
            <ConfirmPage
                t={t}
                contractBackToUpdatePage={contractBackToUpdatePage}
                inquiry={note}
                serviceContractContentData={serviceContractContentData}
                serviceContractContentAlterBasicPlan={serviceContractContentAlterBasicPlan}
                serviceContractContentAlterPlan={serviceContractContentAlterPlan}
                managerProfile={managerProfile}
                notes={note}
                terms_agreed={terms_agreed}
                toggleTerms={toggleTerms}
                confirmContractUpdate={confirmContractUpdate}
                confirmChangePlanLoading={confirmChangePlanLoading}
                confirmChangePlanError={confirmChangePlanError}
                scid={scid}
            />
        )
    } else {
        return (
            <CompletedPage
                serviceContractContentData={serviceContractContentData}
                serviceContractId={scid}
                t={t}
            />
        )
    }
}

/**
 *
 * * Redux
 * * i18next Translations
 * を
 * UserView コンポーネントに追加する。
 */
const ModifyServiceContractContentContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_modifyServiceContractContentContainer)

export default ModifyServiceContractContentContainer;

import {takeEvery, takeLeading, takeLatest} from "redux-saga/effects";
import {default as types} from "./types";
import {
    cancelRequestSaga,
    changeContractApproved,
    downloadRequestQuotePDF,
    parseServiceContractNameRequest,
    requestsDetailLoad,
    requestsLoad,
    requestsQuoteLoad,
    requestsSearch,
    sendEbisInformation
} from "./sagas";

/**
 * //////////////////////////////////////////
 * SAGA WATCHERS (サガ・ウォッチャー)
 * --------------------
 * actions.jsからのすべてのアクションは、ここで読み込まれてからreducerに送られます。
 * イベントタイプが一致した場合、下記の第2パラメータの関数が呼び出され、任意のアクションデータを使用することができます。
 * ////////////////////////////////////////////
 */

export function* fetchRequests() {
    yield takeLeading(types.REQUESTS_SEARCH, requestsSearch);
    yield takeLeading(types.REQUESTS_LOAD, requestsLoad);
    yield takeLeading(types.REQUESTS_DETAIL_LOAD, requestsDetailLoad);
    yield takeEvery(types.REQUESTS_DETAIL_QUOTE_DETAIL_LOAD, requestsDetailLoad)
    yield takeLeading(types.REQUESTS_DETAIL_QUOTE_DETAIL_LOAD, requestsQuoteLoad)
    yield takeLeading(types.REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF, downloadRequestQuotePDF)
    yield takeLeading(types.SETTING_EBIS_ACCOUNT_INFORMATION_CONFIRMED, sendEbisInformation)
    yield takeLeading(types.CONTRACT_CHANGE_QUOTE_APPROVED_REQUEST, changeContractApproved)
    yield takeLeading(types.REQUESTS_DETAIL_CANCEL_REQUEST, cancelRequestSaga)
    yield takeLeading(types.REQUEST_SERVICE_CONTRACT_ID_QUERY, parseServiceContractNameRequest)
}

import React, {useEffect} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router";
import TransferPage from "../../../../components/user/serviceContracts/transfer/TransferPage";
import TransferConfirmPage from "../../../../components/user/serviceContracts/transfer/ConfirmPage";
import TransferCompletePage from "../../../../components/user/serviceContracts/transfer/CompletePage";
import userSideBarLinks from "../../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json";
import {rootOperations} from "../../../../../state/ducks/root";
import {serviceContractTransferOperations} from "../../../../../state/ducks/user/service_contracts/transfer";
import {empty} from "../../../../../state/utils/Common";
import Auth0Utils from "../../../../../state/utils/Auth0Utils";
import {useAuth0} from "@auth0/auth0-react";
import {serviceContractOperations} from "../../../../../state/ducks/user/service_contracts";

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,
        transferForm: state.serviceContractTransfer.transferForm,
        transfer_accepted_terms: state.serviceContractTransfer.transfer_accepted_terms,

        transferRequestLoading: state.serviceContractTransfer.transferRequestLoading,
        transferRequestError: state.serviceContractTransfer.transferRequestError,

        transferDataLoading: state.serviceContractTransfer.transferDataLoading,
        transferData: state.serviceContractTransfer.transferData,
        transferDataError: state.serviceContractTransfer.transferDataError,
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    loadSidebarItems: rootOperations.loadSidebarItems,
    saveTransferForm: serviceContractTransferOperations.saveTransferForm,
    sendTransferRequest: serviceContractTransferOperations.sendTransferRequest,

    getTransferData: serviceContractTransferOperations.getTransferData,
    transferToggleTerms: serviceContractTransferOperations.transferToggleTerms,
    transferResetTerms: serviceContractTransferOperations.transferResetTerms,
    saveContractNameToSidebarLink: serviceContractOperations.saveContractNameToSidebarLink
}

/*
利用契約 -> 連絡先コンテイナ
 */
const _transferContainer = (
    {
        managerProfile,
        loadSidebarItems,
        saveTransferForm,
        transferForm,

        transferRequestLoading,
        transferRequestError,
        sendTransferRequest,

        saveContractNameToSidebarLink,

        transferDataLoading,
        transferData,
        transferDataError,
        getTransferData,

        transferToggleTerms,
        transferResetTerms,
        transfer_accepted_terms,

        t
    }) => {

    let {scid, action} = useParams();
    const history = useHistory();

    // auth0 アクセストークン取得
    const {getAccessTokenSilently} = useAuth0();

    useEffect(() => {
        transferResetTerms()
    }, [transferResetTerms, action])

    // 利用契約一覧をAPIから取得する。
    useEffect(() => {
        loadSidebarItems(userSideBarLinks.navigation.service_contracts)
    }, [loadSidebarItems])

    const SendSuccessCallBackAction = () => history.push('/service_contracts/' + scid + '/transfer/complete');

    // 利用契約一覧をAPIから取得する。
    useEffect(() => {
        // ログインしたユーザープロフィール取得したかどうかのチェック
        if (!empty(managerProfile) && empty(transferData)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    },
                );
                getTransferData(access_token, managerProfile?.account?.uuid, scid)
            })()
        }
    }, [getAccessTokenSilently, scid, managerProfile, getTransferData])

    useEffect(() => {
        if(!empty(managerProfile) && !empty(transferData)) {
            saveContractNameToSidebarLink(transferData?.service_contract?.service_account_name, transferData?.service_contract?.cancelable)
        }
    }, [managerProfile, transferData, saveContractNameToSidebarLink])

    if (action === "confirm" && empty(transferForm)) {
        history.push('/service_contracts/' + scid + '/transfer')
        return (<></>)
    }



    switch (action) {
        default:
            return (
                <TransferPage
                    transferData={transferData}
                    t={t}
                    service_contract_id={scid}
                    formData={transferForm}
                    saveTransferForm={saveTransferForm}
                />);
        case ("confirm"):
            return (
                <TransferConfirmPage
                    transferData={transferData}
                    t={t}
                    transferRequestLoading={transferRequestLoading}
                    transferRequestError={transferRequestError}
                    sendTransferRequest={sendTransferRequest}
                    transferToggleTerms={transferToggleTerms}
                    transfer_accepted_terms={transfer_accepted_terms}

                    transferForm={transferForm}
                    managerProfile={managerProfile}
                    SendSuccessCallBackAction={SendSuccessCallBackAction}
                    service_contract_id={scid}
                />);
        case("complete"):
            return (
                <TransferCompletePage
                    service_contract_id={scid}
                    transferData={transferData}
                    t={t}
                />);
    }
}

/**
 * Redux
 * i18next Translations
 * を
 * _contentsContainer コンポーネントに追加する。
 */
const TransferContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_transferContainer)

export default TransferContainer;

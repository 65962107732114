import {Box, Card, FormControl, Grid, MenuItem, Select, Typography} from "@material-ui/core";
import React, {useState} from "react";
import {empty} from "../../../../state/utils/Common";
import globalTheme from "../../../theme/globalTheme";
import {AddCircleRounded, LibraryBooksRounded, RemoveCircleRounded} from "@material-ui/icons";


export const TransferRow = (
    {
        RowTitle,
        DropDownOptions,
        y = 1,
        x = 2,
        url,
        data,
        addPlanEvent = null,
        removePlanEvent = null,
        changeItemAction = null,
        changePlanAction = null,
        undoBasicPlanUpdate = null,
        defaultTier,
        allowRounded = false,
        savedTierItem,
        t
    }) => {
    const globalFormClasses = globalTheme.defaultFormStyle();


    let style = {};

    if (allowRounded) {
        style = {borderRadius: 4}
    }

    const [selectedId, setId] =
        useState(!empty(savedTierItem) ? savedTierItem[0].id :
            !empty(defaultTier) ? defaultTier :
            !empty(DropDownOptions) ? DropDownOptions[0].id : 0);

    function changeItemFunction(plan_id) {
        setId(plan_id)
        if(!empty(changeItemAction)) {
            changeItemAction(data, defaultTier, plan_id);
        }
        if(!empty(changePlanAction) && plan_id !== defaultTier) {
            changePlanAction(data, DropDownOptions.filter((item) => { return item.product_rate_plan_id === plan_id}));
        }
        if(!empty(changePlanAction) && plan_id === defaultTier) {
            undoBasicPlanUpdate()
        }
    }

    return (
        <Card style={style}>
            <Box py={y} px={x}>
                <Grid container alignItems={"center"} alignContent={"center"} justify={"center"}>
                    <Grid item xs={12} md={4} lg={5} xl={7}>
                        <Typography variant={"body1"}>
                            {RowTitle}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={7} xl={5} style={{textAlign: "right"}}>
                        {!empty(DropDownOptions) &&
                            <FormControl className={globalFormClasses.selectWidth110}>
                                <Select
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null
                                    }}
                                    value={selectedId}
                                    onChange={e => changeItemFunction(e.target.value)}
                                    variant={"outlined"}
                                    labelId="demo-simple-select-label"
                                >
                                    {DropDownOptions.map((item) => {
                                        if(empty(item?.id)) {
                                            return (
                                                <MenuItem key={item.product_rate_plan_id} value={item.product_rate_plan_id}>{item.product_rate_plan_id === defaultTier && t('service_contracts.contents.modify.item_in_use') } {item?.product_rate_plan_name}</MenuItem>
                                            )
                                        }
                                        if(!empty(item?.product_rate_plan_name)) {
                                            return (
                                                <MenuItem key={item.id}
                                                          value={item.id}>{item?.product_rate_plan_name}</MenuItem>
                                            )
                                        }
                                        return (<MenuItem key={item.id}
                                            value={item.id}>{item?.selectable_option}</MenuItem>)
                                    })}
                                </Select>
                            </FormControl>
                        }
                        {!empty(url) &&
                            <a href={url} target={"plans"}>
                                <LibraryBooksRounded className={globalFormClasses.toolTip}/>
                            </a>
                        }
                        {!empty(addPlanEvent) &&
                            <AddCircleRounded onClick={() => addPlanEvent(data, selectedId)} className={globalFormClasses.positiveOption}/>
                        }
                        {!empty(removePlanEvent) &&
                            <RemoveCircleRounded onClick={() => removePlanEvent(data)} className={globalFormClasses.negativeOption}/>
                        }
                    </Grid>
                </Grid>
            </Box>
        </Card>
    )
}

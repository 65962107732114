import {userListOperations} from "../../../../state/ducks/user/users";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import UserView from "../../../components/user/users/Page";
import {rootOperations} from "../../../../state/ducks/root";
import {useAuth0} from "@auth0/auth0-react";
import {useEffect} from "react";
import {empty} from "../../../../state/utils/Common";
import Auth0Utils from "../../../../state/utils/Auth0Utils";
import userSideBarLinks from "../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json";

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        reLoad: state.root.reLoad,
        profile: state.root.managerProfile,

        loading: state.user.loading,
        users: state.user.userList,
        isRegisterView: state.user.isRegisterView,
        userRegistrationLoad: state.user.registerLoading,
        registerError: state.user.registerError,
        searchLoad: state.user.searchLoad,
        searchUserResult: state.user.searchUserResult,
        userList_CurrentPage: state.user.userList_CurrentPage,
        activeProfile: state.user.activeProfile,
        deleteView: state.user.deleteView,
        deleteLoading: state.user.deleteLoading,
        deleteError: state.user.deleteError,

        unblockNotificationShow: state.user.unblockNotificationShow,

        resendNotificationShow: state.user.resendNotificationShow
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    loadSidebarItems: rootOperations.loadSidebarItems,

    toggleRegisterView: userListOperations.toggleRegister,
    registerUser: userListOperations.registerUser,
    searchUser: userListOperations.requestSearchUsers,
    changePage: userListOperations.userChangePage,
    updateUser: userListOperations.updateUser,
    viewUserDeleteToggle: userListOperations.viewUserDeleteToggle,
    deleteUsers: userListOperations.deleteUsers,
    loadUserList: userListOperations.loadUserList,

    unlockAuth0User: userListOperations.unlockAuth0User,
    closeUnlockAuth0UserModal: userListOperations.closeUnlockAuth0UserModal,

    resendAuth0VerifyMail: userListOperations.resendAuth0VerifyMail,
    resendAuth0VerifyMailCloseNotification: userListOperations.resendAuth0VerifyMailCloseNotification,
}

/**
 * ユーザーコンテイナー
 * @param loading
 * @param activeProfile
 * @param profile
 * @param updateUser
 * @param isRegisterView
 * @param toggleRegisterView
 * @param userRegistrationLoad
 * @param registerError
 * @param registerUser
 * @param searchLoad
 * @param searchUserResult
 * @param reLoad
 * @param searchUser
 * @param users
 * @param deleteView
 * @param deleteLoading
 * @param deleteError
 * @param viewUserDeleteToggle
 * @param deleteUsers
 * @param loadUserList
 * @param loadSidebarItems
 * @param unlockAuth0User
 * @param unblockNotificationShow
 * @param closeUnlockAuth0UserModal
 * @param resendNotificationShow
 * @param resendAuth0VerifyMail
 * @param resendAuth0VerifyMailCloseNotification
 * @param t
 * @returns {JSX.Element}
 * @private
 */
const _userAccountContainer = (
    {
        loading,
        activeProfile,
        profile,
        updateUser,
        isRegisterView,
        toggleRegisterView,
        userRegistrationLoad,
        registerError,
        registerUser,
        searchLoad,
        searchUserResult,
        reLoad,
        searchUser,
        users,
        deleteView,
        deleteLoading,
        deleteError,
        viewUserDeleteToggle,
        deleteUsers,
        loadUserList,
        loadSidebarItems,
        unlockAuth0User,
        unblockNotificationShow,
        closeUnlockAuth0UserModal,
        resendNotificationShow,
        resendAuth0VerifyMail,
        resendAuth0VerifyMailCloseNotification,
        t
    }
) => {
    // auth0 アクショントークンを取得
    const {getAccessTokenSilently} = useAuth0();
    //アプリケーションテーマを取得
    useEffect(() => {
        if (reLoad && !empty(profile)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    },
                );
                loadUserList(access_token, profile?.account?.uuid);

            })();
        }
    }, [loadUserList, getAccessTokenSilently, profile, reLoad])

    const account_id = profile?.account?.uuid;
    /**
     * ナビ項目のロードエベント
     */
    useEffect(() => {
        loadSidebarItems(userSideBarLinks.navigation.user)
    }, [loadSidebarItems])

    return (
        <UserView
            loading={loading}
            activeProfile={activeProfile}
            profile={profile}
            account_id={account_id}
            updateUser={updateUser}
            isRegisterView={isRegisterView}
            toggleRegisterView={toggleRegisterView}
            userRegistrationLoad={userRegistrationLoad}
            registerError={registerError}
            registerUser={registerUser}
            searchLoad={searchLoad}
            searchUserResult={searchUserResult}
            reLoad={reLoad}
            searchUser={searchUser}
            users={users}
            deleteView={deleteView}
            deleteLoading={deleteLoading}
            deleteError={deleteError}
            viewUserDeleteToggle={viewUserDeleteToggle}
            deleteUsers={deleteUsers}
            loadUserList={loadUserList}
            loadSidebarItems={loadSidebarItems}
            unlockAuth0User={unlockAuth0User}
            unblockNotificationShow={unblockNotificationShow}
            closeUnlockAuth0UserModal={closeUnlockAuth0UserModal}
            resendNotificationShow={resendNotificationShow}
            resendAuth0VerifyMail={resendAuth0VerifyMail}
            resendAuth0VerifyMailCloseNotification={resendAuth0VerifyMailCloseNotification}
            t={t}
        />
    )
}

/**
 * Redux
 * i18next Translations
 * を
 * UserView コンポーネントに追加する。
 */
const UserAccountContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_userAccountContainer)

export default UserAccountContainer;

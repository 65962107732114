import {call, put} from 'redux-saga/effects';
import {
    deleteLoading,
    deleteUserFailure,
    deleteUserSuccess,
    failureSearchUsers,
    failureUserList,
    loadedUserList,
    loadingSearchUsers,
    loadingUserList,
    loadUserList,
    registerNewUserFailure,
    registerNewUserSuccess, resendAuth0VerifyMailFailure, resendAuth0VerifyMailLoading, resendAuth0VerifyMailSuccess,
    successSearchUsers,
    unlockAuth0UserFailed,
    unlockAuth0UserLoading,
    unlockAuth0UserSuccess,
    userRegistrationLoad
} from "./actions";
import {addUsers, callUsers, deleteUsers, resendVerificationEmailAPI, unlockAuth0UserAPI, updateUsers} from "./api";
import {error_raise} from "../../root/actions";

/***
 * ////////////////
 * SAGA MIDDLEWARE (SAGAミドルウェア)
 * ---------------
 *
 * SAGAミドルウェアは、アクションとリデューサ間のアクションを解析し、アクションのTYPEに基づいてAPI関数を処理します。
 * すべてのSAGAミドルウェアは、<i>watchersSagas.js</i>から呼び出されます。
 * ////////////////
 */

/**
 * ユーザー取得リクエスト
 * @param data
 */
export function* selectUserList(data) {
    yield put(loadingUserList())
    try {
        const users = yield call(callUsers, data.payload.access_token, data.payload?.user_id, null);
        yield put(loadedUserList(users));
    } catch (e) {
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
        yield put(failureUserList(e));
    }
}

/**
 * 新規ユーザーリクエスト
 * @param userData
 */
export function* addUserList(userData) {
    yield put(userRegistrationLoad());
    try {
        yield call(addUsers, userData.payload.user, userData.payload.access_token, userData.payload.uuid)
        yield put(registerNewUserSuccess());
        yield put(loadUserList(userData.payload.access_token, userData.payload.uuid))
    } catch (e) {
        yield put(registerNewUserFailure(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * ユーザー更新リクエスト
 * @param userData
 */
export function* updateUser(userData) {
    yield put(userRegistrationLoad())
    try {
        yield call(updateUsers, userData.payload.user_id, userData.payload.access_token, userData.payload?.current_user_id, userData.payload.user)
        yield put(registerNewUserSuccess());
        yield put(loadUserList(userData.payload.access_token, userData.payload?.current_user_id))
    } catch (e) {
        yield put(registerNewUserFailure(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * ユーザー検索リクエスト
 * @param data
 */
export function* filterUserList(data) {
    const userSearchTerm = {search: data.payload.searchUsers};
    yield put(loadingSearchUsers())
    try {
        const searchResults = yield call(callUsers, data.payload.access_token, userSearchTerm)
        yield put(successSearchUsers(searchResults.data))
    } catch (e) {
        yield put(failureSearchUsers(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * ユーザー削除リクエスト
 * @param accountData
 */
export function* deleteUser(accountData) {
    yield put(deleteLoading())
    try {
        yield call(deleteUsers, accountData.payload.user, accountData.payload.access_token, accountData.payload.account_id)
        yield put(deleteUserSuccess())
        yield put(loadUserList(accountData.payload.access_token, accountData.payload.account_id))
    } catch (e) {
        yield put(deleteUserFailure(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * ブロックされたauth0ユーザーの解除
 * @param accountData
 */
export function* unblockAuth0User(accountData) {
    yield put(unlockAuth0UserLoading())
    try {
        yield call(unlockAuth0UserAPI, accountData.payload.userId, accountData.payload.accessToken, accountData.payload.accountId)
        yield put(unlockAuth0UserSuccess())
        yield put(loadUserList(accountData.payload.accessToken, accountData.payload.accountId))
    } catch (e) {
        yield put(unlockAuth0UserFailed(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/***
 * 認証メールの再送信処理。
 * @param accountData
 */
export function* resendAuth0VerificationEmail(accountData) {
    yield put(resendAuth0VerifyMailLoading())
    try{
        yield call(resendVerificationEmailAPI, accountData.payload.userId, accountData.payload.accessToken, accountData.payload.accountId)
        yield put(resendAuth0VerifyMailSuccess())
    } catch (e) {
        yield put(resendAuth0VerifyMailFailure(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}


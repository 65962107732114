import React from 'react';
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    Chip,
    Container,
    Divider,
    FormLabel,
    Grid,
    makeStyles,
    Typography
} from '@material-ui/core';
import Page from "../../Page";
import {Field, Form} from "react-final-form";
import {useHistory} from "react-router";
import Validation, {translate} from "../../../../../state/utils/Validation";
import {empty, mergeWithDefaultForm} from "../../../../../state/utils/Common";
import FinalFormCheckBox from "../../../../atoms/FinalFormCheckBox";
import FinalFormTextArea from "../../../../atoms/FinalFormTextArea";
import globalTheme from "../../../../theme/globalTheme";
import {serviceContractTerminateForms} from "../../../../../state/ducks/user/service_contracts/terminate";
import {
    terminateServiceContractValidation,
    terminateServiceContractWithoutPreferredDay
} from "../../../../../state/ducks/user/service_contracts/terminate/validation";
import FinalFormFieldIndependentError from "../../../../atoms/FinalFormFieldIndependentError";
import EditableCheck from "../../../../atoms/EditableCheck";
import urlLinks from "../../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json"
import {Link} from "react-router-dom";
import FinalFormDate from "../../../../atoms/FinalFormDate";
import NotEditableCheck from "../../../../atoms/NotEditableCheck";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(1)
    },
    button: {
        fontWeight: 900,
    }
}));

/**
 *
 * @param t
 * @param message
 * @returns {JSX.Element}
 * @constructor
 */
const TerminatePage = (
    {
        t,
        formData,
        response,
        service_contract_id,

        saveTerminateForm
    }) => {
    const classes = useStyles();
    const globalStyle = globalTheme.defaultFormStyle();
    const history = useHistory();
    return (
        <Page
            className={classes.root}
            title={t('service_contracts.terminate.title', {service_contract_name: !empty(response?.service_contract?.service_account_name) ? response?.service_contract?.service_account_name : ""})}
        >
            <Container maxWidth={false}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Typography
                            variant="h2">{t('service_contracts.terminate.title', {service_contract_name: !empty(response?.service_contract?.service_account_name) ? response?.service_contract?.service_account_name : ""})}</Typography>
                    </Grid>
                </Grid>
                <Grid container alignContent={"center"} alignItems={"center"} style={{paddingTop: 6}}>
                    <Grid item xs={12} md={12}>
                        <Breadcrumbs separator={">"} aria-label="breadcrumb">
                            <Link to={urlLinks.navigation.top.index.href}>
                                <Typography color="textPrimary">{t('general.top')}</Typography>
                            </Link>
                            <Link to={urlLinks.navigation.service_contracts.index.href}>
                                <Typography color="textPrimary">{t('service_contracts.title')}</Typography>
                            </Link>
                            <Link
                                to={urlLinks.navigation.service_contracts.index.sub_links.terminate.href.replace('{SCID}', service_contract_id)}>
                                <Typography
                                    color="textPrimary">{t('service_contracts.terminate.breadcrumb')}</Typography>
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Box mt={2}>
                    <Card>
                        <Box p={2}>
                            <Typography
                                variant={"h4"}>{t('service_contracts.terminate.terminate_form.header')}</Typography>
                        </Box>
                        <Divider/>
                        <Box p={2}>
                            <Form
                                onSubmit={(e) => {
                                    e = mergeWithDefaultForm(e, serviceContractTerminateForms.terminateForm);
                                    saveTerminateForm(e);
                                    history.push('/service_contracts/' + service_contract_id + '/terminate/confirm');
                                }}
                                // ここでフォームデータを妥当性確認し、キーを変換します。
                                validate={e => {
                                    if (!response?.allow_prefer_ym) {
                                        return translate(t, Validation({...e}, terminateServiceContractWithoutPreferredDay))
                                    }
                                    return translate(t, Validation({...e}, terminateServiceContractValidation))
                                }}
                                // （編集モード）最初のバリュー
                                initialValues={formData}
                                // ここでは、フォームのレンダリングと制御を行います
                                // エラー処理やダブルクリック防止などはここで行います
                                render={({handleSubmit, form, pristine, invalid, values}) => (
                                    <form onSubmit={handleSubmit} noValidate>
                                        <Box paddingBottom={"20px"}>
                                            <FormLabel>{t('service_contracts.terminate.terminate_form.associated_account')}</FormLabel>
                                            <Box paddingTop={1}>
                                                <Typography variant={"body1"}>
                                                    <span
                                                        style={{paddingRight: 25}}>{response?.service_contract?.service_account_id}</span>
                                                    <span
                                                        style={{paddingRight: 60}}>{response?.service_contract?.service_account_name}</span>
                                                    <span>
                                                    {t('service_contracts.terminate.terminate_form.next_update_date', { 'update_date': response?.renewal_date })}
                                                    </span>
                                                </Typography>
                                            </Box>
                                        </Box>
                                        {response?.service_contract?.allow_prefer_ym === true &&
                                        /** 解約希望年月（入力) -> 任意入力可能 */
                                        <Box paddingBottom={2}>
                                            <Grid container>
                                                <Grid item xs={12} sm={5} md={2}>
                                                    <Field
                                                        fullWidth
                                                        name="cancel_prefferd_ym"
                                                        required={true}
                                                        dateFormat={'YYYY/MM'}
                                                        minDate={response?.service_contract?.today}
                                                        maxDate={response?.renewal_ym}
                                                        component={FinalFormDate}
                                                        okByDateClick={true}
                                                        size="small"
                                                        type="text"
                                                        views={["year", "month"]}

                                                        label={t('service_contracts.terminate.terminate_form.preferred_date_of_terminate')}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        }
                                        {response?.service_contract?.allow_prefer_ym === false &&
                                        /** 解約希望年月（ラベル) -> 任意入力不可*/
                                        <Box paddingBottom={2}>
                                            <Grid container>
                                                <Grid item xs={12} sm={5} md={2}>
                                                    <label class="MuiFormLabel-root">
                                                        {t('service_contracts.terminate.terminate_form.preferred_date_of_terminate')}
                                                    </label>
                                                    <div
                                                        class="MuiFormControl-root MuiTextField-root makeStyles-textfield-19  MuiFormControl-fullWidth">
                                                        <Box paddingTop={1}>
                                                            <Typography variant={"body1"}>
                                                                {response?.renewal_ym}
                                                            </Typography>
                                                        </Box>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        }
                                        <Box paddingBottom={1}>
                                            {/** 解約理由１ */}
                                            <Grid container>
                                                <Grid item xs={12} sm={5} md={2}>
                                                    <FormLabel>{t('service_contracts.terminate.terminate_form.terminate_reason')}
                                                        <Chip label={"必須"} size="small" className={globalStyle.chip}/>
                                                    </FormLabel>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        <Box paddingX={1}>
                                            {!empty(response?.cancel_reasons) && response.cancel_reasons.map((item) => {
                                                return (<Field
                                                    name="cancel_reasons1"
                                                    listStyle={"vertical"}
                                                    type={"checkbox"}
                                                    size={"small"}
                                                    component={FinalFormCheckBox}
                                                    value={item.value}
                                                    label={item.label}
                                                />)
                                            })}
                                            <FinalFormFieldIndependentError name={"cancel_reasons1"}/>
                                            <Grid container style={{marginTop: 20}}>
                                                <Grid item xs={12} md={6} lg={4}>
                                                    {/** 解約理由２ */}
                                                    <Box paddingBottom={"20px"}>
                                                        <Field
                                                            fullWidth={true}
                                                            name={"cancel_reason2"}
                                                            component={FinalFormTextArea}
                                                            required={false}
                                                            rows={5}
                                                            label={t('service_contracts.terminate.terminate_form.reason_for_option')}
                                                        />
                                                    </Box>
                                                    {/** 解約理由３ */}
                                                    <Box paddingBottom={"20px"}>
                                                        <Field
                                                            fullWidth={true}
                                                            name={"cancel_reason3"}
                                                            component={FinalFormTextArea}
                                                            required={false}
                                                            rows={5}
                                                            label={t('service_contracts.terminate.terminate_form.changing_service_to')}
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>


                                        <Box paddingBottom={"20px"}>
                                            <Grid container>
                                                <Grid item xs={12} md={7}>
                                                    <Box bgcolor={"#EDE8DF"} style={{padding: 15}}>
                                                        <Box paddingBottom={2}>
                                                            <Typography variant={"body1"}>
                                                                {t('service_contracts.terminate.terminate_form.expiry_warning')}
                                                            </Typography>
                                                            <Typography variant={"subtitle2"}>
                                                                {t('service_contracts.terminate.terminate_form.download_data_warning')}
                                                            </Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography variant={"body1"}>
                                                                {t('service_contracts.terminate.terminate_form.advert_redirect_over')}
                                                            </Typography>
                                                            <Typography variant={"subtitle2"}>
                                                                {t('service_contracts.terminate.terminate_form.advert_redirect_detail')}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        <Box paddingBottom={5}>
                                            <Grid container justify={"center"} alignItems={"center"}
                                                  alignContent={"center"}>
                                                <Grid item xs={12} style={{textAlign: "center"}}>
                                                    <EditableCheck>
                                                        <Button disabled={invalid} type={"submit"} variant={"contained"}
                                                                color={"primary"}>
                                                            {t('actions.confirm')}
                                                        </Button>
                                                    </EditableCheck>
                                                    <NotEditableCheck>
                                                        <Button disabled={true} type={"button"} variant={"contained"}
                                                                color={"primary"}>
                                                            {t('actions.confirm')}
                                                        </Button>
                                                    </NotEditableCheck>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </form>)}
                            />
                        </Box>
                    </Card>
                </Box>
            </Container>
        </Page>
    );
}

export default TerminatePage;

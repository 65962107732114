/**
 * //////////////////////////////////
 * フォームテンプレー
 * /////////////////////////////////
 *
 * コンポーネントのフォームテンプレはここに書いています。
 * ＨＴＭＬフォームで未入力状態でフォーム送信を実行すればフォームの入力キーが無くなってしまうので、nullフィールドがあっても、
 * 必ずフォームテンプレを送信するようにここにファイルが必要です。
 */

/**
 * 移管フォーム
 * @type {{phone: null, company_name: null, last_name: null, preferred_ym: null, first_name: null, email: null}}
 */
const transferForm = {
    preferred_ym:null,
    company_name:null,
    last_name:null,
    first_name:null,
    phone:null,
    email:null
}

export const forms = {
    transferForm
}

export default forms

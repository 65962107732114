import React from 'react';
import {Box, Breadcrumbs, Button, Container, Grid, makeStyles, Typography} from '@material-ui/core';
import Page from "../../Page";
import ContentsList from "./list/ContentsList";
import {Link} from "react-router-dom";
import urlLinks from "../../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json"

/**
 * /manager/notices コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(1)
    },
    button: {
        fontWeight: 900,
    }
}));
/**
 *
 * /noticesのメインコンポーネント
 * @param managerLoadNoticeList
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const ServiceContractsContentsView = (
    {
        serviceContractContentData,
        serviceContractContentLoading,
        serviceContractId,
        t,
    }) => {
    const classes = useStyles();
    return (
        <Page
            className={classes.root}
            title={t('service_contracts.contents.title', {contract_name: serviceContractContentData?.service_contract?.service_account_name})}
        >
            <Container maxWidth={false}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Typography
                            variant="h2">{t('service_contracts.contents.title', {contract_name: serviceContractContentData?.service_contract?.service_account_name})}</Typography>
                    </Grid>
                    <Grid container item xs={12} md={6} justify="flex-end">
                        <Link
                            to={'/service_contracts/' + serviceContractId + "/contents/modify"}
                            data-testid="show-more-button">
                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.button}
                                data-testid="create-button"
                                onClick={() => console.log("OKOK")}
                            >
                                {t('service_contracts.contents.plan_update_contact_button')}
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
                <Grid container alignContent={"center"} alignItems={"center"}>
                    <Grid item xs={12} md={12}>
                        <Breadcrumbs separator={">"} aria-label="breadcrumb">
                            <Link to={urlLinks.navigation.top.index.href}>
                                <Typography color="textPrimary">{t('general.top')}</Typography>
                            </Link>
                            <Link to={urlLinks.navigation.service_contracts.index.href}>
                                <Typography color="textPrimary">{t('service_contracts.title')}</Typography>
                            </Link>
                            <Link
                                to={urlLinks.navigation.service_contracts.index.sub_links.contents.href.replace('{SCID}', serviceContractId)}>
                                <Typography
                                    color="textPrimary">{t('service_contracts.contents.breadcrumb')}</Typography>
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Box mt={1}>
                    {/* 契約プラン一覧 */}
                    <ContentsList
                        t={t}
                        contents={serviceContractContentData}
                        className={""}
                        contentsLoading={serviceContractContentLoading}
                    />
                </Box>
            </Container>
        </Page>
    );
}

export default ServiceContractsContentsView;

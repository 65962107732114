import {Button, Grid, Typography} from "@material-ui/core";
import React from "react";
import globalTheme from "../../../../theme/globalTheme";
import CreateIcon from "@material-ui/icons/Create";
import LockIcon from "@material-ui/icons/Lock";
import SendSharpIcon from "@material-ui/icons/SendSharp";
import DeleteIcon from "@material-ui/icons/Delete";
import RestrictSystem from "../../../../atoms/RestrictSystem";

export const UsersListRow = props => {
    const commonTheme = globalTheme._default();
    return (
        <Grid container key={props.data.users[props.index].uuid} style={{
            ...props.style,
            paddingLeft: 21,
            paddingTop: 13,
            paddingBottom: 13,
            paddingRight: 29,
            borderBottom: "1px #CFCFCF solid"
        }}>
            <Grid item xs={3} zeroMinWidth={true}>
                <Typography
                    noWrap={true}
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.users[props.index].last_name} {props.data.users[props.index].first_name}
                </Typography>
            </Grid>
            <Grid item xs={3} zeroMinWidth={true}>
                <Typography
                    noWrap={true}
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.users[props.index].email}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.t(props.data.userRoles.find((value) => {
                        return value.value === props.data.users[props.index]?.role_type
                    })?.translation_view)}
                </Typography>
            </Grid>
            <Grid item xs={1} style={{textAlign: "center"}}>
                <Button
                    variant={"text"}
                    size={"small"}
                    className={commonTheme.listButton}
                    onClick={() => props.data.handleEditClick(props.data.users[props.index])}
                >
                    <CreateIcon style={{
                        fontSize: 12,
                        marginRight: 3,
                        marginTop: "-3px"
                    }}/>{props.data.t('users.list_contents.edit')}
                </Button>
            </Grid>
            <Grid item xs={1} style={{textAlign: "center"}}>
                <RestrictSystem>
                    <Button
                        variant={"text"}
                        size={"small"}
                        className={commonTheme.listButton}
                        onClick={() => props.data.unlockAuth0User(props.data.users[props.index].uuid)}
                    >
                        <LockIcon
                            style={{fontSize: 12, marginRight: 3}}/>{props.data.t('users.list_contents.password_lock')}
                    </Button>
                </RestrictSystem>
            </Grid>
            <Grid item xs={1} style={{textAlign: "center"}}>
                <RestrictSystem>
                    {props.data.users[props.index].email_verified === false &&
                    <Button
                        variant={"text"}
                        size={"small"}
                        className={commonTheme.listButton}
                        onClick={() => props.data.resendAuth0VerifyMail(props.data.users[props.index].uuid)}
                    >
                        <SendSharpIcon
                            style={{
                                fontSize: 12,
                                marginRight: 3,
                                marginTop: "-3px"
                            }}/>{props.data.t('users.list_contents.auth_re_send')}
                    </Button>
                    }
                </RestrictSystem>
            </Grid>
            <Grid item xs={1} style={{textAlign: "center"}}>
                <RestrictSystem>
                    <Button
                        color="textPrimary"
                        variant={"text"}
                        size={"small"}
                        className={commonTheme.listButton}
                        onClick={() => props.data.handleDeleteClick(props.data.users[props.index])}
                    >
                        <DeleteIcon
                            style={{
                                fontSize: 12,
                                marginRight: 3,
                                marginTop: "-3px"
                            }}/>{props.data.t('users.list_contents.delete_user')}
                    </Button>
                </RestrictSystem>
            </Grid>
        </Grid>
    )
}

/***
 * ////////////////////////////////////////////////
 * ACTIONS (アクション)
 * ------------------
 *
 * コンポーネントやコンテナから要求・呼び出しされるアクション
 * アクションには、REDUCER FILEまたはSAGAミドルウェアにイベントをブロードキャストするためのTYPEキーが必要で、REDUCERまたはSAGAミドルウェアは以下を解析します。
 * ////////////////////////////////////////////////
 */

import types from "./types";

//-------------------------------------------------
// お知らせ一覧
//-------------------------------------------------

/**
 * お知らせ一覧取得リクエスト
 * @param {string} access_token - auth0アクセストークン
 * @param {string} account_id - ログインしたユーザーのアカウントID
 * @returns {{payload: {access_token, user_id}, type: string}}
 */
export function loadNotificationList(access_token, account_id) {
    console.log(account_id);
    return {
        type: types.REQUEST_NOTIFICATIONS,
        payload: {
            access_token: access_token,
            account_id: account_id
        }
    };
}

/**
 * お知らせ一覧取得リクエスト（ロード中）
 * @returns {{type: string}}
 */
export function loadingNotificationList() {
    return {
        type: types.REQUEST_NOTIFICATIONS_LOADING
    }
}

/***
 * お知らせ一覧取得リクエスト（成功）
 * @param {Object} notifications 取得したお知らせデータ
 * @returns {{payload, type: string}}
 */
export function loadedNotificationList(notifications ) {
    return {
        type: types.REQUEST_NOTIFICATIONS_SUCCESS,
        payload: notifications
    }
}

/***
 * お知らせ一覧取得リクエスト（失敗)
 * @param error
 * @returns {{payload: {error}, type: string}}
 */
export function failureNotificationList(error) {
    return {
        type: types.REQUEST_NOTIFICATIONS_FAILURE,
        payload: {
            error: error
        }
    }
}

//-------------------------------------------------
// 全てのお知らせの一覧
//-------------------------------------------------

/**
 * 全てのお知らせ一覧取得リクエスト
 * @param {string} access_token - auth0アクセストークン
 * @param {string} account_id - ログインしたユーザーのアカウントID
 * @returns {{payload: {access_token, user_id}, type: string}}
 */
export function loadNotificationAllList(access_token, account_id) {
    console.log(account_id);
    return {
        type: types.REQUEST_ALL_NOTIFICATIONS,
        payload: {
            access_token: access_token,
            account_id: account_id
        }
    };
}

/**
 * 全てのお知らせ一覧取得リクエスト（ロード中）
 * @returns {{type: string}}
 */
export function loadingNotificationAllList() {
    return {
        type: types.REQUEST_ALL_NOTIFICATIONS_LOADING
    }
}

/***
 * 全てのお知らせ一覧取得リクエスト（成功）
 * @param {Object} notifications 取得したお知らせデータ
 * @returns {{payload, type: string}}
 */
export function loadedNotificationAllList(notifications ) {
    return {
        type: types.REQUEST_ALL_NOTIFICATIONS_SUCCESS,
        payload: notifications
    }
}

/***
 * 全てのお知らせ一覧取得リクエスト（失敗)
 * @param error
 * @returns {{payload: {error}, type: string}}
 */
export function failureNotificationAllList(error) {
    return {
        type: types.REQUEST_ALL_NOTIFICATIONS_FAILURE,
        payload: {
            error: error
        }
    }
}


const actions = {
    loadNotificationList,
    loadingNotificationList,
    loadedNotificationList,
    failureNotificationList,

    loadNotificationAllList,
    loadingNotificationAllList,
    loadedNotificationAllList,
    failureNotificationAllList
};
export default actions;

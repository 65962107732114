/***
 * /////////////////////////////////////////////////////////////////
 * CONSTANTS (定数）
 * ----------------------------
 *
 * TYPEではないmanagerAccountsに関連するすべての定数について
 * 例を挙げると
 * API エンドポイント
 * /////////////////////////////////////////////////////////////////
 *
 */
export const MANAGER_GET_ACCOUNTS_API_PATH = "/manager/accounts/";
export const MANAGER_SALESFORCE_ACCOUNT_API_PATH = '/manager/salesforce/accounts'
export const MANAGER_SALESFORCE_USER_API_PATH = '/manager/salesforce/accounts/{SFID}/contacts'
export const MANAGER_GET_ACCOUNT_USERS_API_PATH = "/manager/accounts/{SFID}/users"
export const MANAGER_GET_ACCOUNT_DETAIL_API_PATH = "/manager/accounts/{SFID}/service_contracts"

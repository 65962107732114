import {Button, Grid, Typography} from "@material-ui/core";
import React from "react";
import globalTheme from "../../../../theme/globalTheme";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import EditableCheck from "../../../../atoms/EditableCheck";

export const AddressesListRow = props => {
    const commonTheme = globalTheme._default();
    return (
        <Grid container key={props.data.addresses[props.index].uuid} alignContent={"center"} alignItems={"center"}
              justify={"center"}
              style={{
                  ...props.style,
                  paddingLeft: 21,
                  paddingTop: 13,
                  paddingBottom: 13,
                  paddingRight: 45,
                  borderBottom: "1px #CFCFCF solid"
              }}>
            <Grid item xs={2}>
                <Typography
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.t('address.postal_code_sign')}{props.data.addresses[props.index].postal_code}
                </Typography>
            </Grid>
            <Grid item xs={8} zeroMinWidth={true}>
                <Typography
                    noWrap={true}
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.addresses[props.index].state} {props.data.addresses[props.index].city} {props.data.addresses[props.index].address}
                </Typography>
            </Grid>
            <Grid item xs={1} style={{fontWeight: "bold", textAlign: "center"}}>
                <Button
                    style={{marginTop: 1}}
                    variant={"text"}
                    size={"small"}
                    className={commonTheme.listButton}
                    onClick={() => props.data.handleEditClick(props.data.addresses[props.index])}
                >
                    <CreateIcon
                        style={{fontSize: 12, marginRight: 3, marginTop: "-3px"}}/> {props.data.t('actions.edit')}
                </Button>
            </Grid>
            <Grid item xs={1} style={{fontWeight: "bold", textAlign: "center"}}>
                <EditableCheck>
                    <Button
                        style={{marginTop: 1}}
                        variant={"text"}
                        size={"small"}
                        className={commonTheme.listButton}
                        onClick={() => props.data.handleDeleteClick(props.data.addresses[props.index])}
                    >
                        <DeleteIcon
                            style={{fontSize: 12, marginRight: 3, marginTop: "-3px"}}/>
                        {props.data.t('actions.delete')}
                    </Button>
                </EditableCheck>
            </Grid>
        </Grid>
    )
}

import React from 'react';
import {Box, makeStyles, Typography} from '@material-ui/core';
import {TransferRow} from "../../../../../../atoms/rows/transferList/TransferRow";
import globalTheme from "../../../../../../theme/globalTheme";
import {empty} from "../../../../../../../state/utils/Common";

/**
 * /manager/notices コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(1)
    },
    button: {
        fontWeight: 900,
    }
}));
/**
 *
 * /noticesのメインコンポーネント
 * @param managerLoadNoticeList
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const AllPlanContentsView = (
    {
        t,
        serviceContractContentAllPlanData,
        serviceContractContentAllPlanLoading,
        serviceContractContentAlterPlan,
        addPlan,
        savedTierItem,
        activeServiceContract
    }) => {
    const classes = useStyles();
    const allTheme = globalTheme.defaultFormStyle();

    return (
        <>
            <Box mt={1}>
                <Typography style={{fontSize: 13, marginTop: 15, marginBottom: 10, fontWeight: 900}}>
                    {t('service_contracts.contents.modify.all_plan')}
                </Typography>
            </Box>
            {serviceContractContentAllPlanLoading === false &&
                !empty(serviceContractContentAllPlanData?.options) &&
                <Box mt={1} className={allTheme.groupListRounded}>
                    {serviceContractContentAllPlanData?.options.map((item) => {
                        if(serviceContractContentAlterPlan.some((plan) => plan.PlanData.product_rate_plan_id === item.product_rate_plan_id)) return "";
                        if(!empty(activeServiceContract) && activeServiceContract.plans.options.some((planContract) => planContract.product_rate_plan_id === item.product_rate_plan_id)) return "";
                        if(item?.tiers.length > 1) {
                            return (<TransferRow
                                key={item.id}
                                DropDownOptions={item.tiers}
                                data={item}
                                url={item?.product_url}
                                RowTitle={item.product_rate_plan_name}
                                hasInfoTip={true}
                                addPlanEvent={addPlan}
                                savedTierItem={savedTierItem.filter((items, index) => {
                                    return items.product_rate_plan_id === item.product_rate_plan_id
                                })}
                            />)
                        }
                        return (<TransferRow key={item.id} DropDownOptions={null} data={item} url={item?.product_url} RowTitle={item.product_rate_plan_name} hasInfoTip={true}
                                             addPlanEvent={addPlan}/>)
                    })}
                < /Box>
            }
        </>
    );
}

export default AllPlanContentsView;

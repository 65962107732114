import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {useAuth0} from "@auth0/auth0-react";
import React, {useEffect} from "react";
import {empty} from "../../../../../../state/utils/Common";
import Auth0Utils from "../../../../../../state/utils/Auth0Utils";
import {useParams} from "react-router";
import CurrentPlanContentsView
    from "../../../../../components/user/serviceContracts/contents/modify/currentPlanContents/Page";
import {serviceContractOperations} from "../../../../../../state/ducks/user/service_contracts";
import {serviceContractContentOperations} from "../../../../../../state/ducks/user/service_contracts/contents";
import {undoBasicPlanUpdate} from "../../../../../../state/ducks/user/service_contracts/contents/actions";


/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,

        serviceContractContentData: state.serviceContractContent.serviceContractContentData,
        serviceContractContentLoading: state.serviceContractContent.serviceContractContentLoading,

        serviceContractContentAlterPlan: state.serviceContractContent.serviceContractContentAlterPlan,
        serviceContractContentAllPlanData: state.serviceContractContent.serviceContractContentAllPlanData,
        savedTierItem: state.serviceContractContent.savedTierItem
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    serviceContractContentLoad: serviceContractContentOperations.serviceContractContentLoad,
    removePlan: serviceContractContentOperations.removePlan,
    changeItem: serviceContractContentOperations.changeItem,
    changePlan: serviceContractContentOperations.changePlan,
    undoBasicPlanUpdate: serviceContractContentOperations.undoBasicPlanUpdate
}

/***
 * ///////////////////////////////////////////////////////////
 * CONTAINER VS COMPONENT (コンテイナ対コンポーネント)
 * --------------------------------
 * コンテナは、任意のアクションを *実行* し、任意の redux ストアやオペレーションをロードして、コンポーネントにプッシュします。
 * コンポーネントは、可能な限りダムでなければなりません。つまり、目に見えるデータのみを表示する必要があります。
 * ///////////////////////////////////////////////////////////
 */

/*
通常ユーザー側の申込コンテイナー
*/
const _currentPlanContentsContainer = (
    {
        managerProfile,
        serviceContractContentLoad,
        serviceContractContentData,
        serviceContractContentLoading,
        t,
        removePlan,
        changeItem,
        changePlan,

        serviceContractContentAlterPlan,
        serviceContractContentAllPlanData,
        savedTierItem,
        undoBasicPlanUpdate

    }) => {
    // auth0 アクセストークン取得
    const {getAccessTokenSilently} = useAuth0();
    let {scid} = useParams();
    // 利用契約一覧をAPIから取得する。
    useEffect(() => {
        // ログインしたユーザープロフィール取得したかどうかのチェック
        if (!empty(managerProfile)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    },
                );
                serviceContractContentLoad(access_token, managerProfile?.account?.uuid, scid)
            })()
        }
    }, [getAccessTokenSilently, serviceContractContentLoad, managerProfile, scid])

    return (
        <CurrentPlanContentsView
            t={t}
            undoBasicPlanUpdate={undoBasicPlanUpdate}
            serviceContractContentData={serviceContractContentData}
            serviceContractContentLoading={serviceContractContentLoading}
            removePlanAction={removePlan}
            changeItemAction={changeItem}
            changePlanAction={changePlan}
            serviceContractContentAlterPlan={serviceContractContentAlterPlan}
            serviceContractContentAllPlanData={serviceContractContentAllPlanData}
            savedTierItem={savedTierItem}
        />
    )
}

/**
 *
 * * Redux
 * * i18next Translations
 * を
 * UserView コンポーネントに追加する。
 */
const CurrentPlanContentsContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_currentPlanContentsContainer)

export default CurrentPlanContentsContainer;

import React from 'react';
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    Container,
    Divider,
    FormLabel,
    Grid,
    makeStyles,
    Typography
} from '@material-ui/core';
import Page from "../../Page";
import {Field, Form} from "react-final-form";
import {useHistory} from "react-router";
import Validation, {translate} from "../../../../../state/utils/Validation";
import {empty, mergeWithDefaultForm} from "../../../../../state/utils/Common";
import FinalFormTextField from "../../../../atoms/FinalFormTextField";
import {transferValidation} from "../../../../../state/ducks/user/service_contracts/transfer/validation";
import {serviceContractTransferForms} from "../../../../../state/ducks/user/service_contracts/transfer";
import EditableCheck from "../../../../atoms/EditableCheck";
import urlLinks from "../../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json"
import {Link} from "react-router-dom";
import FinalFormDate from "../../../../atoms/FinalFormDate";
import NotEditableCheck from "../../../../atoms/NotEditableCheck";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(1)
    },
    button: {
        fontWeight: 900,
    }
}));

/**
 *
 * @param t
 * @param message
 * @param receiveContactFormMessage
 * @returns {JSX.Element}
 * @constructor
 */
const TransferPage = (
    {
        t,
        formData,
        transferData,
        service_contract_id,
        saveTransferForm,
    }) => {
    const classes = useStyles();
    const history = useHistory();
    return (
        <Page
            className={classes.root}
            title={t('service_contracts.transfer.title', {service_contract_name: !empty(transferData) ? transferData?.service_contract?.service_account_name : ""})}
        >
            <Container maxWidth={false}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Typography
                            variant="h2">{t('service_contracts.transfer.title', {service_contract_name: !empty(transferData) ? transferData?.service_contract?.service_account_name : ""})}</Typography>
                    </Grid>
                </Grid>
                <Grid container alignContent={"center"} alignItems={"center"} style={{paddingTop: 6}}>
                    <Grid item xs={12} md={12}>
                        <Breadcrumbs separator={">"} aria-label="breadcrumb">
                            <Link to={urlLinks.navigation.top.index.href}>
                                <Typography color="textPrimary">{t('general.top')}</Typography>
                            </Link>
                            <Link to={urlLinks.navigation.service_contracts.index.href}>
                                <Typography color="textPrimary">{t('service_contracts.title')}</Typography>
                            </Link>
                            <Link
                                to={urlLinks.navigation.service_contracts.index.sub_links.transfer.href.replace('{SCID}', service_contract_id)}>
                                <Typography
                                    color="textPrimary">{t('service_contracts.transfer.breadcrumb')}</Typography>
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Box mt={2}>
                    <Card>
                        <Box p={2}>
                            <Typography
                                variant={"h4"}>{t('service_contracts.transfer.transfer_form.header')}</Typography>
                        </Box>
                        <Divider/>
                        <Box p={2}>
                            <Form
                                onSubmit={(e) => {
                                    e = mergeWithDefaultForm(e, serviceContractTransferForms.transferForm);
                                    saveTransferForm(e);
                                    history.push('/service_contracts/' + service_contract_id + '/transfer/confirm');
                                }}
                                // ここでフォームデータを妥当性確認し、キーを変換します。
                                validate={e => {
                                    return translate(t, Validation({...e}, transferValidation))
                                }}
                                // （編集モード）最初のバリュー
                                initialValues={formData}
                                // ここでは、フォームのレンダリングと制御を行います
                                // エラー処理やダブルクリック防止などはここで行います
                                render={({handleSubmit, form, pristine, invalid, values}) => (
                                    <form onSubmit={handleSubmit} noValidate>
                                        <Box paddingBottom={4}>
                                            <FormLabel>{t('service_contracts.transfer.transfer_form.associated_account')}</FormLabel>
                                            <Box paddingTop={1}>
                                                <Typography variant={"body1"}>
                                                    <span
                                                        style={{paddingRight: 25}}>{transferData?.service_contract?.service_account_id}</span>
                                                    <span
                                                        style={{paddingRight: 60}}>{transferData?.service_contract?.service_account_name}</span>
                                                    <span>
                                                        {t('service_contracts.transfer.transfer_form.next_update_date', {update_date: !empty(transferData) ? transferData?.renewal_date : ""})}
                                                    </span>
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box paddingBottom={2}>
                                            <Grid container>
                                                <Grid item xs={12} sm={5} md={2}>
                                                    <Field
                                                        fullWidth
                                                        name="preferred_ym"
                                                        required={true}
                                                        dateFormat={'YYYY/MM'}
                                                        component={FinalFormDate}
                                                        views={["year", "month"]}
                                                        size="small"
                                                        okByDateClick={true}
                                                        type="text"
                                                        label={t('service_contracts.transfer.transfer_form.preferred_date_of_move')}
                                                    />
                                                    <Typography style={{paddingTop: 5}}
                                                                variant={"subtitle1"}>{t('service_contracts.transfer.transfer_form.next_update_note')}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box paddingBottom={2}>
                                            <Grid container>
                                                <Grid item xs={12} sm={7} md={4}>
                                                    <Field
                                                        fullWidth
                                                        name="company_name"
                                                        required={true}
                                                        component={FinalFormTextField}
                                                        size="small"
                                                        type="text"
                                                        label={t('service_contracts.transfer.transfer_form.move_to_company_name')}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box paddingBottom={2}>
                                            <FormLabel>{t('service_contracts.transfer.transfer_form.manager_contact_details')}</FormLabel>
                                        </Box>
                                        <Box paddingBottom={2}>
                                            <Grid container>
                                                <Grid item xs={12} sm={7} md={4}>
                                                    <Grid item container spacing={3}>
                                                        <Grid item xs={12} md={6}>
                                                            <Field
                                                                fullWidth
                                                                name="last_name"
                                                                required={true}
                                                                noBold={true}
                                                                component={FinalFormTextField}
                                                                size="small"
                                                                type="text"
                                                                label={t('service_contracts.transfer.transfer_form.last_name')}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <Field
                                                                fullWidth
                                                                name="first_name"
                                                                required={true}
                                                                noBold={true}
                                                                component={FinalFormTextField}
                                                                size="small"
                                                                type="text"
                                                                label={t('service_contracts.transfer.transfer_form.first_name')}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box paddingBottom={2}>
                                            <Grid container>
                                                <Grid item xs={12} sm={7} md={4}>
                                                    <Field
                                                        fullWidth
                                                        name="phone"
                                                        required={true}
                                                        component={FinalFormTextField}
                                                        noBold={true}
                                                        size="small"
                                                        type="text"
                                                        label={t('service_contracts.transfer.transfer_form.phone')}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box paddingBottom={2}>
                                            <Grid container>
                                                <Grid item xs={12} sm={7} md={4}>
                                                    <Field
                                                        fullWidth
                                                        name="email"
                                                        required={true}
                                                        noBold={true}
                                                        component={FinalFormTextField}
                                                        size="small"
                                                        type="text"
                                                        label={t('service_contracts.transfer.transfer_form.mail_address')}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box paddingBottom={5}>
                                            <Grid container justify={"center"} alignItems={"center"}
                                                  alignContent={"center"}>
                                                <Grid item xs={12} style={{textAlign: "center"}}>
                                                    <EditableCheck>
                                                        <Button disabled={invalid} type={"submit"} variant={"contained"}
                                                                color={"primary"}>
                                                            {t('actions.confirm')}
                                                        </Button>
                                                    </EditableCheck>
                                                    <NotEditableCheck>
                                                        <Button disabled={true} type={"button"} variant={"contained"}
                                                                color={"primary"}>
                                                            {t('actions.confirm')}
                                                        </Button>
                                                    </NotEditableCheck>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </form>)}
                            />
                        </Box>
                    </Card>
                </Box>
            </Container>
        </Page>
    );
}

export default TransferPage;

import {AccordionDetails, AccordionSummary, FormLabel, Grid, Typography, withStyles} from "@material-ui/core";

import MuiAccordion from "@material-ui/core/Accordion";
import React from "react";
import {AddCircleOutlineRounded, RemoveCircleOutlineRounded} from "@material-ui/icons";
import {currencyFormatter, empty} from "../../../../../../state/utils/Common";

const Accordion = withStyles({
    root: {
        "&$expanded": {
            margin: "auto",
        }
    },
    expanded: {}
})(MuiAccordion);

const IconLeftAccordionSummary = withStyles({
    root: {
        minHeight: "8px !important",
        "&$expanded": {
            minHeight: 30
        }
    },
    content: {
        margin: "10px 0 !important"
    },
    expandIcon: {
        order: -1
    }

})(AccordionSummary);

export const ServiceContractRow = ({serviceContract, t}) => {
    const [expanded, setExpanded] = React.useState('panel2');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <IconLeftAccordionSummary
                expandIcon={expanded === 'panel1' ? <RemoveCircleOutlineRounded style={{color: "#CCCCCC"}}/> :
                    <AddCircleOutlineRounded style={{color: "#CCCCCC"}}/>}
                aria-controls="panel1a-content"

                id="panel1a-header"
            >
                <FormLabel style={{marginLeft: 20}}>
                    {serviceContract.ebis_client_name}
                </FormLabel>

            </IconLeftAccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xl={2} style={{paddingLeft: 55}}>
                                <Typography variant={"subtitle2"} style={{fontWeight: 900}}>
                                    {t('accounts.service_contracts.list_header.service_contract_plan')}
                                </Typography>
                            </Grid>
                            <Grid item xl={10}>
                                <Typography variant={"subtitle2"}>
                                    {!empty(serviceContract?.service_names) &&
                                        serviceContract.service_names.join(',')
                                    }
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xl={2} style={{paddingLeft: 55}}>
                                <Typography variant={"subtitle2"} style={{fontWeight: 900}}>
                                    {t('accounts.service_contracts.list_header.service_contract_amount')}
                                </Typography>
                            </Grid>
                            <Grid item xl={10}>
                                <Typography variant={"subtitle2"}>
                                    {currencyFormatter(serviceContract.amount)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xl={2} style={{paddingLeft: 55}}>
                                <Typography variant={"subtitle2"} style={{fontWeight: 900}}>
                                    {t('accounts.service_contracts.list_header.next_update_date')}
                                </Typography>
                            </Grid>
                            <Grid item xl={10}>
                                <Typography variant={"subtitle2"}>
                                    {serviceContract.renewal_at}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Typography variant={"body1"} style={{marginLeft: 55, marginRight: 31, whiteSpace: "pre-line"}}>
                    {serviceContract?.detail}
                </Typography>
            </AccordionDetails>
        </Accordion>
    )
}

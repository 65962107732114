import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {useAuth0} from "@auth0/auth0-react";
import React, {useEffect} from "react";
import RequestsView from "../../../components/user/requests/Page";
import {rootOperations} from "../../../../state/ducks/root";
import userSideBarLinks from "../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json"
import {empty} from "../../../../state/utils/Common";
import Auth0Utils from "../../../../state/utils/Auth0Utils";
import {requestOperations} from "../../../../state/ducks/user/requests";
import {useLocation} from "react-router-dom";
import {loadRequestSearch} from "../../../../state/ducks/user/requests/actions";


/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,

        requestList: state.request.requestList,
        requestListLoading: state.request.requestListLoading,
        searchFormInformation: state.request.searchFormInformation,
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    loadSidebarItems: rootOperations.loadSidebarItems,
    loadRequestSearch: requestOperations.loadRequestSearch,
    parseServiceContractId: requestOperations.parseServiceContractId
}

/***
 * ///////////////////////////////////////////////////////////
 * CONTAINER VS COMPONENT (コンテイナ対コンポーネント)
 * --------------------------------
 * コンテナは、任意のアクションを *実行* し、任意の redux ストアやオペレーションをロードして、コンポーネントにプッシュします。
 * コンポーネントは、可能な限りダムでなければなりません。つまり、目に見えるデータのみを表示する必要があります。
 * ///////////////////////////////////////////////////////////
 */

/*
通常ユーザー側の申込コンテイナー
*/
const _requestsContainer = (
    {
        managerProfile,
        loadSidebarItems,
        loadRequestSearch,
        requestListLoading,
        requestList,
        parseServiceContractId,
        searchFormInformation,
        t
    }) => {

    // このステートをReducksに入れたらREDUX-DEVTOOLSがクラッシュするから、こちらのコンポーネントで検索非表示・表示ステートを管理する。
    const [anchorEl, setAnchorEl] = React.useState(null);

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl);

    // auth0 アクセストークン取得
    const {getAccessTokenSilently} = useAuth0();

    // 利用契約一覧をAPIから取得する。
    useEffect(() => {
        // ログインしたユーザープロフィール取得したかどうかのチェック
        if (!empty(managerProfile?.uuid)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    },
                );
                loadRequestSearch(searchFormInformation, access_token, managerProfile?.account?.uuid)
            })()
        }
    }, [getAccessTokenSilently, loadRequestSearch, managerProfile, searchFormInformation])

    // 利用契約一覧をAPIから取得する。
    useEffect(() => {
        loadSidebarItems(userSideBarLinks.navigation.requests)
    }, [loadSidebarItems])
    return (
        <RequestsView
            loadNoticeList={handleClick}
            searchFieldOpen={open}
            searchFieldCloseEvent={handleClose}
            anchorEl={anchorEl}
            loadRequestSearch={loadRequestSearch}
            requestListLoading={requestListLoading}
            requestList={requestList}
            t={t}
        />
    )
}

/**
 *
 * * Redux
 * * i18next Translations
 * を
 * UserView コンポーネントに追加する。
 */
const RequestsContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_requestsContainer)

export default RequestsContainer;

import React from 'react';
import {Box, Breadcrumbs, Card, Container, Divider, Grid, makeStyles, Typography} from '@material-ui/core';
import Page from "../../Page";
import {empty} from "../../../../../state/utils/Common";
import {Link} from "react-router-dom";
import urlLinks from "../../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json";


const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(1)
    },
    button: {
        fontWeight: 900,
    }
}));

/**
 *
 * @param t
 * @param message
 * @returns {JSX.Element}
 * @constructor
 */
const TerminateCompletePage = (
    {
        cancelReasonsData,
        service_contract_id,
        t,
    }) => {
    const classes = useStyles();
    return (
        <Page
            className={classes.root}
            title={t('service_contracts.terminate.title', {service_contract_name: !empty(cancelReasonsData?.service_contract?.service_account_name) ? cancelReasonsData?.service_contract?.service_account_name : "..."})}
        >
            <Container maxWidth={false}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h2">
                            {t('service_contracts.terminate.title', {service_contract_name: !empty(cancelReasonsData?.service_contract?.service_account_name) ? cancelReasonsData?.service_contract?.service_account_name : "..."})}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container alignContent={"center"} alignItems={"center"} style={{paddingTop: 6}}>
                    <Grid item xs={12} md={12}>
                        <Breadcrumbs separator={">"} aria-label="breadcrumb">
                            <Link to={urlLinks.navigation.top.index.href}>
                                <Typography color="textPrimary">{t('general.top')}</Typography>
                            </Link>
                            <Link to={urlLinks.navigation.service_contracts.index.href}>
                                <Typography color="textPrimary">{t('service_contracts.title')}</Typography>
                            </Link>
                            <Link
                                to={urlLinks.navigation.service_contracts.index.sub_links.terminate.href.replace('{SCID}', service_contract_id)}>
                                <Typography
                                    color="textPrimary">{t('service_contracts.terminate.breadcrumb')}</Typography>
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Box mt={2}>
                    <Card>
                        <Box p={2}>
                            <Typography
                                variant={"h4"}>{t('service_contracts.terminate.terminate_form.header')}</Typography>
                        </Box>
                        <Divider/>
                        <Box p={2}>
                            <Typography style={{whiteSpace: "pre-wrap"}}
                                        variant={"body1"}>{t('service_contracts.terminate.complete.description')}</Typography>
                            <Typography style={{marginBottom: 20, whiteSpace: "pre-wrap", fontWeight: "bold"}}
                                        variant={"body1"}>{t('service_contracts.terminate.complete.note')}</Typography>
                        </Box>
                    </Card>
                </Box>
            </Container>
        </Page>
    );
}

export default TerminateCompletePage;

import {Redirect, Route, Switch} from "react-router-dom";
import {ProtectedRoute} from "../../PrivateRoute";

import React from "react";
import UserAccountContainer from "./user/users/Users";
import AddressAccountContainer from "./user/addresses/AddressContainer";
import ManagerUserAccountContainer from "./manager/users/Users";
import ManagerAccountContainer from "./manager/accounts/AccountContainer";
import UserLoginContainer from "./user/UserLoginContainer";
import ManagerLoginContainer from "./manager/ManagerLoginContainer";
import ServiceContractsContainer from "./user/serviceContracts/ServiceContracts";
import ContentsContainer from "./user/serviceContracts/Contacts";
import managerAccountUserContainer from "./manager/accounts/UsersContainer";
import ManagerNoticeContainer from "./manager/notices/ManagerNoticeContainer";
import ManagerRequestsContainer from "./manager/requests/ManagerRequestsContainer";
import RequestsContainer from "./user/requests/RequestsContainer";
import RequestsDetailContainer from "./user/requests/RequestsDetailContainer";
import QuoteContainer from "./user/requests/detail/quote/QuoteContainer";
import SettingContainer from "./user/requests/detail/quote/apply/SettingContainer";
import ApplyNewContainer from "./user/requests/detail/quote/apply/ApplyNewContainer";
import ApplyContainer from "./user/requests/detail/quote/apply/ApplyContainer";
import ServiceContractContentContainer from "./user/serviceContracts/contents/ContentsContainer";
import ModifyServiceContractContentContainer from "./user/serviceContracts/contents/modify/ModifyContainer";
import ContactContainer from "./user/contact/ContactContainer";
import TransferContainer from "./user/serviceContracts/transfer/TransferContainer";
import TerminateContainer from "./user/serviceContracts/terminate/TerminateContainer";
import NotificationContainer from "./user/notifications/NotificationsContainer";
import AllNotificationsContainer from "./user/notifications/all/AllNotificationsContainer";
import {useAuth0} from "@auth0/auth0-react";
import {empty} from "../../state/utils/Common";
import ManagerQuoteContainer from "./manager/requests/detail/quote/ManagerQuoteContainer";
import ManagerRequestsDetailContainer from "./manager/requests/ManagerRequestsDetailContainer";
import TransferConfirmContainer from "./guest/transfer/TransferConfirmContainer";
import ManagerServiceContractContainer from "./manager/accounts/ManagerServiceContractContainer";

/**
 * urlに基づいてコンテナをロードする
 * @returns {JSX.Element}
 * @constructor
 */
const RouteContainer = () => {
    // auth0アクセストークンの取得
    const {user} = useAuth0();
    return (
        <div className="makeStyles-content-4">
            <Switch>
                {/** フロントログインのURLマッピング**/}
                {empty(user) &&
                    <Route exact path="/" component={UserLoginContainer}/>
                }
                {!empty(user) &&
                    <ProtectedRoute exact path="/" redirectTo="/" component={NotificationContainer}/>
                }

                {/** ログイン不要URLマッピング**/}
                <Route path={"/transfer/accept/:rid"} component={TransferConfirmContainer}/>

                {/** フロント側のURLマッピング**/}
                <ProtectedRoute path="/notifications/all" redirectTo="/" component={AllNotificationsContainer}/>
                <ProtectedRoute path="/users" redirectTo="/" component={UserAccountContainer}/>
                <ProtectedRoute path="/addresses" redirectTo="/" component={AddressAccountContainer}/>
                <ProtectedRoute path="/service_contracts/:scid/contents/modify" redirectTo="/" component={ModifyServiceContractContentContainer}/>
                <ProtectedRoute path="/service_contracts/:scid/contents" redirectTo="/" component={ServiceContractContentContainer}/>
                <ProtectedRoute path="/service_contracts/:id/contacts" redirectTo="/" component={ContentsContainer}/>
                <ProtectedRoute path="/service_contracts/:scid/transfer/:action" redirectTo={"/"} component={TransferContainer}/>
                <ProtectedRoute path="/service_contracts/:scid/transfer" redirectTo={"/"} component={TransferContainer}/>
                <ProtectedRoute path="/service_contracts/:scid/terminate/:action" redirectTo={"/"} component={TerminateContainer}/>
                <ProtectedRoute path="/service_contracts/:scid/terminate" redirectTo={"/"} component={TerminateContainer}/>
                <ProtectedRoute path="/service_contracts" redirectTo="/" component={ServiceContractsContainer}/>
                <ProtectedRoute path="/requests/:rid/quotes/:qid/apply_new" redirectTo="/" component={ApplyNewContainer}/>
                <ProtectedRoute path="/requests/:rid/quotes/:qid/apply" redirectTo="/" component={ApplyContainer}/>
                <ProtectedRoute path="/requests/:rid/quotes/:qid/setting" redirectTo="/" component={SettingContainer}/>
                <ProtectedRoute path="/requests/:rid/quotes/:qid" redirectTo="/" component={QuoteContainer}/>
                <ProtectedRoute path="/requests/:rid" redirectTo="/" component={RequestsDetailContainer}/>
                <ProtectedRoute path="/requests" redirectTo="/" component={RequestsContainer}/>
                <ProtectedRoute path="/contact/:action" redirectTo={"/"} component={ContactContainer}/>
                <ProtectedRoute path="/contact" redirectTo="/" component={ContactContainer}/>

                {/** 管理者側のURLマッピング**/}
                <Route exact path="/manager" component={ManagerLoginContainer}/>

                <ProtectedRoute path="/manager/users" redirectTo="/manager" component={ManagerUserAccountContainer}/>
                <ProtectedRoute path="/manager/accounts/:id/users" redirectTo="/manager" component={managerAccountUserContainer}/>
                <ProtectedRoute path="/manager/accounts/:id/service_contracts" redirectTo="/manager" component={ManagerServiceContractContainer}/>
                <ProtectedRoute path="/manager/accounts" redirectTo="/manager" component={ManagerAccountContainer}/>
                <ProtectedRoute path="/manager/notices" redirectTo="/manager" component={ManagerNoticeContainer}/>
                <ProtectedRoute path="/manager/requests/:rid/quotes/:qid" redirectTo="/manager" component={ManagerQuoteContainer}/>
                <ProtectedRoute path="/manager/requests/:rid" redirectTo="/manager" component={ManagerRequestsDetailContainer}/>
                <ProtectedRoute path="/manager/requests" redirectTo="/manager" component={ManagerRequestsContainer}/>
                <Redirect to="/"/>
            </Switch>
        </div>
    )
}
export default RouteContainer

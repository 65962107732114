import {TRANSFER_ACCEPT_API_PATH} from "./constants";
import ApiUtils from "../../../utils/ApiUtils";

/***
 * ///////////////////////////////////////////////
 * API
 * -----------------
 *
 * /notificationsのすべてのAPIイベントは、ここで呼び出されます。これらのメソッドは、
 * 主にSAGASから呼び出され、APIイベントは、エラーまたはデータオブジェクトを戻します。
 * ///////////////////////////////////////////////
 */

/**
 *
 */
export function getTransferAcceptAPIPath(account_id) {
    return new ApiUtils(null).get(
        TRANSFER_ACCEPT_API_PATH.replace('{AID}', account_id),
        "",
    )
}


/**
 *
 */
export function sendTransferAcceptAPIPath(account_id) {
    console.log(account_id);
    return new ApiUtils(null).put(
        TRANSFER_ACCEPT_API_PATH.replace('{AID}', account_id),
        "",
        "",
    )
}

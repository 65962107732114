import {AccordionDetails, AccordionSummary, Typography, withStyles} from "@material-ui/core";

import MuiAccordion from "@material-ui/core/Accordion";
import React from "react";
import {AddCircleOutlineRounded, RemoveCircleOutlineRounded} from "@material-ui/icons";

const Accordion = withStyles({
    root: {
        "&$expanded": {
            margin: "auto",
        }
    },
    expanded: {}
})(MuiAccordion);

const IconLeftAccordionSummary = withStyles({
    root: {
        minHeight: "8px !important",
        "&$expanded": {
            minHeight: 30
        }
    },
    content: {
        margin: "10px 0 !important"
    },
    expandIcon: {
        order: -1
    }

})(AccordionSummary);

export const NotificationsRow = ({notification}) => {
    const [expanded, setExpanded] = React.useState('panel2');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <IconLeftAccordionSummary
                expandIcon={expanded === 'panel1' ? <RemoveCircleOutlineRounded style={{color: "#CCCCCC"}}/> :
                    <AddCircleOutlineRounded style={{color: "#CCCCCC"}}/>}
                aria-controls="panel1a-content"

                id="panel1a-header"
            >
                {notification?.start_at &&
                <Typography variant={"body1"} className=""
                            style={{marginLeft: 20}}>[{notification.start_at}]</Typography>
                }
                <Typography variant={"body1"}
                            style={{marginLeft: 20, fontWeight: "bold"}}>{notification.title}</Typography>

            </IconLeftAccordionSummary>
            <AccordionDetails>
                <Typography variant={"body1"} style={{marginLeft: 55, marginRight: 31, whiteSpace: "pre-line"}}>
                    {notification.detail}
                </Typography>
            </AccordionDetails>
        </Accordion>
    )
}

import actions from "./actions";

/***
 * /////////////////////////////////////////////////////////
 * OPERATIONS オペレーション
 * --------------------
 *
 * ミドルウェアを必要とするすべてのアクションまたはアクションのためのバインドで、一緒にバインドされます。
 * このファイルでは、ミドルウェアのインジェクションを必要とするアクションはありませんが、その例がここにあります。
 * 例: https://github.com/alexnm/re-ducks#operations (英語)
 * ////////////////////////////////////////////////////////
 */

// アカウント取得
const managerLoadingAccountList = actions.managerLoadingAccountList;
const managerFailureAccountList = actions.managerFailureAccountList;
const managerLoadAccountList = actions.managerLoadAccountList;
const managerLoadedAccountList = actions.managerLoadedAccountList;

// モダール表示・非表示
const managerViewRegisterToggleInitSFAccountLoad = actions.managerViewRegisterToggleInitSFAccountLoad
const managerViewRegisterToggle = actions.managerViewRegisterToggle;
const managerViewDeleteToggle = actions.managerViewDeleteToggle;

// 新規アカウント
const managerRegisterNewAccount = actions.managerRegisterNewAccount;
const managerRegisterNewAccountLoading = actions.managerRegisterNewAccountLoading;
const managerRegisterNewAccountSuccess = actions.managerRegisterNewAccountSuccess;
const managerRegisterNewAccountFailure = actions.managerRegisterNewAccountFailure;

// アカウント検索
const managerRequestSearchAccounts = actions.managerRequestSearchAccounts;
const managerRequestChangeKSearchKeyWord = actions.managerRequestChangeKSearchKeyWord;

// アカウント編集
const managerViewEditToggle = actions.managerViewEditToggle;
const managerEditAccount = actions.managerEditAccount;
const managerEditAccountSuccess = actions.managerEditAccountSuccess;
const managerEditAccountFailure = actions.managerEditAccountFailure;
const managerAccountEditLoad = actions.managerAccountEditLoad;

// アカウント削除
const managerDeleteAccount = actions.managerDeleteAccount;
const managerDeleteAccountLoading = actions.managerDeleteAccountLoading;
const managerDeleteAccountSuccess = actions.managerDeleteAccountSuccess;
const managerDeleteAccountFailure = actions.managerDeleteAccountFailure;

// SFアカウント検索
const managerSearchSalesForceAccount = actions.managerSearchSalesForceAccount
const managerSelectedSalesForceAccountSuccess = actions.managerSelectedSalesForceAccountSuccess
const managerSelectedSalesForceAccountFailure = actions.managerSelectedSalesForceAccountFailure
const managerLoadingSalesForceAccount = actions.managerLoadingSalesForceAccount
const managerSelectedSalesForceAccount = actions.managerSelectedSalesForceAccount
const managerSelectedSalesForceAccountClear = actions.managerSelectedSalesForceAccountClear
// SFユーザー検索
const managerSearchSalesForceUser = actions.managerSearchSalesForceUser
const managerSelectedSalesForceUserSuccess = actions.managerSelectedSalesForceUserSuccess
const managerSelectedSalesForceUserFailure = actions.managerSelectedSalesForceUserFailure
const managerLoadingSalesForceUser = actions.managerLoadingSalesForceUser
const managerSelectedSalesForceUser = actions.managerSelectedSalesForceUser
const managerSelectedSalesForceAccount_LoadUsers = actions.managerSelectedSalesForceAccount_LoadUsers
const managerSelectedSalesForceUserClear = actions.managerSelectedSalesForceUserClear

// アカウントのユーザー取得
const managerSelectAccountUsers = actions.managerSelectAccountUsers
const managerSelectAccountUserLoad = actions.managerSelectAccountUserLoad
const managerSelectAccountUserSuccess = actions.managerSelectAccountUserSuccess
const managerSelectAccountUserFailure = actions.managerSelectAccountUserFailure

// アカウントページの変更
const managerChangePageAccountList = actions.managerChangePageAccountList

// フロント側にログインエベント
const managerLoginEvent = actions.managerLoginEvent

// ご契約内容照会情報の取得
const mangerGetAccountDetail = actions.mangerGetAccountDetail

const saveContractNameToSidebarLink = actions.saveContractNameToSidebarLink

// オペレーションのまとめ
const operations = {
    managerLoadingAccountList,
    managerFailureAccountList,
    managerLoadAccountList,
    managerLoadedAccountList,

    managerViewRegisterToggleInitSFAccountLoad,
    managerViewRegisterToggle,
    managerViewDeleteToggle,

    managerRegisterNewAccount,
    managerRegisterNewAccountLoading,
    managerRegisterNewAccountSuccess,
    managerRegisterNewAccountFailure,

    managerDeleteAccount,
    managerDeleteAccountLoading,
    managerDeleteAccountSuccess,
    managerDeleteAccountFailure,

    managerSearchSalesForceAccount,
    managerSelectedSalesForceAccountClear,
    managerSelectedSalesForceAccountSuccess,
    managerSelectedSalesForceAccountFailure,
    managerLoadingSalesForceAccount,
    managerSelectedSalesForceAccount,
    managerSearchSalesForceUser,
    managerSelectedSalesForceUserClear,
    managerSelectedSalesForceUserSuccess,
    managerSelectedSalesForceUserFailure,
    managerLoadingSalesForceUser,
    managerSelectedSalesForceUser,

    managerRequestSearchAccounts,
    managerRequestChangeKSearchKeyWord,

    managerViewEditToggle,
    managerEditAccount,
    managerEditAccountSuccess,
    managerEditAccountFailure,
    managerAccountEditLoad,

    managerSelectedSalesForceAccount_LoadUsers,

    managerChangePageAccountList,

    managerSelectAccountUsers,
    managerSelectAccountUserLoad,
    managerSelectAccountUserSuccess,
    managerSelectAccountUserFailure,

    managerLoginEvent,

    mangerGetAccountDetail,

    saveContractNameToSidebarLink
};
export default operations;

import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import React from "react";
import QuotesList from "../../../../components/user/requests/detail/list/QuotesList";


/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        related_quotes: state.request.requestDetail?.related_quotes
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {}

/***
 * ///////////////////////////////////////////////////////////
 * CONTAINER VS COMPONENT (コンテイナ対コンポーネント)
 * --------------------------------
 * コンテナは、任意のアクションを *実行* し、任意の redux ストアやオペレーションをロードして、コンポーネントにプッシュします。
 * コンポーネントは、可能な限りダムでなければなりません。つまり、目に見えるデータのみを表示する必要があります。
 * ///////////////////////////////////////////////////////////
 */

/*
通常ユーザー側の申込詳細コンテイナー
*/
const _requestsDetailContainer = (
    {
        related_quotes,
        requestUUID,
        t
    }) => {
    return (
        <QuotesList
            related_quotes={related_quotes}
            requestUUID={requestUUID}
            t={t}
        />
    )
}

/**
 *
 * * Redux
 * * i18next Translations
 * を
 * UserView コンポーネントに追加する。
 */
const RequestsDetailContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_requestsDetailContainer)

export default RequestsDetailContainer;

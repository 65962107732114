import types from "./types";

/***
 * ////////////////////////////////////////////////
 * ACTIONS (アクション)
 * ------------------
 *
 * コンポーネントやコンテナから要求・呼び出しされるアクション
 * アクションには、REDUCER FILEまたはSAGAミドルウェアにイベントをブロードキャストするためのTYPEキーが必要で、REDUCERまたはSAGAミドルウェアは以下を解析します。
 * ////////////////////////////////////////////////
 */

export function saveTerminateFormData(form) {
    return {
        type: types.SAVE_TERMINATE_FORM_DATA,
        payload: {
            form: form
        }
    }
}

export function toggleTerms() {
    return {
        type: types.TOGGLE_TERMS_AND_CONDITIONS,
    }
}

export function resetTerms() {
    return {
        type: types.RESET_TERMS_AND_CONDITIONS
    }
}

export function resetCancellationForm() {
    return {
        type: types.RESET_CANCELLATION_FORM
    }
}

//-------------------------------------------------
// 契約解約の理由の取得
//-------------------------------------------------

/***
 * 契約解約の理由の取得
 * @param access_token - auth0アクセストークン
 * @param accountId - ユーザーアカウントID
 * @param serviceContractId - 利用契約ID
 * @returns {{payload: {access_token, accountId, serviceContractId}, type: string}}
 */
export function getCancelContractReasons(access_token, accountId, serviceContractId) {
    console.log("KOKO");
    console.log(access_token);
    console.log(accountId);
    console.log(serviceContractId);
    return {
        type: types.GET_SERVICE_CONTRACT_CANCEL_REASONS,
        payload: {
            access_token: access_token,
            accountId: accountId,
            serviceContractId: serviceContractId,
        },
    }
}

/***
 * 契約解約の理由の取得（ロード中）
 * @returns {{type: string}}
 */
export function getCancelContractReasonsLoading() {
    return {
        type: types.GET_SERVICE_CONTRACT_CANCEL_REASONS_LOADING
    }
}

/***
 * 契約解約の理由の取得 (成功）
 * @returns {{payload: {activeServiceContract}, type: string}}
 * @param reasons
 */
export function getCancelContractReasonsSuccess(reasons) {
    return {
        type: types.GET_SERVICE_CONTRACT_CANCEL_REASONS_SUCCESS,
        payload: {
            reasons: reasons
        }
    }
}

/***
 * 契約解約の理由の取得（失敗）
 * @param e - エラー内容
 * @returns {{payload: {error}, type: string}}
 */
export function getCancelContractReasonsFailed(e) {
    return {
        type: types.GET_SERVICE_CONTRACT_CANCEL_REASONS_FAILED,
        payload: {
            error: e
        }
    }
}



//-------------------------------------------------
// 契約解約送信
//-------------------------------------------------
/***
 * @param access_token - auth0アクセストークン
 * @param accountId - ユーザーアカウントID
 * @param serviceContractId - 利用契約ID
 * @param form - フォーム内容
 * @param callBackAction - コールバックアクション
 * @returns {{payload: {access_token, accountId, serviceContractId}, type: string}}
 */
export function sendCancelServiceContract(access_token, accountId, serviceContractId, form, callBackAction) {
    return {
        type: types.SEND_SERVICE_CONTRACT_CANCEL_REQUEST,
        payload: {
            access_token: access_token,
            accountId: accountId,
            serviceContractId: serviceContractId,
            form: form
        },
        meta: {
            callBackAction: callBackAction
        }
    }
}

/***
 * 契約解約送信（ロード中）
 * @returns {{type: string}}
 */
export function sendCancelServiceContractLoading() {
    return {
        type: types.SEND_SERVICE_CONTRACT_CANCEL_REQUEST_LOADING
    }
}

/***
 * 契約解約送信 (成功）
 * @returns {{payload: {activeServiceContract}, type: string}}
 */
export function sendCancelServiceContractSuccess() {
    return {
        type: types.SEND_SERVICE_CONTRACT_CANCEL_REQUEST_SUCCESS,
    }
}

/***
 * 契約解約送信（失敗）
 * @param e - エラー内容
 * @returns {{payload: {error}, type: string}}
 */
export function sendCancelServiceContractFailed(e) {
    return {
        type: types.SEND_SERVICE_CONTRACT_CANCEL_REQUEST_FAILED,
        payload: {
            error: e
        }
    }
}


const actions = {
    saveTerminateFormData,
    toggleTerms,
    resetCancellationForm,

    getCancelContractReasons,
    getCancelContractReasonsLoading,
    getCancelContractReasonsSuccess,
    getCancelContractReasonsFailed,

    sendCancelServiceContract,
    sendCancelServiceContractLoading,
    sendCancelServiceContractSuccess,
    sendCancelServiceContractFailed,
    resetTerms,
};


export default actions;

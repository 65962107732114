import React from 'react';
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    Checkbox,
    Container,
    Divider,
    FormControlLabel,
    FormLabel,
    Grid, Link as LinkMaterial,
    makeStyles,
    Typography
} from '@material-ui/core';
import {Link} from "react-router-dom";
import Page from "../../Page";
import {useAuth0} from "@auth0/auth0-react";
import Auth0Utils from "../../../../../state/utils/Auth0Utils";
import {empty} from "../../../../../state/utils/Common";
import clsx from "clsx";
import EditableCheck from "../../../../atoms/EditableCheck";
import urlLinks from "../../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json";
import {Trans} from "react-i18next";


const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(1)
    },
    button: {
        fontWeight: 900,
    }
}));

/**
 *
 * @param t
 * @param message
 * @returns {JSX.Element}
 * @constructor
 */
const TransferConfirmPage = (
    {
        t,
        transferData,
        transferForm,
        service_contract_id,

        sendTransferRequest,
        transferRequestLoading,
        transferRequestError,

        SendSuccessCallBackAction,
        managerProfile,

        transferToggleTerms,
        transfer_accepted_terms,
    }) => {
    const classes = useStyles();
    const {getAccessTokenSilently} = useAuth0();
    return (
        <Page
            className={classes.root}
            title={t('service_contracts.transfer.title', {service_contract_name: !empty(transferData) ? transferData?.service_contract?.service_account_name : ""})}
        >
            <Container maxWidth={false}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Typography
                            variant="h2">{t('service_contracts.transfer.title', {service_contract_name: !empty(transferData) ? transferData?.service_contract?.service_account_name : ""})}</Typography>
                    </Grid>
                </Grid>
                <Grid container alignContent={"center"} alignItems={"center"} style={{paddingTop: 6}}>
                    <Grid item xs={12} md={12}>
                        <Breadcrumbs separator={">"} aria-label="breadcrumb">
                            <Link to={urlLinks.navigation.top.index.href}>
                                <Typography color="textPrimary">{t('general.top')}</Typography>
                            </Link>
                            <Link to={urlLinks.navigation.service_contracts.index.href}>
                                <Typography color="textPrimary">{t('service_contracts.title')}</Typography>
                            </Link>
                            <Link to={urlLinks.navigation.service_contracts.index.sub_links.transfer.href.replace('{SCID}', service_contract_id)}>
                                <Typography color="textPrimary">{t('service_contracts.transfer.breadcrumb')}</Typography>
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Box mt={2}>
                    <Card>
                        <Box p={2}>
                            <Typography
                                variant={"h4"}>{t('service_contracts.transfer.transfer_form.header')}</Typography>
                        </Box>
                        <Divider/>

                        <Box p={2}>
                            <Box paddingBottom={2} paddingTop={1}>
                                <Typography variant={"body1"}>
                                    {t('service_contracts.transfer.confirm.description')}
                                </Typography>
                            </Box>
                            <FormLabel>{t('service_contracts.transfer.transfer_form.associated_account')}</FormLabel>
                            <Box paddingTop={"3px"} paddingBottom={1}>
                                <Typography variant={"body1"}>
                                    <span
                                        style={{paddingRight: 25}}>{transferData?.service_contract?.service_account_id}</span>
                                    <span
                                        style={{paddingRight: 60}}>{transferData?.service_contract?.service_account_name}</span>
                                    <span>{t('service_contracts.transfer.transfer_form.next_update_date', {update_date: !empty(transferData) ? transferData?.renewal_date : ""})}</span>
                                </Typography>
                            </Box>

                            <FormLabel>{t('service_contracts.transfer.transfer_form.preferred_date_of_move')}</FormLabel>
                            <Box paddingTop={"3px"} paddingBottom={1}>
                                <Typography variant={"body1"}>
                                    {transferForm.preferred_ym}
                                </Typography>
                            </Box>

                            <FormLabel>{t('service_contracts.transfer.transfer_form.move_to_company_name')}</FormLabel>
                            <Box paddingTop={"3px"} paddingBottom={1}>
                                <Typography variant={"body1"}>
                                    {transferForm.company_name}
                                </Typography>
                            </Box>

                            <Box paddingTop={"3px"} paddingBottom={1}>
                                <FormLabel>{t('service_contracts.transfer.transfer_form.manager_contact_details')}</FormLabel>
                            </Box>


                            <Box paddingTop={"3px"} paddingBottom={1}>
                                <Grid container>
                                    <Grid item xs={12} md={4}>
                                        <Grid container>
                                            <Grid item xs={12} md={6}>
                                                <FormLabel
                                                    style={{fontWeight: 500}}>{t('service_contracts.transfer.transfer_form.last_name')}</FormLabel>
                                                <Typography variant={"body1"}>
                                                    {transferForm.last_name}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <FormLabel
                                                    style={{fontWeight: 500}}>{t('service_contracts.transfer.transfer_form.first_name')}</FormLabel>
                                                <Typography variant={"body1"}>
                                                    {transferForm.first_name}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>

                            <FormLabel
                                style={{fontWeight: 500}}>{t('service_contracts.transfer.transfer_form.phone')}</FormLabel>
                            <Box paddingTop={"3px"}>
                                <Typography variant={"body1"}>
                                    {transferForm.phone}
                                </Typography>
                            </Box>

                            <FormLabel
                                style={{fontWeight: 500}}>{t('service_contracts.transfer.transfer_form.mail_address')}</FormLabel>
                            <Box paddingTop={"3px"} paddingBottom={2}>
                                <Typography variant={"body1"}>
                                    {transferForm.email}
                                </Typography>
                            </Box>

                            <Box paddingBottom={"20px"}>
                                <Grid container>
                                    <Grid item xs={12} md={7}>
                                        <Box bgcolor={"#EDE8DF"}>
                                            <Typography style={{padding: 15, whiteSpace: "pre-wrap"}}>
                                                {t('service_contracts.transfer.confirm.warn')}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className={clsx(classes.root)} paddingX={1}>
                                <Grid container justify="flex-start" alignItems={"flex-start"} spacing={5}>
                                    <Grid item>
                                        <FormControlLabel
                                            color={"primary"}
                                            disabled={transferRequestLoading}
                                            control={
                                                <Checkbox
                                                    color={"primary"}
                                                    onChange={() => transferToggleTerms()}
                                                    checked={transfer_accepted_terms}
                                                />}
                                            label={
                                            <Trans i18nKey="general.agree_terms">
                                                { /** TODO: 時間があれば下記のリンクを環境設定変数に */}
                                                上記申込内容、お客様への注意事項、
                                                「<LinkMaterial target="_blank" style={{ color: "#0D66D0" }} href="https://www.ebis.ne.jp/policy/rule.php">AD EBiS 利用契約約款</LinkMaterial>」、
                                                「<LinkMaterial target="_blank" style={{ color: "#0D66D0" }} href="https://support.ebis.ne.jp/search_want/218/">ご利用に際して</LinkMaterial>」、
                                                「<LinkMaterial target="_blank" style={{ color: "#0D66D0" }} href="https://www.yrglm.co.jp/policy/">個人情報保護方針</LinkMaterial> 」 ならびに
                                                「<LinkMaterial target="_blank" style={{ color: "#0D66D0" }} href="https://www.yrglm.co.jp/policy/privacy_f/">個人情報の取扱い</LinkMaterial>」
                                                に同意の上、申し込みます。
                                            </Trans>
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box paddingBottom={5}>
                                <Grid container justify={"center"} alignItems={"center"} alignContent={"center"}>
                                    <Grid item xs={12} style={{textAlign: "center"}}>
                                        <Link to={"/service_contracts/" + service_contract_id + "/transfer"}
                                              disabled={transferRequestLoading}>
                                            <Button variant={"contained"} style={{marginRight: 15}} color={"secondary"}
                                                    disabled={transferRequestLoading}>
                                                {t('contact.confirm.back_to_input')}
                                            </Button>
                                        </Link>
                                        <EditableCheck>
                                            <Button onClick={() => (async () => {
                                                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                                                    () => {
                                                        return getAccessTokenSilently();
                                                    },
                                                );
                                                sendTransferRequest(access_token, managerProfile?.account?.uuid, service_contract_id, transferForm, SendSuccessCallBackAction)
                                            })()
                                            }
                                                    disabled={transferRequestLoading || !transfer_accepted_terms}
                                                    variant={"contained"}
                                                    color={"primary"}>
                                                {t('actions.send')}
                                            </Button>
                                        </EditableCheck>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Card>
                </Box>
            </Container>
        </Page>
    );
}

export default TransferConfirmPage;

import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {useAuth0} from "@auth0/auth0-react";
import React, {useEffect} from "react";
import {rootOperations} from "../../../../../../state/ducks/root";
import userSideBarLinks from "../../../../../../state/utils/data/sitemap/admin/sidebar_navigation_urls.json"
import {empty} from "../../../../../../state/utils/Common";
import Auth0Utils from "../../../../../../state/utils/Auth0Utils";
import {useParams} from "react-router";
import ManagerRequestQuoteDetailView from "../../../../../components/manager/requests/detail/quote/ManagerPage";
import {managerRequestOperations} from "../../../../../../state/ducks/manager/managerRequests";


/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,

        requestDetail: state.managerRequest.managerRequestDetail,
        requestQuote: state.managerRequest.managerRequestQuote,
        requestQuoteLoading: state.managerRequest.managerRequestQuoteLoading,

        managerTermsAndConditionsIsOpen: state.managerRequest.managerTermsAndConditionsIsOpen,
        managerPdfIsLoading: state.managerRequest.managerPdfIsLoading,
        managerPdfError: state.managerRequest.managerPdfError
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    loadSidebarItems: rootOperations.loadSidebarItems,
    requestsDetailQuoteDetailLoad: managerRequestOperations.managerRequestsDetailQuoteDetailLoad,
    managerCloseTermsAndConditionsModal: managerRequestOperations.managerCloseTermsAndConditionsModal,
    managerOpenTermsAndConditionsModal: managerRequestOperations.managerOpenTermsAndConditionsModal,
    managerDownloadQuotePDF: managerRequestOperations.managerDownloadQuotePDF
}

/***
 * ///////////////////////////////////////////////////////////
 * CONTAINER VS COMPONENT (コンテイナ対コンポーネント)
 * --------------------------------
 * コンテナは、任意のアクションを *実行* し、任意の redux ストアやオペレーションをロードして、コンポーネントにプッシュします。
 * コンポーネントは、可能な限りダムでなければなりません。つまり、目に見えるデータのみを表示する必要があります。
 * ///////////////////////////////////////////////////////////
 */

/*
通常ユーザー側の申込詳細コンテイナー
*/
const _managerQuoteContainer = (
    {
        managerProfile,
        loadSidebarItems,
        requestQuote,
        requestQuoteLoading,
        requestsDetailQuoteDetailLoad,
        requestDetail,
        managerTermsAndConditionsIsOpen,
        managerCloseTermsAndConditionsModal,
        managerOpenTermsAndConditionsModal,
        managerPdfIsLoading,
        managerPdfError,
        managerDownloadQuotePDF,
        t
    }) => {
    // auth0 アクセストークン取得
    const {getAccessTokenSilently} = useAuth0();
    let {rid, qid} = useParams();
    // 利用契約一覧をAPIから取得する。
    useEffect(() => {
        // ログインしたユーザープロフィール取得したかどうかのチェック
        if (!empty(managerProfile)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    },
                );
                requestsDetailQuoteDetailLoad(access_token, rid, qid)
            })()
        }
    }, [rid, qid, getAccessTokenSilently, requestsDetailQuoteDetailLoad, managerProfile])
    // 利用契約一覧をAPIから取得する。
    useEffect(() => {
        loadSidebarItems(userSideBarLinks.navigation.requests)
    }, [loadSidebarItems])

    return (
        <ManagerRequestQuoteDetailView
            requestId={rid}
            quoteId={qid}
            managerProfile={managerProfile}
            getAccessTokenSilently={getAccessTokenSilently}
            requestQuoteLoading={requestQuoteLoading}
            requestQuote={requestQuote}
            requestDetail={requestDetail}
            termsIsOpen={managerTermsAndConditionsIsOpen}
            termsOpen={managerOpenTermsAndConditionsModal}
            termsClose={managerCloseTermsAndConditionsModal}
            managerPdfIsLoading={managerPdfIsLoading}
            managerPdfError={managerPdfError}
            managerDownloadQuotePDF={managerDownloadQuotePDF}
            t={t}
        />
    )
}

/**
 *
 * * Redux
 * * i18next Translations
 * を
 * UserView コンポーネントに追加する。
 */
const ManagerQuoteContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_managerQuoteContainer)

export default ManagerQuoteContainer;

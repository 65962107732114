/***
 * //////////////////////////////////////////////////////////////
 * TYPES (タイプ)
 * ----------------------
 *
 * タイプは、アクション、リデューサ、saga(または他の)ミドルウェアで使用するための定数です。
 * Typeはアクションの中で、ミドルウェアやリデューサーに命令を伝えるために使われます。
 * 全てのアクションタイプは、グループ化しやすいように以下の配列に格納され、選択されます。
 * //////////////////////////////////////////////////////////////
 */

/** 検索エベント **/
export const REQUESTS_SEARCH = "REQUESTS_SEARCH";
export const REQUESTS_SEARCH_LOADING = "REQUESTS_SEARCH_LOADING";
export const REQUESTS_SEARCH_SUCCESS = "REQUESTS_SEARCH_SUCCESS";
export const REQUESTS_SEARCH_FAILED = "REQUESTS_SEARCH_FAILED";

/** 一覧取得エベント **/
export const REQUESTS_LOAD = "REQUESTS_LOAD";
export const REQUESTS_LOADING = "REQUESTS_LOADING";
export const REQUESTS_SUCCESS = "REQUESTS_SUCCESS";
export const REQUESTS_FAILED = "REQUESTS_FAILED";

/** フォーム編集 **/
export const EDIT_SEARCH_FORM = "EDIT_SEARCH_FORM";

/** お申込詳細 **/
export const REQUESTS_DETAIL_LOAD = "REQUESTS_DETAIL_LOAD";
export const REQUESTS_DETAIL_LOAD_LOADING = "REQUESTS_DETAIL_LOAD_LOADING";
export const REQUESTS_DETAIL_LOAD_SUCCESS = "REQUESTS_DETAIL_LOAD_SUCCESS";
export const REQUESTS_DETAIL_LOAD_FAILURE = "REQUESTS_DETAIL_LOAD_FAILURE";

/** お申込の見積詳細 **/
export const REQUESTS_DETAIL_QUOTE_DETAIL_LOAD = "REQUESTS_DETAIL_QUOTE_DETAIL_LOAD";
export const REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_LOADING = "REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_LOADING";
export const REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_SUCCESS = "REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_SUCCESS";
export const REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_FAILURE = "REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_FAILURE";

/** 申込の初期設定 **/
export const RESET_FORM = "RESET_FORM";
export const SETTING_EBIS_ACCOUNT_OPTION_FORM_INPUT_SETTING = "SETTING_EBIS_ACCOUNT_OPTION_FORM_INPUT_SETTING";
export const SETTING_EBIS_OPTION_NO_UPDATE_NEEDED = "SETTING_EBIS_OPTION_NO_UPDATE_NEEDED";

export const SETTING_EBIS_ACCOUNT_INFORMATION_INPUT = "SETTING_EBIS_ACCOUNT_INFORMATION_INPUT";
export const SETTING_ADDITIONAL_OPTION_INPUT = "SETTING_ADDITIONAL_OPTION_INPUT";
export const UPDATE_SETTING_ADDITIONAL_OPTION_INPUT = "UPDATE_SETTING_ADDITIONAL_OPTION_INPUT";
export const BACK_TO_UPDATE_SETTING_ADDITIONAL_OPTION_INPUT = "BACK_TO_UPDATE_SETTING_ADDITIONAL_OPTION_INPUT"

/** エビスアカウント情報の送信 **/
export const SETTING_EBIS_ACCOUNT_INFORMATION_CONFIRMED = "SETTING_EBIS_ACCOUNT_INFORMATION_CONFIRMED";
export const SETTING_EBIS_ACCOUNT_INFORMATION_CONFIRMED_SUCCESS = "SETTING_EBIS_ACCOUNT_INFORMATION_CONFIRMED_SUCCESS";
export const SETTING_EBIS_ACCOUNT_INFORMATION_CONFIRMED_FAILURE = "SETTING_EBIS_ACCOUNT_INFORMATION_CONFIRMED_FAILURE";
export const SETTING_EBIS_ACCOUNT_INFORMATION_CONFIRMED_LOADING = "SETTING_EBIS_ACCOUNT_INFORMATION_CONFIRMED_LOADING";

export const SETTING_EBIS_ACCOUNT_BACK_BUTTON = "SETTING_EBIS_ACCOUNT_BACK_BUTTON";
export const SETTING_APPLY_TOGGLE_TERMS = "SETTING_APPLY_TOGGLE_TERMS";
export const SETTING_SCROLLED_TO_TERMS_BOTTOM = "SETTING_SCROLLED_TO_TERMS_BOTTOM"
export const SETTING_APPLY_INFORMATION_CONFIRMED = "SETTING_APPLY_INFORMATION_CONFIRMED";
export const OPEN_TERMS_AND_CONDITIONS_MODAL = "OPEN_TERMS_AND_CONDITIONS_MODAL"
export const CLOSE_TERMS_AND_CONDITIONS_MODAL = "CLOSE_TERMS_AND_CONDITIONS_MODAL";

/** 見積もりPDFダウンロード **/
export const REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF = "REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF";
export const REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF_LOADING = "REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF_LOADING";
export const REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF_SUCCESS = "REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF_SUCCESS";
export const REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF_FAILURE = "REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF_FAILURE";

/** 契約変更承認の送信 **/
export const CONTRACT_CHANGE_QUOTE_APPROVED_REQUEST = "CONTRACT_CHANGE_QUOTE_APPROVED_REQUEST";
export const CONTRACT_CHANGE_QUOTE_APPROVED_REQUEST_LOADING = "CONTRACT_CHANGE_QUOTE_APPROVED_REQUEST_LOADING";
export const CONTRACT_CHANGE_QUOTE_APPROVED_REQUEST_SUCCESS = "CONTRACT_CHANGE_QUOTE_APPROVED_REQUEST_SUCCESS";
export const CONTRACT_CHANGE_QUOTE_APPROVED_REQUEST_FAILURE = "CONTRACT_CHANGE_QUOTE_APPROVED_REQUEST_FAILURE";

export const REQUESTS_DETAIL_CANCEL_REQUEST_TOGGLE = "REQUESTS_DETAIL_CANCEL_REQUEST_TOGGLE";

export const REQUESTS_DETAIL_CANCEL_REQUEST = "REQUESTS_DETAIL_CANCEL_REQUEST"
export const REQUESTS_DETAIL_CANCEL_REQUEST_LOADING = "REQUESTS_DETAIL_CANCEL_REQUEST_LOADING"
export const REQUESTS_DETAIL_CANCEL_REQUEST_SUCCESS = "REQUESTS_DETAIL_CANCEL_REQUEST_SUCCESS"
export const REQUESTS_DETAIL_CANCEL_REQUEST_FAILURE = "REQUESTS_DETAIL_CANCEL_REQUEST_FAILURE"

export const REQUEST_SERVICE_CONTRACT_ID_QUERY = "REQUEST_SERVICE_CONTRACT_ID_QUERY"
export const REQUEST_SERVICE_CONTRACT_ID_QUERY_LOADING = "REQUEST_SERVICE_CONTRACT_ID_QUERY_LOADING"
export const REQUEST_SERVICE_CONTRACT_ID_QUERY_SUCCESS = "REQUEST_SERVICE_CONTRACT_ID_QUERY_SUCCESS"
export const REQUEST_SERVICE_CONTRACT_ID_QUERY_FAILURE = "REQUEST_SERVICE_CONTRACT_ID_QUERY_FAILURE"

export const CONTRACT_NAME_SEND_TO_FORM = "CONTRACT_NAME_SEND_TO_FORM"

const types = {
    REQUESTS_SEARCH,

    REQUESTS_SEARCH_LOADING,
    REQUESTS_SEARCH_SUCCESS,
    REQUESTS_SEARCH_FAILED,

    REQUESTS_LOAD,
    REQUESTS_LOADING,
    REQUESTS_SUCCESS,
    REQUESTS_FAILED,

    EDIT_SEARCH_FORM,

    REQUESTS_DETAIL_LOAD,
    REQUESTS_DETAIL_LOAD_LOADING,
    REQUESTS_DETAIL_LOAD_SUCCESS,
    REQUESTS_DETAIL_LOAD_FAILURE,

    REQUESTS_DETAIL_QUOTE_DETAIL_LOAD,
    REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_LOADING,
    REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_SUCCESS,
    REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_FAILURE,

    RESET_FORM,
    SETTING_EBIS_ACCOUNT_INFORMATION_INPUT,
    SETTING_ADDITIONAL_OPTION_INPUT,
    SETTING_EBIS_OPTION_NO_UPDATE_NEEDED,
    UPDATE_SETTING_ADDITIONAL_OPTION_INPUT,
    BACK_TO_UPDATE_SETTING_ADDITIONAL_OPTION_INPUT,

    SETTING_EBIS_ACCOUNT_INFORMATION_CONFIRMED,
    SETTING_EBIS_ACCOUNT_INFORMATION_CONFIRMED_SUCCESS,
    SETTING_EBIS_ACCOUNT_INFORMATION_CONFIRMED_FAILURE,
    SETTING_EBIS_ACCOUNT_INFORMATION_CONFIRMED_LOADING,

    SETTING_EBIS_ACCOUNT_BACK_BUTTON,
    SETTING_APPLY_TOGGLE_TERMS,
    SETTING_SCROLLED_TO_TERMS_BOTTOM,
    SETTING_APPLY_INFORMATION_CONFIRMED,
    OPEN_TERMS_AND_CONDITIONS_MODAL,
    CLOSE_TERMS_AND_CONDITIONS_MODAL,

    REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF,
    REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF_LOADING,
    REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF_SUCCESS,
    REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF_FAILURE,

    CONTRACT_CHANGE_QUOTE_APPROVED_REQUEST,
    CONTRACT_CHANGE_QUOTE_APPROVED_REQUEST_LOADING,
    CONTRACT_CHANGE_QUOTE_APPROVED_REQUEST_SUCCESS,
    CONTRACT_CHANGE_QUOTE_APPROVED_REQUEST_FAILURE,

    REQUESTS_DETAIL_CANCEL_REQUEST_TOGGLE,

    REQUESTS_DETAIL_CANCEL_REQUEST,
    REQUESTS_DETAIL_CANCEL_REQUEST_LOADING,
    REQUESTS_DETAIL_CANCEL_REQUEST_SUCCESS,
    REQUESTS_DETAIL_CANCEL_REQUEST_FAILURE,

    REQUEST_SERVICE_CONTRACT_ID_QUERY,
    REQUEST_SERVICE_CONTRACT_ID_QUERY_LOADING,
    REQUEST_SERVICE_CONTRACT_ID_QUERY_SUCCESS,
    REQUEST_SERVICE_CONTRACT_ID_QUERY_FAILURE,

    SETTING_EBIS_ACCOUNT_OPTION_FORM_INPUT_SETTING,

    CONTRACT_NAME_SEND_TO_FORM
};

export default types;

import types, {MANAGER_NOTIFICATION_CHOOSE_SEARCH_ACCOUNT} from "./types";
import {empty} from "../../../utils/Common";

/***
 * ////////////////////////////////////////////////
 * ACTIONS (アクション)
 * ------------------
 *
 * コンポーネントやコンテナから要求・呼び出しされるアクション
 * アクションには、REDUCER FILEまたはSAGAミドルウェアにイベントをブロードキャストするためのTYPEキーが必要で、REDUCERまたはSAGAミドルウェアは以下を解析します。
 * ////////////////////////////////////////////////
 */

/**
 * お知らせ一覧取得
 * @param access_token
 * @returns {{payload: {access_token}, type: string}}
 */
export function managerLoadNoticeList(access_token) {
    return {
        type: types.MANAGER_REQUEST_NOTICES,
        payload: {
            access_token: access_token
        }
    }
}

/**
 * お知らせ一覧取得中
 * @returns {{type: string}}
 */
export function managerLoadingNoticeList() {
    return {
        type: types.MANAGER_REQUEST_NOTICES_LOADING
    }
}

/**
 * お知らせ一覧取得済み
 * @param notices
 * @returns {{payload, type: string}}
 */
export function managerLoadedNoticeList(notices) {
    return {
        type: types.MANAGER_REQUEST_NOTICES_SUCCESS,
        payload: {
            data: {
                notices: notices
            }
        }
    }
}

/**
 * お知らせ一覧取得失敗
 * @param error - 例外メッセージ
 */
export function managerFailedNoticeList(error) {
    return {
        type: types.MANAGER_REQUEST_NOTICES_FAILURE,
        payload: {
            error: error
        }
    }
}

/**
 * お知らせ作成モーダル表示・非表示スウィッチ
 */
export function managerNotificationRegisterToggle(notification = null) {
    return {
        type: types.MANAGER_TOGGLE_REGISTER_NOTICE,
        payload: {
            notification: notification
        }
    }
}

/**
 * お知らせ登録エベント
 * @param notice
 * @param access_token
 * @returns {{payload: {access_token, notice}, type: string}}
 */
export function managerRegisterNewNotice(notice, access_token) {
    return {
        type: types.MANAGER_ADD_NOTICE,
        payload: {
            notice: notice,
            access_token: access_token
        }
    }
}

/**
 * お知らせ情報変更エベント
 * @param notice
 * @param new_notice_info
 * @param access_token
 * @returns {{payload: {access_token, new_notice, notice}, type: string}}
 */
export function managerUpdateNotice(notice, new_notice_info, access_token) {
    return {
        type: types.MANAGER_UPDATE_NOTICE,
        payload: {
            notice: notice,
            new_notice: new_notice_info,
            access_token: access_token
        }
    }
}

/**
 * 新規お知らせ登録・変更等のロードエベント
 * @returns {{type: string}}
 */
export function managerLoadingRegisterNotice() {
    return {
        type: types.MANAGER_ADD_UPDATE_NOTICE_LOADING
    }
}

/**
 *お知らせ登録・更新リクエスト（成功）
 *
 * @returns {{type: string}}
 */
export function managerRegisterNewNoticeSuccess() {
    return {
        type: types.MANAGER_ADD_NOTICE_SUCCESS
    }
}

/**
 * お知らせ登録・更新リクエスト（失敗)
 *
 * @param e - エラー内容
 * @returns {{payload: {managerError}, type: string}}
 */
export function managerRegisterNewNoticeFailure(e) {
    return {
        type: types.MANAGER_ADD_NOTICE_FAILURE,
        payload: {managerError: e}
    }
}

//-------------------------------------------------
// お知らせ削除イベント
//-------------------------------------------------

/**
 * お知らせ削除モーダルの表示を無効・有効にするイベント
 *
 * @param accountId - お知らせID
 * @returns {{payload: {accountId}, type: string}}
 */
export function managerViewNoticeDeleteToggle(accountId) {
    return {
        type: types.MANAGER_TOGGLE_DELETE_NOTICE,
        payload: {
            accountId: accountId
        }
    }
}

/**
 *
 * APIにお知らせ削除を送信するイベント
 *
 * @param notice - お知らせID
 * @param access_token - アクセストークン
 * @returns {{payload: {access_token, user}, type: string}}
 */
export function managerDeleteNotice(notice, access_token) {
    return {
        type: types.MANAGER_DELETE_NOTICE_REQUEST,
        payload: {
            notice: notice,
            access_token: access_token
        }
    }
}

/**
 * 削除の実行中
 * @returns {{type: string}}
 */
export function managerDeleteNoticeLoading() {
    return {
        type: types.MANAGER_DELETE_NOTICE_LOADING
    }
}

/**
 * お知らせ削除リクエスト（成功）
 *
 * @returns {{type: string}}
 */
export function managerDeleteNoticeSuccess() {
    return {
        type: types.MANAGER_DELETE_NOTICE_SUCCESS
    }
}

/**
 * お知らせ削除作成リクエスト（失敗）
 *
 * @param e - エラーメッセージ
 * @returns {{payload, type: string}}
 */
export function managerDeleteNoticeFailure(e) {
    return {
        type: types.MANAGER_DELETE_NOTICE_FAILURE,
        payload: {
            error: e
        }
    }
}

//-------------------------------------------------
// アカウント検索
//-------------------------------------------------

/**
 * 検索キーワードでアカウントを検索する
 * @param access_token -アクセストークン
 * @param keyword - 検索キーワード
 * @returns {{payload: {access_token, new_notice, notice}, type: string}}
 */
export function managerNotificationSearchAccount(access_token, keyword) {
    return {
        type: types.MANAGER_SEARCH_ACCOUNT_BY_KEYWORD,
        payload: {
            access_token: access_token,
            params: {
                keyword: keyword
            }
        }
    }
}

/**
 * 検索キーワードでアカウントを検索する「ロード中」
 * @returns {{type: string}}
 */
export function managerNotificationSearchAccountLoading() {
    return {
        type: types.MANAGER_SEARCH_ACCOUNT_BY_KEYWORD_LOADING
    }
}

/**
 *検索キーワードでアカウントを検索する「成功」
 *
 * @returns {{type: string}}
 */
export function managerNotificationSearchAccountSuccess(account_results) {
    console.log(account_results);
    if(empty(account_results)) {
        account_results = []
    }
    return {
        type: types.MANAGER_SEARCH_ACCOUNT_BY_KEYWORD_SUCCESS,
        payload: {
            account_results: account_results
        }
    }
}

/**
 * 検索キーワードでアカウントを検索する「失敗」
 *
 * @param e - エラー内容
 * @returns {{payload: {managerError}, type: string}}
 */
export function managerNotificationSearchAccountFailure(e) {
    return {
        type: types.MANAGER_SEARCH_ACCOUNT_BY_KEYWORD_FAILURE,
        payload: {
            error: e
        }
    }
}

//-------------------------------------------------
// 検索したアカウントを選択するエベント
//-------------------------------------------------
export function managerNotificationChooseAccount(account) {
    return {
        type: types.MANAGER_NOTIFICATION_CHOOSE_SEARCH_ACCOUNT,
        payload: {
            account: account
        }
    }
}


const actions = {
    managerLoadNoticeList,
    managerLoadingNoticeList,
    managerLoadedNoticeList,
    managerFailedNoticeList,

    managerNotificationRegisterToggle,
    managerViewNoticeDeleteToggle,

    managerRegisterNewNotice,
    managerUpdateNotice,
    managerLoadingRegisterNotice,
    managerRegisterNewNoticeSuccess,
    managerRegisterNewNoticeFailure,

    managerDeleteNotice,
    managerDeleteNoticeLoading,
    managerDeleteNoticeSuccess,
    managerDeleteNoticeFailure,

    managerNotificationChooseAccount,

    managerNotificationSearchAccount,
    managerNotificationSearchAccountLoading,
    managerNotificationSearchAccountSuccess,
    managerNotificationSearchAccountFailure,
};
export default actions;

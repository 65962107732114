import {Box, Breadcrumbs, Card, Container, Grid, Typography} from "@material-ui/core";
import React from "react";
import Page from "../../../Page";
import {empty} from "../../../../../../state/utils/Common";
import {Link} from "react-router-dom";
import urlLinks from "../../../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json";

export const CompletedPage = (
    {
        serviceContractContentData,
        serviceContractId,
        t
    }
) => {
    return (
        <Page title={t('service_contracts.contents.modify.title', {contract_name:  !empty(serviceContractContentData?.service_contract?.service_account_name) ? serviceContractContentData?.service_contract?.service_account_name : ""})}
        >
            <Container maxWidth={false}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography style={{marginBottom: 6}}
                                    variant="h2">{t('service_contracts.contents.modify.title', {contract_name:  !empty(serviceContractContentData?.service_contract?.service_account_name) ? serviceContractContentData?.service_contract?.service_account_name : "" })}</Typography>
                    </Grid>
                </Grid>
                <Grid container alignContent={"center"} alignItems={"center"}>
                    <Grid item xs={12} md={12}>
                        <Breadcrumbs separator={">"} aria-label="breadcrumb">
                            <Link to={urlLinks.navigation.top.index.href}>
                                <Typography color="textPrimary">{t('general.top')}</Typography>
                            </Link>
                            <Link to={urlLinks.navigation.service_contracts.index.href}>
                                <Typography color="textPrimary">{t('service_contracts.title')}</Typography>
                            </Link>
                            <Link
                                to={urlLinks.navigation.service_contracts.index.sub_links.contents.href.replace('{SCID}', serviceContractId)}>
                                <Typography
                                    color="textPrimary">{t('service_contracts.contents.breadcrumb')}</Typography>
                            </Link>
                            <Link
                                to={urlLinks.navigation.service_contracts.index.sub_links.contents.sub_links.modify.href.replace('{SCID}', serviceContractId)}>
                                <Typography
                                    color="textPrimary">{t('service_contracts.contents.modify.breadcrumb')}</Typography>
                            </Link></Breadcrumbs>
                    </Grid>
                </Grid>
                <Grid container style={{marginTop: 10}}>
                    <Grid item md={12} style={{paddingRight: 28}}>
                        <Card style={{marginBottom: 15, marginTop: 15}}>
                            <Box p={3}>
                                <Typography variant={"body1"} style={{whiteSpace: "pre-line", fontSize: 13}}>
                                    {t('service_contracts.contents.modify.complete.update_complete_request')}
                                </Typography>
                                <Typography variant={"body1"} style={{whiteSpace: "pre-line", fontWeight: "bold", fontSize: 13}}>
                                    {t('service_contracts.contents.modify.complete.warning_note')}
                                </Typography>
                                <Typography variant={"body1"} style={{whiteSpace: "pre-line", fontSize: 13}}>
                                    {t('service_contracts.contents.modify.complete.reply_message')}
                                </Typography>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )
}

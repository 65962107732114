import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Box, Drawer, Hidden, List, makeStyles} from '@material-ui/core';
import NavItem from './NavItem';
import {withTranslation} from "react-i18next";
import {useAuth0} from "@auth0/auth0-react";
import {empty} from "../../../../state/utils/Common";
import DynamicURLPath from "../../../../state/utils/data/sitemap/user/dynamic_url_path.json";

/**
 * コンポーネントスタイル
 */
const useStyles = makeStyles(() => ({
    mobileDrawer: {
        width: 220
    },
    desktopDrawer: {
        width: 220,
        top: 71,
        overflowX: "hidden",
        height: 'calc(100% - 64px)'
    }
}));

/**
 * ナビゲーションバーコンポーネント
 *
 * @param onMobileClose
 * @param openMobile
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const NavBar = ({onMobileClose, openMobile, t, sidebarLinks}) => {
    const classes = useStyles();
    const location = useLocation();
    // モバイル/デスクトップの状態を確認
    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);
    const {logout} = useAuth0();

    /**
     * 再帰的のナビ・サブナビ項目
     * @param links
     * @param regexMatch
     * @param level
     * @returns {JSX.Element}
     */
    function getElement(links, regexMatch, level = 0) {
        const lLevel = level + 1;
        return <>
            {(empty(regexMatch) || (!empty(regexMatch) && regexMatch.test(location.pathname))) && !empty(links) && Object.keys(links).map((oneKey, i) => {

                const currentLink = location.pathname;
                let link = links[oneKey].href;

                // @TODO: Fix the navigation to include a patch for mismatched url sub link paths i.e (/ and "notifications/all".
                if(links[oneKey]?.translate_to && currentLink.includes('all'))
                    link = links[oneKey]?.translate_to

                Object.keys(DynamicURLPath).forEach((key, index) => {
                    if (link.includes(key) || link.includes(key.replace('_BASE', ''))) {
                        if (!key.includes('_BASE')) {
                            let dynamicPath = new RegExp(DynamicURLPath[key]);
                            if (!empty(currentLink.match(dynamicPath))) {
                                link = link.replace(key, currentLink.match(dynamicPath)[1]);
                            }
                        } else {
                            let dynamicPath = new RegExp(DynamicURLPath[key]);
                            if (!empty(currentLink.match(dynamicPath))) {
                                link = link.replace(key.replace('_BASE', ''), currentLink.match(dynamicPath)[1]);
                            }
                        }
                    }
                })
                const re = new RegExp("^" + link + "/(.*)");
                const result = currentLink.match(re);
                return (
                    <>
                        <NavItem
                            href={link}
                            id={links[oneKey]?.id}
                            key={i}
                            isActive={empty(result)}
                            buttonStyles={{marginRight: 10 * level}}
                            style={{marginTop: 2, marginLeft: 10 * level}}
                            activeOverride={links[oneKey].activeOverride}
                            title={t('navigation.' + links[oneKey].title)}
                            icon={links[oneKey].icon}
                        />
                        {getElement(links[oneKey].sub_links, re, lLevel)}
                    </>
                )
            })}
        </>;
    }

    /**
     * サイドバーナビ作成
     * @param hasLogout
     * @returns {JSX.Element}
     */
    const generateNavList = (hasLogout = false) => {
        return (
            <Box
                style={{backgroundColor: "#F7F5F1"}}
                height="100%"
                display="flex"
                flexDirection="column"
            >
                <Box paddingLeft={3} paddingY={2} paddingRight={2}>
                    <List>
                        {getElement(sidebarLinks, null)}
                        {hasLogout === true &&
                            <NavItem
                                href={null}
                                logoutFunc={() => logout({returnTo: window.location.origin + "/sign_out"})}
                                key={t('actions.logout')}
                                style={{marginTop: 2}}
                                title={t('actions.logout')}
                            />
                        }
                    </List>
                </Box>
                <Box flexGrow={1}/>
            </Box>
        );
    }

    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    classes={{paper: classes.mobileDrawer}}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                >
                    {generateNavList(true)}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer
                    anchor="left"
                    classes={{paper: classes.desktopDrawer}}
                    open
                    variant="persistent"
                >
                    {generateNavList(false)}
                </Drawer>
            </Hidden>
        </>
    );
};

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

NavBar.defaultProps = {
    onMobileClose: () => {
    },
    openMobile: false
};

export default withTranslation('common')(NavBar);

import {takeEvery, takeLeading} from 'redux-saga/effects';
import {
    initSFAccount,
    loginAsFrontUser,
    managerAddAccountList,
    managerDeleteAccount,
    managerEditAction,
    managerGetAccountDetailSaga,
    managerSelectAccountList,
    searchAccountUsers,
    searchSfAccount,
    searchSfUser,
    selectedSfAccountUserSearch
} from './sagas';
import {default as types} from "./types";

/**
 * //////////////////////////////////////////
 * SAGA WATCHERS (サガ・ウォッチャー)
 * --------------------
 * actions.jsからのすべてのアクションは、ここで読み込まれてからreducerに送られます。
 * イベントタイプが一致した場合、下記の第2パラメータの関数が呼び出され、任意のアクションデータを使用することができます。
 * ////////////////////////////////////////////
 */
export function* managerFetchAccounts() {
    yield takeLeading(types.MANAGER_REQUEST_ACCOUNTS, managerSelectAccountList);
    yield takeEvery(types.MANAGER_ADD_ACCOUNT, managerAddAccountList)
    yield takeEvery(types.MANAGER_DELETE_ACCOUNT_REQUEST, managerDeleteAccount)
    yield takeEvery(types.MANAGER_EDIT_ACCOUNT_REQUEST, managerEditAction)
    yield takeLeading(types.MANAGER_SEARCH_SALESFORCE_ACCOUNT_REQUEST, searchSfAccount)
    yield takeLeading(types.MANAGER_SEARCH_SALESFORCE_USER_REQUEST, searchSfUser)
    yield takeEvery(types.MANAGER_SEARCH_SALESFORCE_ACCOUNT_USER_SELECT, selectedSfAccountUserSearch)
    yield takeLeading(types.MANAGER_REQUEST_ACCOUNT_USERS, searchAccountUsers)
    yield takeLeading(types.MANAGER_ACCOUNT_TOGGLE_REGISTER_INIT_SF_ACC_LOAD, initSFAccount)
    yield takeLeading(types.MANAGER_ACCOUNT_LOGIN_AS_USER, loginAsFrontUser)
    yield takeLeading(types.MANAGER_ACCOUNT_GET_ACCOUNT_DETAIL, managerGetAccountDetailSaga)
}

/***
 * ////////////////////////////////////////////////
 * ACTIONS (アクション)
 * ------------------
 *
 * コンポーネントやコンテナから要求・呼び出しされるアクション
 * アクションには、REDUCER FILEまたはSAGAミドルウェアにイベントをブロードキャストするためのTYPEキーが必要で、REDUCERまたはSAGAミドルウェアは以下を解析します。
 * ////////////////////////////////////////////////
 */

import types, {SEND_APPROVE_TRANSFER_REQUEST} from "./types";

//-------------------------------------------------
// 移管情報を取得
//-------------------------------------------------

/**
 *
 * @param {string} account_id
 * @returns {{payload: {access_token, user_id}, type: string}}
 */
export function approveTransferRequest(account_id) {
    return {
        type: types.APPROVE_TRANSFER_REQUEST,
        payload: {
            account_id: account_id
        }
    };
}

/**
 * @returns {{type: string}}
 */
export function approveTransferRequestLoading() {
    return {
        type: types.APPROVE_TRANSFER_REQUEST_LOADING
    }
}

/***
 * @returns {{payload, type: string}}
 */
export function approveTransferRequestSuccess(approveData) {
    return {
        type: types.APPROVE_TRANSFER_REQUEST_SUCCESS,
        payload: {
            approveData: approveData
        }
    }
}

/***
 * @param error
 * @returns {{payload: {error}, type: string}}
 */
export function approveTransferRequestFailure(error) {
    return {
        type: types.APPROVE_TRANSFER_REQUEST_FAILED,
        payload: {
            error: error
        }
    }
}

//-------------------------------------------------
// 移管リクエストを承認する
//-------------------------------------------------

export function approveTransferRequestSend(account_id) {
    console.log(account_id);
    return {
        type: types.SEND_APPROVE_TRANSFER_REQUEST,
        payload: {
            account_id: account_id
        }
    };
}

/**
 * @returns {{type: string}}
 */
export function approveTransferRequestSendLoading() {
    return {
        type: types.SEND_APPROVE_TRANSFER_REQUEST_LOADING
    }
}

/***
 * @returns {{payload, type: string}}
 */
export function approveTransferRequestSendSuccess() {
    return {
        type: types.SEND_APPROVE_TRANSFER_REQUEST_SUCCESS,
    }
}

/***
 * @param error
 * @returns {{payload: {error}, type: string}}
 */
export function approveTransferRequestSendFailure(error) {
    return {
        type: types.SEND_APPROVE_TRANSFER_REQUEST_FAILED,
        payload: {
            error: error
        }
    }
}

const actions = {
    approveTransferRequest,
    approveTransferRequestLoading,
    approveTransferRequestSuccess,
    approveTransferRequestFailure,

    approveTransferRequestSend,
    approveTransferRequestSendLoading,
    approveTransferRequestSendSuccess,
    approveTransferRequestSendFailure,
};
export default actions;

import React from 'react';
import {Grid, TextField} from '@material-ui/core';
import {useAuth0} from "@auth0/auth0-react";

/**
 *
 * @param searchAccount
 * @param t
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
const Toolbar = (
    {
        searchAccount,
        t,
        managerRequestChangeKSearchKeyWord,
        managerAccountVisibleKeyword,
        managerAccountSearchKeyword
    }) => {
    // Auth0アクセストークンの取得
    const {getAccessTokenSilently} = useAuth0();

    return (
        <Grid container alignItems={"flex-end"}>
            <Grid item>
                {/* アカウント検索のオートサジェスト欄 */}
                <TextField id="outlined-basic"
                           onKeyUp={(e) => {
                               if (e.key === 'Enter') {
                                   return searchAccount(managerAccountVisibleKeyword, true);
                               }
                           }}
                           onChange={(e) => {
                               managerRequestChangeKSearchKeyWord(e.target.value)
                           }}
                           placeholder={t('accounts.search')}
                           fullWidth
                           value={managerAccountVisibleKeyword}
                           size={"small"}
                           style={{backgroundColor: "#FFFFFF", color: "#868686!important", width: "400px"}}
                           variant="outlined"
                />
            </Grid>
            <Grid item>
            </Grid>
        </Grid>

    );
}

export default Toolbar;

import types, {SETTING_EBIS_OPTION_NO_UPDATE_NEEDED, UPDATE_SETTING_ADDITIONAL_OPTION_INPUT} from "./types";
import {empty} from "../../../utils/Common";
import PlanOptionInputBlocks from "../../../../state/utils/data/request_application/option_input_blocks.json";

/***
 * ////////////////////////////////////////////////
 * ACTIONS (アクション)
 * ------------------
 *
 * コンポーネントやコンテナから要求・呼び出しされるアクション
 * アクションには、REDUCER FILEまたはSAGAミドルウェアにイベントをブロードキャストするためのTYPEキーが必要で、REDUCERまたはSAGAミドルウェアは以下を解析します。
 * ////////////////////////////////////////////////
 */

/**
 * 検索条件で申込データ取得
 * @param form
 * @param access_token
 * @param account_id
 * @returns {{payload: {access_token, form}, type: string}}
 */
export function loadRequestSearch(form, access_token, account_id) {
    return {
        type: types.REQUESTS_SEARCH,
        payload: {
            form: form,
            access_token: access_token,
            account_id: account_id
        }
    }
}

export function sendContractNameToSearchRequest(account_name) {
    return {
        type: types.CONTRACT_NAME_SEND_TO_FORM,
        payload: {
            account_name: account_name
        }
    }
}

/**
 * 一覧取得のロードエベント
 * @returns {{type: string}}
 */
export function requestSearchLoading() {
    return {
        type: types.REQUESTS_SEARCH_LOADING
    }
}

/**
 * 一覧取得結果エベント
 * @param requests
 * @returns {{payload: {requests}, type: string}}
 */
export function requestSearchLoaded(requests) {
    return {
        type: types.REQUESTS_SEARCH_SUCCESS,
        payload: {
            requests: requests
        }
    }
}

/**
 * 検索条件で取得の失敗
 * @param e
 * @returns {{payload: {error}, type: string}}
 */
export function requestSearchFailed(e) {
    return {
        type: types.REQUESTS_SEARCH_FAILED,
        payload: {
            error: e
        }
    }
}

/**
 * 一覧取得開始エベント
 * @param access_token
 * @param account_id
 * @returns {{payload: {access_token}, type: string}}
 */
export function requestsLoad(access_token, account_id) {
    return {
        type: types.REQUESTS_LOAD,
        payload: {
            access_token: access_token,
            account_id: account_id
        }
    }
}

/**
 * リクエストロード中エベント
 * @returns {{type: string}}
 */
export function requestsLoading() {
    return {
        type: types.REQUESTS_LOADING,
    }
}

/**
 * リクエスト成功エベント
 * @param requests
 * @returns {{payload: {requests}, type: string}}
 */
export function requestsSuccess(requests) {
    return {
        type: types.REQUESTS_SUCCESS,
        payload: {
            requests: requests
        }
    }
}

/**
 * リクエスト失敗
 * @param e
 * @returns {{payload: {error}, type: string}}
 */
export function requestsFailed(e) {
    return {
        type: types.REQUESTS_FAILED,
        payload: {
            error: e
        }
    }
}

/**
 * フォーム項目の変更エベント
 * @param form
 * @returns {{payload: {form}, type: string}}
 */
export function formEditEvent(form) {
    return {
        type: types.EDIT_SEARCH_FORM,
        payload: {
            form: form
        }
    }
}

/*********************************
 * お申込詳細ページ
 ***********************************/

/**
 * 申込詳細の取得エベント
 * @param access_token
 * @param account_id
 * @param request_id
 * @returns {{payload: {access_token}, type: string}}
 */
export function requestsDetailLoad(access_token, account_id, request_id) {
    return {
        type: types.REQUESTS_DETAIL_LOAD,
        payload: {
            access_token: access_token,
            account_id: account_id,
            request_id: request_id
        }
    }
}

/**
 * 申込詳細取得のロード中エベント
 * @returns {{type: string}}
 */
export function requestsDetailLoading() {
    return {
        type: types.REQUESTS_DETAIL_LOAD_LOADING,
    }
}

/**
 * 申込詳細の取得成功エベント
 * @returns {{payload: {requests}, type: string}}
 * @param request_detail
 */
export function requestsDetailSuccess(request_detail) {
    return {
        type: types.REQUESTS_DETAIL_LOAD_SUCCESS,
        payload: {
            request_detail: request_detail
        }
    }
}

/**
 * 申込詳細の取得失敗エベント
 * @param e
 * @returns {{payload: {error}, type: string}}
 */
export function requestsDetailFailed(e) {
    return {
        type: types.REQUESTS_DETAIL_LOAD_FAILURE,
        payload: {
            error: e
        }
    }
}

/*********************************
 * お申込詳細の見積詳細
 ***********************************/

/**
 * 申込見積詳細の取得エベント
 * @param access_token
 * @param account_id
 * @param request_id
 * @param quote_id
 * @returns {{payload: {access_token}, type: string}}
 */
export function requestsDetailQuoteDetailLoad(access_token, account_id, request_id, quote_id) {
    return {
        type: types.REQUESTS_DETAIL_QUOTE_DETAIL_LOAD,
        payload: {
            access_token: access_token,
            account_id: account_id,
            request_id: request_id,
            quote_id: quote_id
        }
    }
}

/**
 * 申込見積詳細取得のロード中エベント
 * @returns {{type: string}}
 */
export function requestsDetailQuoteDetailLoading() {
    return {
        type: types.REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_LOADING,
    }
}

/**
 * 申込見積詳細の取得成功エベント
 * @returns {{payload: {requests}, type: string}}
 * @param quote
 */
export function requestsDetailQuoteDetailSuccess(quote) {
    return {
        type: types.REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_SUCCESS,
        payload: {
            quote: quote
        }
    }
}

/**
 * 申込見積詳細の取得失敗エベント
 * @param e
 * @returns {{payload: {error}, type: string}}
 */
export function requestsDetailQuoteDetailFailed(e) {
    return {
        type: types.REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_FAILURE,
        payload: {
            error: e
        }
    }
}

/****************
 * 申込み初期設定
 ****************/

export function initializeOptionFormInformation(rawInput) {
    console.log(rawInput);
    // オプション入力欄
    let filter = {};
    let validation = {};
    rawInput.forEach((element) => {
        element.fields.forEach((value) => {
            filter = {
                ...filter, ...{
                    [value.name]: ""
                }
            }
        })

        validation = {...validation, ...element.validation};

    })

    console.log(filter);
    console.log(validation)

    return {
        type: types.SETTING_EBIS_ACCOUNT_OPTION_FORM_INPUT_SETTING,
        payload: {
            initialFields: filter,
            validation: validation
        }
    }
}

export function declareNoUpdateOptionNeeded() {
    return {
        type: types.SETTING_EBIS_OPTION_NO_UPDATE_NEEDED,
    }
}


/****************
 * 初期設定画面
 ****************/

export function resetFormInformation() {
    return {
        type: types.RESET_FORM
    }
}

export function settingSubmitEbisFormInformation(formInformation, hasAdditionalFields) {
    console.log(formInformation)
    return {
        type: types.SETTING_EBIS_ACCOUNT_INFORMATION_INPUT,
        payload: {
            requestStartUpInformation: formInformation,
            stepBy: hasAdditionalFields ? 1 : 2
        }
    }
}

export function settingAdditionalSteps(formInformation) {
    let reorderedFormData = {}
    if (!empty(formInformation)) {
        for (let [key, value] of Object.entries(formInformation)) {
            for (let [key2, value2] of Object.entries(PlanOptionInputBlocks)) {
                value2.fields.forEach((valuek) => {
                    if(valuek.name === key) {
                        let understand = {
                            [key2]: {
                                ...reorderedFormData[key2],
                                [key]: value
                            }
                        }
                        reorderedFormData = {...reorderedFormData, ...understand};
                    }
                })
            }
        }
    }
    console.log(reorderedFormData)
    return {
        type: types.SETTING_ADDITIONAL_OPTION_INPUT,
        payload: {
            additionalFormInformation: formInformation,
            formattedAdditionalForm: reorderedFormData
        }
    }
}

export function settingUpdateAdditionalSteps(formInformation) {
    let reorderedFormData = {}
    if (!empty(formInformation)) {
        for (let [key, value] of Object.entries(formInformation)) {
            for (let [key2, value2] of Object.entries(PlanOptionInputBlocks)) {
                value2.fields.forEach((valuek) => {
                    if(valuek.name === key) {
                        let understand = {
                            [key2]: {
                                ...reorderedFormData[key2],
                                [key]: value
                            }
                        }
                        reorderedFormData = {...reorderedFormData, ...understand};
                    }
                })
            }
        }
    }
    console.log(reorderedFormData)
    return {
        type: types.UPDATE_SETTING_ADDITIONAL_OPTION_INPUT,
        payload: {
            additionalFormInformation: formInformation,
            formattedAdditionalForm: reorderedFormData
        }
    }
}

export function backToAdditionalSteps() {
    return {
        type: types.BACK_TO_UPDATE_SETTING_ADDITIONAL_OPTION_INPUT
    }
}

/*********************************
 * 本申フォーム①
 * 初期設定
 ***********************************/

export function settingConfirmEbisFormInformation(accessToken, account_id, request_id, quote_id, ebis_form_data, additional_form_data) {
    if (!empty(ebis_form_data.ebis_host_names)) {
        ebis_form_data.ebis_host_names = ebis_form_data.ebis_host_names.map(value => {
            return value.ebis_host_name
        })
    }

    let reorderedFormData = {}
    if (!empty(additional_form_data)) {
        for (let [key, value] of Object.entries(additional_form_data)) {
            for (let [key2, value2] of Object.entries(PlanOptionInputBlocks)) {
                value2.fields.forEach((valuek) => {
                    if(valuek.name === key) {
                        let understand = {
                            [key2]: {
                                ...reorderedFormData[key2],
                                [key]: value
                            }
                        }
                        reorderedFormData = {...reorderedFormData, ...understand};
                    }
                })
            }
        }
    }

    return {
        type: types.SETTING_EBIS_ACCOUNT_INFORMATION_CONFIRMED,
        payload: {
            accessToken: accessToken,
            account_id: account_id,
            request_id: request_id,
            quote_id: quote_id,
            ebis_form_data: ebis_form_data,
            additional_form_data: reorderedFormData
        }
    }
}

export function loadingConfirmEbisFormInformation() {
    return {
        type: types.SETTING_EBIS_ACCOUNT_INFORMATION_CONFIRMED_LOADING
    }
}

export function successConfirmEbisFormInformation() {
    return {
        type: types.SETTING_EBIS_ACCOUNT_INFORMATION_CONFIRMED_SUCCESS
    }
}

export function failureConfirmEbisFormInformation(error) {
    return {
        type: types.SETTING_EBIS_ACCOUNT_INFORMATION_CONFIRMED_FAILURE,
        payload: {
            error: error
        }
    }
}

/*********************************
 * 本申フォーム② - エビスアカウント情報は不要
 ***********************************/

export function contractUpdateRequest(accessToken, account_id, request_id, quote_id, additionalOptionInformation) {
    let reorderedFormData = {}
    if (!empty(additionalOptionInformation)) {
        for (let [key, value] of Object.entries(additionalOptionInformation)) {
            for (let [key2, value2] of Object.entries(PlanOptionInputBlocks)) {
                value2.fields.forEach((valuek) => {
                    if(valuek.name === key) {
                        let understand = {
                            [key2]: {
                                ...reorderedFormData[key2],
                                [key]: value
                            }
                        }
                        reorderedFormData = {...reorderedFormData, ...understand};
                    }
                })
            }
        }
    }
    console.log(reorderedFormData)

    return {
        type: types.CONTRACT_CHANGE_QUOTE_APPROVED_REQUEST,
        payload: {
            accessToken: accessToken,
            account_id: account_id,
            request_id: request_id,
            quote_id: quote_id,
            reorderedFormData:reorderedFormData
        }
    }
}

export function contractUpdateRequestLoading() {
    return {
        type: types.CONTRACT_CHANGE_QUOTE_APPROVED_REQUEST_LOADING,
    }
}

export function contractUpdateRequestSuccess() {
    return {
        type: types.CONTRACT_CHANGE_QUOTE_APPROVED_REQUEST_SUCCESS,
    }
}

export function contractUpdateRequestFailure(error) {
    return {
        type: types.CONTRACT_CHANGE_QUOTE_APPROVED_REQUEST_FAILURE,
        payload: {
            error: error
        }
    }
}

/*********************************
 * フォームヘルパー
 ***********************************/

export function settingEbisBackButton(steps = 1) {
    return {
        type: types.SETTING_EBIS_ACCOUNT_BACK_BUTTON,
        payload: {
            stepsBackBy: steps
        }
    }
}

export function scrolledToTermsBottom() {
    return {
        type: types.SETTING_SCROLLED_TO_TERMS_BOTTOM
    }
}

export function applyToggleTerms() {
    return {
        type: types.SETTING_APPLY_TOGGLE_TERMS
    }
}

export function settingApplyConfirm() {
    return {
        type: types.SETTING_APPLY_INFORMATION_CONFIRMED
    }
}

export function closeTermsAndConditionsModal() {
    return {
        type: types.CLOSE_TERMS_AND_CONDITIONS_MODAL
    }
}

export function openTermsAndConditionsModal() {
    return {
        type: types.OPEN_TERMS_AND_CONDITIONS_MODAL
    }
}

/*************
 * 見積PDFダウンロード
 ****+********/
export function downloadQuotePDF(access_token, account_id, request_id, quote_id, quote_no) {
    return {
        type: types.REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF,
        payload: {
            access_token: access_token,
            account_id: account_id,
            request_id: request_id,
            quote_id: quote_id,
            quote_no: quote_no,
        }
    }
}


export function downloadQuoteLoading() {
    return {
        type: types.REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF_LOADING
    }
}

export function downloadQuoteSuccess(binaryFileData) {
    return {
        type: types.REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF_SUCCESS,
        payload: {
            binaryFileData: binaryFileData
        }
    }
}

export function downloadQuoteFailure(e) {
    return {
        type: types.REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF_FAILURE,
        payload: {
            error: e
        }
    }
}

/*********************************
 * 申込のキャンセル処理
 ***********************************/
export function toggleCancelConfirmation(willOpen = true) {
    return {
        type: types.REQUESTS_DETAIL_CANCEL_REQUEST_TOGGLE,
        payload: {
            willOpen: willOpen
        }
    }
}

export function cancelRequestSend(access_token, account_id, request_id) {
    return {
        type: types.REQUESTS_DETAIL_CANCEL_REQUEST,
        payload: {
            access_token: access_token,
            account_id: account_id,
            request_id: request_id,
        }
    }
}

export function cancelRequestSendLoading() {
    return {
        type: types.REQUESTS_DETAIL_CANCEL_REQUEST_LOADING,
    }
}

export function cancelRequestSendSuccess() {
    return {
        type: types.REQUESTS_DETAIL_CANCEL_REQUEST_SUCCESS,
    }
}

export function cancelRequestSendFailure(e) {
    return {
        type: types.REQUESTS_DETAIL_CANCEL_REQUEST_FAILURE,
        payload: {
            error: e
        }
    }
}

/*********************************
 * アカウントIDからアカウント名を取得するエベント
 ***********************************/
export function parseServiceContractId(access_token, account_id, service_contract_id) {
    return {
        type: types.REQUEST_SERVICE_CONTRACT_ID_QUERY,
        payload: {
            access_token: access_token,
            account_id: account_id,
            service_contract_id: service_contract_id
        }
    }
}

export function parseServiceContractIdLoading() {
    return {
        type: types.REQUEST_SERVICE_CONTRACT_ID_QUERY_LOADING
    }
}

export function parseServiceContractIdSuccess(service_contract_data) {
    console.log(service_contract_data)
    return {
        type: types.REQUEST_SERVICE_CONTRACT_ID_QUERY_SUCCESS,
        payload: {
            account_name: service_contract_data.service_contract.service_account_name
        }
    }
}

export function parseServiceContractIdFailure(e) {
    return {
        type: types.REQUEST_SERVICE_CONTRACT_ID_QUERY_FAILURE,
        payload: {
            error: e
        }
    }
}


const actions = {
    loadRequestSearch,
    formEditEvent,

    requestsLoad,
    requestsLoading,
    requestsSuccess,
    requestsFailed,

    requestsDetailLoad,
    requestsDetailLoading,
    requestsDetailSuccess,
    requestsDetailFailed,

    requestsDetailQuoteDetailLoad,
    requestsDetailQuoteDetailLoading,
    requestsDetailQuoteDetailSuccess,
    requestsDetailQuoteDetailFailed,

    resetFormInformation,
    settingSubmitEbisFormInformation,
    settingAdditionalSteps,

    settingConfirmEbisFormInformation,
    loadingConfirmEbisFormInformation,
    successConfirmEbisFormInformation,
    failureConfirmEbisFormInformation,

    contractUpdateRequest,
    contractUpdateRequestLoading,
    contractUpdateRequestSuccess,
    contractUpdateRequestFailure,

    settingEbisBackButton,
    scrolledToTermsBottom,
    applyToggleTerms,
    settingApplyConfirm,

    openTermsAndConditionsModal,
    closeTermsAndConditionsModal,

    downloadQuotePDF,
    downloadQuoteLoading,
    downloadQuoteSuccess,
    downloadQuoteFailure,

    toggleCancelConfirmation,

    cancelRequestSend,
    cancelRequestSendLoading,
    cancelRequestSendSuccess,
    cancelRequestSendFailure,

    parseServiceContractId,
    parseServiceContractIdLoading,
    parseServiceContractIdSuccess,
    parseServiceContractIdFailure,

    initializeOptionFormInformation,
    declareNoUpdateOptionNeeded,
    settingUpdateAdditionalSteps,
    backToAdditionalSteps,

    sendContractNameToSearchRequest
};

export default actions;

import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {useAuth0} from "@auth0/auth0-react";
import React, {useEffect} from "react";
import {serviceContractContentOperations} from "../../../../../../state/ducks/user/service_contracts/contents";
import {empty} from "../../../../../../state/utils/Common";
import Auth0Utils from "../../../../../../state/utils/Auth0Utils";
import {useParams} from "react-router";
import AllPlanContentsView from "../../../../../components/user/serviceContracts/contents/modify/all/Page";

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,

        serviceContractContentAllPlanData: state.serviceContractContent.serviceContractContentAllPlanData,
        serviceContractContentAllPlanLoading: state.serviceContractContent.serviceContractContentAllPlanLoading,

        serviceContractContentAlterPlan: state.serviceContractContent.serviceContractContentAlterPlan,
        savedTierItem: state.serviceContractContent.savedTierItem,

        serviceContractContentData: state.serviceContractContent.serviceContractContentData,
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    serviceContractContentAllPlansLoad: serviceContractContentOperations.serviceContractContentAllPlansLoad,
    addPlan: serviceContractContentOperations.addPlan,
}

/***
 * ///////////////////////////////////////////////////////////
 * CONTAINER VS COMPONENT (コンテイナ対コンポーネント)
 * --------------------------------
 * コンテナは、任意のアクションを *実行* し、任意の redux ストアやオペレーションをロードして、コンポーネントにプッシュします。
 * コンポーネントは、可能な限りダムでなければなりません。つまり、目に見えるデータのみを表示する必要があります。
 * ///////////////////////////////////////////////////////////
 */

/*
通常ユーザー側の申込コンテイナー
*/
const _currentPlanContentsContainer = (
    {
        managerProfile,
        serviceContractContentAllPlanData,
        serviceContractContentAllPlanLoading,

        serviceContractContentAllPlansLoad,

        serviceContractContentAlterPlan,
        addPlan,
        savedTierItem,
        t,

        serviceContractContentData,
    }) => {
    // auth0 アクセストークン取得
    const {getAccessTokenSilently} = useAuth0();
    let {scid} = useParams();
    // 利用契約一覧をAPIから取得する。
    useEffect(() => {
        // ログインしたユーザープロフィール取得したかどうかのチェック
        if (!empty(managerProfile)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    },
                );
                serviceContractContentAllPlansLoad(access_token, managerProfile?.account?.uuid, scid)
            })()
        }
    }, [getAccessTokenSilently, serviceContractContentAllPlansLoad, managerProfile, scid])

    return (
        <AllPlanContentsView
            t={t}
            serviceContractContentAllPlanData={serviceContractContentAllPlanData}
            serviceContractContentAllPlanLoading={serviceContractContentAllPlanLoading}
            serviceContractContentAlterPlan={serviceContractContentAlterPlan}
            addPlan={addPlan}
            savedTierItem={savedTierItem}
            activeServiceContract={serviceContractContentData}
        />
    )
}

/**
 *
 * * Redux
 * * i18next Translations
 * を
 * UserView コンポーネントに追加する。
 */
const CurrentPlanContentsContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_currentPlanContentsContainer)

export default CurrentPlanContentsContainer;

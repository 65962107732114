import ApiUtils from "../../../utils/ApiUtils";
import {
    MANAGER_ADD_NOTICES_API_PATH,
    MANAGER_DELETE_NOTICE_API_PATH,
    MANAGER_GET_NOTICES_API_PATH,
    MANAGER_UPDATE_NOTICE_API_PATH
} from "./constants";
import noticeSchema from "./schema/notices.json";

/***
 * ///////////////////////////////////////////////
 * API
 * -----------------
 *
 * /noticesのすべてのAPIイベントは、ここで呼び出されます。これらのメソッドは、
 * 主にSAGASから呼び出され、APIイベントは、エラーまたはデータオブジェクトを戻します。
 * ///////////////////////////////////////////////
 */

/**
 * お知らせ一覧取得リクエスト
 *
 * @param {string} accessToken - auth0 アクセストークン
 * @param {Object} params - GETクエリー
 * @returns {AxiosPromise} - axios 結果
 */
export function managerCallNotices(accessToken, params) {
    return new ApiUtils(accessToken).get(
        MANAGER_GET_NOTICES_API_PATH,
        params,
        noticeSchema
    )
}

export function managerAddNotice(accessToken, notice) {
    return new ApiUtils(accessToken).post(
        MANAGER_ADD_NOTICES_API_PATH,
        notice,
        null
    )
}

export function managerUpdateNotice(activeNotice, updateNoticeDetails, accessToken) {
    console.log(activeNotice);
    return new ApiUtils(accessToken).put(
        MANAGER_UPDATE_NOTICE_API_PATH,
        activeNotice.uuid,
        updateNoticeDetails,
        null
    )
}

/**
 * お知らせ削除APIリクエスト
 *
 * @param id - お知らせID
 * @param accessToken - auth0アクセストークン
 * @returns {AxiosPromise}
 */
export function managerDeleteNotice(id, accessToken) {
    return new ApiUtils(accessToken).delete(
        MANAGER_DELETE_NOTICE_API_PATH,
        id
    )
}

import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {useAuth0} from "@auth0/auth0-react";
import React, {useEffect} from "react";
import {rootOperations} from "../../../../../../../state/ducks/root";
import userSideBarLinks from "../../../../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json"
import {empty} from "../../../../../../../state/utils/Common";
import Auth0Utils from "../../../../../../../state/utils/Auth0Utils";
import {requestOperations} from "../../../../../../../state/ducks/user/requests";
import {useParams} from "react-router";
import RequestApplyNewPage from "../../../../../../components/user/requests/detail/quote/apply/apply_new/Page";
import PlanOptionDetails
    from "../../../../../../../state/utils/data/request_application/plan_option_to_input_block.json";
import PlanOptionInputBlocks from "../../../../../../../state/utils/data/request_application/option_input_blocks.json";


/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,

        requestQuote: state.request.requestQuote,
        requestDetail: state.request.requestDetail,
        requestDetailLoading: state.request.requestDetailLoading,
        requestStartUpInformationStep: state.request.requestStartUpInformationStep,
        requestStartUpInformation: state.request.requestStartUpInformation,
        requestApplyAcceptTerms: state.request.requestApplyAcceptTerms,
        termsAndConditionsIsOpen: state.request.termsAndConditionsIsOpen,
        ebisAccountConfirmedLoading: state.request.ebisAccountConfirmedLoading,
        ebisAccountConfirmedError: state.request.ebisAccountConfirmedError,
        additionalOptionValidation: state.request.additionalOptionValidation,

        additionalOptionInformation: state.request.additionalOptionInformation,
        formattedAdditionalOptionInformation: state.request.formattedAdditionalOptionInformation,
        hasScrolledTermsToBottom: state.request.hasScrolledTermsToBottom
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    loadSidebarItems: rootOperations.loadSidebarItems,
    requestsDetailQuoteDetailLoad: requestOperations.requestsDetailQuoteDetailLoad,
    resetFormInformation: requestOperations.resetFormInformation,
    settingSubmitEbisFormInformation: requestOperations.settingSubmitEbisFormInformation,
    settingConfirmEbisFormInformation: requestOperations.settingConfirmEbisFormInformation,
    settingEbisBackButton: requestOperations.settingEbisBackButton,
    applyToggleTerms: requestOperations.applyToggleTerms,
    closeTermsAndConditionsModal: requestOperations.closeTermsAndConditionsModal,
    openTermsAndConditionsModal: requestOperations.openTermsAndConditionsModal,
    initializeOptionFormInformation: requestOperations.initializeOptionFormInformation,
    settingAdditionalSteps: requestOperations.settingAdditionalSteps,
    scrolledToTermsBottom: requestOperations.scrolledToTermsBottom
}

/***
 * ///////////////////////////////////////////////////////////
 * CONTAINER VS COMPONENT (コンテイナ対コンポーネント)
 * --------------------------------
 * コンテナは、任意のアクションを *実行* し、任意の redux ストアやオペレーションをロードして、コンポーネントにプッシュします。
 * コンポーネントは、可能な限りダムでなければなりません。つまり、目に見えるデータのみを表示する必要があります。
 * ///////////////////////////////////////////////////////////
 */

/*
通常ユーザー側の申込詳細コンテイナー
*/
const _applyNewContainer = (
    {
        managerProfile,
        loadSidebarItems,
        requestQuote,
        requestDetailLoading,
        requestsDetailQuoteDetailLoad,
        resetFormInformation,
        requestStartUpInformationStep,
        requestStartUpInformation,
        settingSubmitEbisFormInformation,
        settingConfirmEbisFormInformation,
        settingEbisBackButton,
        requestApplyAcceptTerms,
        applyToggleTerms,

        scrolledToTermsBottom,
        hasScrolledTermsToBottom,

        termsAndConditionsIsOpen,
        closeTermsAndConditionsModal,
        openTermsAndConditionsModal,
        ebisAccountConfirmedLoading,
        ebisAccountConfirmedError,
        requestDetail,
        additionalOptionInformation,
        initializeOptionFormInformation,
        formattedAdditionalOptionInformation,
        additionalOptionValidation,
        settingAdditionalSteps,
        t
    }) => {
    // auth0 アクセストークン取得
    const {getAccessTokenSilently} = useAuth0();
    let {rid, qid} = useParams();
    // 利用契約一覧をAPIから取得する。
    useEffect(() => {
        // ログインしたユーザープロフィール取得したかどうかのチェック
        if (!empty(managerProfile)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    },
                );
                requestsDetailQuoteDetailLoad(access_token, managerProfile?.account?.uuid, rid, qid)
            })()
        }
    }, [rid, qid, getAccessTokenSilently, requestsDetailQuoteDetailLoad, managerProfile])
    // 利用契約一覧をAPIから取得する。

    useEffect(() => {
        loadSidebarItems(userSideBarLinks.navigation.requests)
    }, [loadSidebarItems])

    // 申請情報のリセット
    useEffect(() => {
        resetFormInformation()
    }, [resetFormInformation])

    let quoteDetailsArray = [];
    let additionalFieldsArray = [];

    if (!empty(requestQuote)) {
        quoteDetailsArray = requestQuote?.display_details.map((value) => {
            return value.rate_plan_code;
        });
        Object.entries(PlanOptionDetails.ASSOCIATE_PLAN_TO_FORM).forEach(([key, value]) => {
            value.forEach((value) => {
                if (quoteDetailsArray.includes(value)) {
                    additionalFieldsArray.push(PlanOptionInputBlocks[key]);
                }
            })
        });
    }

    console.log(quoteDetailsArray)
    console.log(additionalFieldsArray)

    useEffect(() => {
        if (empty(additionalOptionInformation) && !empty(additionalFieldsArray)) {
            initializeOptionFormInformation(additionalFieldsArray)
        }
    }, [additionalOptionInformation, additionalFieldsArray, initializeOptionFormInformation])

    return (
        <RequestApplyNewPage
            requestDetailLoading={requestDetailLoading}
            requestQuote={requestQuote}
            additionalFieldsArray={additionalFieldsArray}
            settingAdditionalSteps={settingAdditionalSteps}
            additionalOptionInformation={additionalOptionInformation}
            additionalOptionValidation={additionalOptionValidation}
            formattedAdditionalOptionInformation={formattedAdditionalOptionInformation}
            requestDetail={requestDetail}
            requestStartUpInformationStep={requestStartUpInformationStep}
            requestStartUpInformation={requestStartUpInformation}
            settingSubmitEbisFormInformation={settingSubmitEbisFormInformation}
            settingConfirmEbisFormInformation={settingConfirmEbisFormInformation}
            managerProfile={managerProfile}
            quoteId={qid}
            requestId={rid}
            requestApplyAcceptTerms={requestApplyAcceptTerms}
            settingEbisBackButton={settingEbisBackButton}
            applyToggleTerms={applyToggleTerms}
            scrolledToTermsBottom={scrolledToTermsBottom}
            hasScrolledToTermsBottom={hasScrolledTermsToBottom}
            termsAndConditionsIsOpen={termsAndConditionsIsOpen}
            closeTermsAndConditionsModal={closeTermsAndConditionsModal}
            openTermsAndConditionsModal={openTermsAndConditionsModal}
            ebisAccountConfirmedLoading={ebisAccountConfirmedLoading}
            ebisAccountConfirmedError={ebisAccountConfirmedError}
            t={t}
        />
    )
}

/**
 *
 * * Redux
 * * i18next Translations
 * を
 * UserView コンポーネントに追加する。
 */
const ApplyNewContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_applyNewContainer)

export default ApplyNewContainer;

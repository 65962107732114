import {Box, Grid, makeStyles, Typography,} from "@material-ui/core";
import React from "react";
import {commonStyles} from "../../../../../../theme/user/styles/common";
import globalTheme from "../../../../../../theme/globalTheme";
import {currencyFormatter, empty} from "../../../../../../../state/utils/Common";
import {ServiceChargeRow} from "./ServiceChargeRow";
import requestType from "../../../../../../../state/utils/data/application_request_type_flat.json"

/**
 * コンポーネントスタイル
 *
 * @type {(props?: any) => ClassNameMap<"paper"|"root">}
 */
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        border: "1px #CCCCCC solid",
        boxShadow: "none"
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));
/**
 * 通常ユーザー側の申込詳細画面の見積り一覧
 *
 * @param {makeStyles} className - コンポーネントのスタイル
 * @param requests
 * @param requestListLoading
 * @param {*} t - 翻訳オブジェクト
 * @returns {JSX.Element}
 * @constructor
 */
const ServiceChargeList =
    ({
         className,
         requestQuote,
         requestDetail,
         t
     }) => {
        const classes = useStyles();
        const common = commonStyles();
        const _globalTheme = globalTheme._default();
        return (
            <>
                <Grid container className={common.tableHeader} style={{paddingLeft: 29, paddingRight: 98}}>
                    <Grid item xs={4} style={{textAlign: "left"}}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('requests.detail.quotes.service_charge_table.service_name')}
                        </Typography>
                    </Grid>
                    <Grid item xs={3} style={{textAlign: "center"}}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('requests.detail.quotes.service_charge_table.monthly_service_charge')}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} style={{textAlign: "center"}}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('requests.detail.quotes.service_charge_table.duration')}
                        </Typography>
                    </Grid>
                    <Grid item xs={3} style={{textAlign: "right"}}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('requests.detail.quotes.service_charge_table.duration_total')}
                        </Typography>
                    </Grid>
                </Grid>
                {!empty(requestQuote) &&
                !empty(requestQuote.display_details) &&
                requestQuote.display_details.map((service_charge) => (
                    <ServiceChargeRow service_charge={service_charge} requestQuote={requestQuote}/>
                ))
                }
                {!empty(requestQuote) &&
                !empty(requestQuote.change_after_details) &&
                requestQuote.change_after_details.map((service_charge) => (
                    <ServiceChargeRow service_charge={service_charge} requestQuote={requestQuote}/>
                ))
                }

                {requestDetail.type === requestType.NEW &&
                <Box padding={2} style={{paddingRight: 98}}>
                    <Grid container justify={"flex-end"} style={{marginBottom: 15}}>
                        <Grid item md={9} style={{textAlign: "right"}}>
                            <Typography
                                variant={"body1"}><strong>{t('requests.detail.quotes.service_charge_table.new_contract_without_tax')}</strong></Typography>
                        </Grid>
                        <Grid item md={3} style={{textAlign: "right"}}>
                            <Typography
                                variant={"body1"}>{currencyFormatter(requestQuote.previewed_sub_total_per_month)}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify={"flex-end"} style={{marginBottom: 15}}>
                        <Grid item md={9} style={{textAlign: "right"}}>
                            <Typography
                                variant={"body1"}><strong>{t('requests.detail.quotes.service_charge_table.new_contract_total_without_tax')}</strong></Typography>
                        </Grid>
                        <Grid item md={3} style={{textAlign: "right"}}>
                            <Typography
                                variant={"body1"}>{currencyFormatter(requestQuote.previewed_sub_total)}</Typography>
                        </Grid>
                    </Grid>
                    {requestQuote.is_margin_exist &&
                    <Grid container justify={"flex-end"} style={{marginBottom: 15}}>
                        <Grid item md={9} style={{textAlign: "right"}}>
                            <Typography
                                variant={"body1"}>{t('requests.detail.quotes.service_charge_table.margin')}</Typography>
                        </Grid>
                        <Grid item md={3} style={{textAlign: "right"}}>
                            <Typography
                                variant={"body1"}>{currencyFormatter(requestQuote.previewed_discount)}</Typography>
                        </Grid>
                    </Grid>
                    }
                </Box>
                }

                {(requestDetail.type === requestType.CHANGE || requestDetail.type === requestType.RENEW) &&
                <Box padding={2} style={{paddingRight: 98}}>
                    <Grid container justify={"flex-end"} alignItems={"flex-end"} alignContent={"flex-end"}
                          style={{marginBottom: 15}}>
                        <Grid item md={9} style={{textAlign: "right"}}>
                            <Typography
                                variant={"body1"}><strong>{t('requests.detail.quotes.service_charge_table.contract_change_without_tax')}</strong></Typography>
                        </Grid>
                        <Grid item md={3} style={{textAlign: "right"}}>
                            <Typography
                                variant={"body1"}>{currencyFormatter(requestQuote.previewed_sub_total_per_month)}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify={"flex-end"} style={{marginBottom: 15}}>
                        <Grid item md={9} style={{textAlign: "right"}}>
                            <Typography
                                variant={"body1"}><strong>{t('requests.detail.quotes.service_charge_table.contract_change_total_amount_without_tax')}</strong></Typography>
                        </Grid>
                        <Grid item md={3} style={{textAlign: "right"}}>
                            <Typography
                                variant={"body1"}>{currencyFormatter(requestQuote.previewed_sub_total)}</Typography>
                        </Grid>
                    </Grid>
                    {requestQuote.is_margin_exist &&
                    <Grid container justify={"flex-end"} style={{marginBottom: 15}}>
                        <Grid item md={9} style={{textAlign: "right"}}>
                            <Typography
                                variant={"body1"}>{t('requests.detail.quotes.service_charge_table.margin')}</Typography>
                        </Grid>
                        <Grid item md={3} style={{textAlign: "right"}}>
                            <Typography
                                variant={"body1"}>{currencyFormatter(requestQuote.previewed_discount)}</Typography>
                        </Grid>
                    </Grid>
                    }
                </Box>
                }
            </>
        );
    };

export default ServiceChargeList;

import React, {useEffect} from "react";
import {managerAccountListOperations} from "../../../../state/ducks/manager/managerAccounts";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {useAuth0} from "@auth0/auth0-react";
import {useHistory, useParams} from "react-router";
import {empty} from "../../../../state/utils/Common";
import Auth0Utils from "../../../../state/utils/Auth0Utils";
import {makeStyles} from "@material-ui/core";
import ManagerAccountUserView from "../../../components/manager/accounts/users/Users";
import {rootOperations} from "../../../../state/ducks/root";
import adminSideBarLinks from "../../../../state/utils/data/sitemap/admin/sidebar_navigation_urls.json";

/**
 * コンテイナのスタイルクラス
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(4)
    },
    button: {
        fontWeight: 900,
    }
}));

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,

        managerAccountUsersLoading: state.managerAccount.managerAccountUsersLoading,
        managerAccountActiveUsers: state.managerAccount.managerAccountActiveUsers,
        managerAccountUsersError: state.managerAccount.managerAccountUsersError,
        managerDetailAccountData: state.managerAccount.managerDetailAccountData
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    loadSidebarItems: rootOperations.loadSidebarItems,
    saveContractNameToSidebarLink: managerAccountListOperations.saveContractNameToSidebarLink,

    managerSelectAccountUsers: managerAccountListOperations.managerSelectAccountUsers
}

/**
 * 管理者側のユーザー一覧コンテイナ
 *
 * @param {Object} managerProfile
 * @param managerAccountUsersLoading
 * @param managerAccountActiveUsers
 * @param managerAccountUsersError
 * @param saveContractNameToSidebarLink
 * @param managerSelectAccountUsers
 * @param managerDetailAccountData
 * @param loadSidebarItems
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const ManagerAccountUserContainer = (
    {
        managerProfile,
        managerAccountUsersLoading,
        managerAccountActiveUsers,
        managerAccountUsersError,
        saveContractNameToSidebarLink,
        managerSelectAccountUsers,
        managerDetailAccountData,
        loadSidebarItems,
        t
    }) => {
    // auth0 アクセストークン
    const {getAccessTokenSilently} = useAuth0();
    // URL履歴
    let {id} = useParams();
    const classes = useStyles();

    /**
     * ナビ項目のロードエベント
     */
    useEffect(() => {
        loadSidebarItems(adminSideBarLinks.navigation.accounts)
    }, [loadSidebarItems])

    useEffect(() => {
        if (!empty(managerProfile)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    }
                );
                managerSelectAccountUsers(access_token, id);
            })()
        }
    }, [getAccessTokenSilently, id, managerProfile, managerSelectAccountUsers])

    useEffect(() => {
        if(!empty(managerProfile) && !empty(managerAccountActiveUsers)) {
            saveContractNameToSidebarLink(managerDetailAccountData?.name)
        }
    }, [managerDetailAccountData, saveContractNameToSidebarLink, managerProfile, managerAccountActiveUsers])

    const history = useHistory();
    // 連絡先情報があるかどうかのチェック、
    // 連絡先情報が無ければ、/service_redirectionにリダイレクトする。
    if (
        managerAccountUsersLoading === false
        && !empty(managerAccountUsersError)
    ) {
        history.push('/manager/accounts')
        return (<div> リダイレクト中</div>)
    }



    return (
        <ManagerAccountUserView
            classes={classes}
            managerAccountUsersLoading={managerAccountUsersLoading}
            managerAccountActiveUsers={managerAccountActiveUsers}
            managerAccountUsersError={managerAccountUsersError}
            managerDetailAccountData={managerDetailAccountData}
            accountId={id}
            t={t}
        />
    )
};


/**
 * Redux
 * i18next Translations
 * を
 * ManagerAccountView コンポーネントにマージする。
 */
const managerAccountUserContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(ManagerAccountUserContainer)

export default managerAccountUserContainer;

import types, {RESET_CANCELLATION_FORM} from "./types";

/***
 * /////////////////////////////////////////
 * REDUCERS (リデューサー)
 * -------------------
 *
 * すべてのリアクトのマネージャーアカウントの一時的なデータが保存される領域。
 * アプリケーションのどこでもデータを使用することができます。
 *
 * Reducerイベントは、ステートへのセッター命令のみを **実行すべき** です。
 *
 * このReducerのステートは、/src/state/ducks/index.jsに設定されているservice_contractsグループに束縛されています。
 * //////////////////////////////////////////
 */
export const serviceContractTerminateState = {
    formData: {},
    accepted_terms: false,

    cancelRequestLoading: false,
    cancelRequestError: {},

    cancelReasonsLoading: true,
    cancelReasonsData: {},
    cancelReasonsError: {}
}

export default function serviceContractTerminateReducer(state = serviceContractTerminateState, action) {
    switch (action.type) {
        case (types.SAVE_TERMINATE_FORM_DATA):
            return {
                ...state,
                formData: state.formData = action.payload.form
            }
        case(types.TOGGLE_TERMS_AND_CONDITIONS):
            return {
                ...state,
                accepted_terms: state.accepted_terms = !state.accepted_terms
            }
        case(types.RESET_TERMS_AND_CONDITIONS):
            return {
                ...state,
                accepted_terms: state.accepted_terms = false
            }
        case(types.RESET_CANCELLATION_FORM):
            return {
                ...state,
                formData: state.formData = {},
                cancelReasonsData: state.cancelReasonsData = {}
            }
        /*************
         * 契約解約理由の取得リクエスト
         *************/
        case(types.GET_SERVICE_CONTRACT_CANCEL_REASONS_LOADING):
            return {
                ...state,
                cancelReasonsLoading: state.cancelReasonsLoading = true,
                cancelReasonsError: state.cancelReasonsError = {},
                cancelReasonsData: state.cancelReasonsData = {},
            }
        case(types.GET_SERVICE_CONTRACT_CANCEL_REASONS_SUCCESS):
            return {
                ...state,
                cancelReasonsLoading: state.cancelReasonsLoading = false,
                cancelReasonsData: state.cancelReasonsData = action.payload.reasons
            }
        case(types.GET_SERVICE_CONTRACT_CANCEL_REASONS_FAILED):
            return {
                ...state,
                cancelReasonsLoading: state.cancelReasonsLoading = false,
                cancelReasonsError: state.cancelReasonsError = action.payload.error
            }
        /*************
         * 契約解約リクエストの送信
         *************/
        case(types.SEND_SERVICE_CONTRACT_CANCEL_REQUEST_LOADING):
            return {
                ...state,
                cancelRequestLoading: state.cancelRequestLoading = true,
                cancelRequestError: state.cancelRequestError = {}
            }
        case(types.SEND_SERVICE_CONTRACT_CANCEL_REQUEST_SUCCESS):
            return {
                ...state,
                cancelRequestLoading: state.cancelRequestLoading = false,
                formData: state.formData = {}
            }
        case(types.SEND_SERVICE_CONTRACT_CANCEL_REQUEST_FAILED):
            return {
                ...state,
                cancelRequestLoading: state.cancelRequestLoading = false,
                cancelRequestError: state.cancelRequestError = action.payload.error
            }
        default:
            return state
    }
}

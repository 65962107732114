import {Box, Card, Chip, Grid, Typography} from "@material-ui/core";
import React from "react";
import globalTheme from "../../../theme/globalTheme";
import {Close} from "@material-ui/icons";
import {empty} from "../../../../state/utils/Common";

export const UpdatedRow = (
    {
        type,
        product_id,
        undoPlanUpdate,
        tiers,
        oldTier,
        label,
        t,
        allowModify
    }
) => {
    const classes = globalTheme.defaultFormStyle();


    let actionState = "";
    let styleOverride = {};
    if (type === 'RemoveProduct') {
        actionState = t('service_contracts.contents.modify.confirm.plan_change_actions.remove');
        styleOverride = {backgroundColor: "#7E7E7E"}
    } else if (type === 'NewProduct') {
        actionState = t('service_contracts.contents.modify.confirm.plan_change_actions.insert')
    } else if (type === 'UpdateProduct' || type === 'UpdatePlan') {
        actionState = t('service_contracts.contents.modify.confirm.plan_change_actions.update')
    }

    return (
        <Card>
            <Box paddingLeft={4} paddingRight={5} py={2}>
                <Grid container alignContent={"center"} alignItems={"center"} justify={"center"}>
                    <Grid item md={7}>
                        <Typography variant={"body1"}>{label}</Typography>
                        {type === 'UpdatePlan' &&
                            <Typography style={{paddingTop: 19}} variant={"body1"}>
                                {oldTier?.product_rate_plan_name} -> {tiers?.product_rate_plan_name}
                            </Typography>
                        }
                        {type === 'UpdateProduct' &&
                            <Typography style={{paddingTop: 19}} variant={"body1"}>
                                {oldTier[0]?.selectable_option} -> {tiers[0]?.selectable_option}
                            </Typography>
                        }
                        {type !== 'UpdateProduct' && !empty(tiers) &&
                            !empty(tiers[0]) &&
                            <Typography style={{paddingTop: 19}} variant={"body1"}>
                                {tiers[0]?.selectable_option}
                            </Typography>
                        }
                    </Grid>
                    <Grid item md={5} style={{textAlign: "right"}}>
                        <Chip label={actionState} size="small" style={{marginTop: "-5px", ...styleOverride}} className={[classes.chip].join(" ")}/>
                        {allowModify &&
                            <Close onClick={() => undoPlanUpdate(product_id)}
                                   style={{marginLeft: 20, paddingTop: 7, cursor: "pointer", color: "#7E7E7E"}}/>
                        }
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );
}

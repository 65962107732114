import {default as types} from "./types";

/***
 * /////////////////////////////////////////
 * REDUCERS (リデューサー)
 * -------------------
 *
 * すべてのリアクトのマネージャーアカウントの一時的なデータが保存される領域。
 * アプリケーションのどこでもデータを使用することができます。
 *
 * Reducerイベントは、ステートへのセッター命令のみを **実行すべき** です。
 *
 * このReducerのステートは、/src/state/ducks/index.jsに設定されているusersグループに束縛されています。
 * //////////////////////////////////////////
 */
export const managerNoticeState = {
    managerLoading: true,
    managerNotices: {},
    managerError: {},

    managerRegisterLoading: false,
    managerIsRegisterView: false,
    activeNotice: null,

    managerIsDeleteView: false,
    deleteNoticeUUID: null,
    deleteLoading: false,
    deleteError: {},

    managerSelectedAccount: null,
    managerSearchKeyword: "",
    managerSearchAccountLoading: false,
    managerSearchAccountError: {},
    managerSearchAccountResult: [],

    editAccountLoading: false,

};

/**
 *
 * @param state
 * @param action
 * @returns
 */
export default function managerNoticeReducer(state = managerNoticeState, action) {
    switch (action.type) {
        /****
         * お知らせ一覧取得エベント
         ******/
        case(types.MANAGER_REQUEST_NOTICES_LOADING):
            return {
                ...state,
                managerLoading: state.managerLoading = true,
                managerError: state.managerError = {}
            }
        case(types.MANAGER_REQUEST_NOTICES_SUCCESS):
            return {
                ...state,
                managerLoading:  state.managerLoading = false,
                managerNotices: state.managerNotices = action.payload.data.notices.notices,
            }
        case(types.MANAGER_REQUEST_NOTICES_FAILURE):
            return {
                ...state,
                managerLoading: state.managerLoading = false,
                managerError: state.managerError = action.payload.error
            }
        case(types.MANAGER_TOGGLE_REGISTER_NOTICE):
            return {
                ...state,
                managerIsRegisterView: !state.managerIsRegisterView,
                activeNotice: state.activeNotice = action.payload.notification
            }
        /****
         * 新規お知らせ登録の送信
         ******/
        case(types.MANAGER_ADD_UPDATE_NOTICE_LOADING):
            return {
                ...state,
                managerRegisterLoading: state.managerRegisterLoading = true,
                managerError: state.managerError = {}

            }
        case(types.MANAGER_ADD_NOTICE_SUCCESS):
            return {
                ...state,
                managerIsRegisterView: state.managerIsRegisterView = false,
                managerRegisterLoading: state.managerRegisterLoading = false
            }
        case(types.MANAGER_ADD_NOTICE_FAILURE):
            return {
                ...state,
                managerError: state.managerError = action.payload.managerError,
                managerRegisterLoading: state.managerRegisterLoading = false
            }
        /****
         * お知らせの削除
         ******/
        case(types.MANAGER_TOGGLE_DELETE_NOTICE):
            return {
                ...state,
                deleteNoticeUUID: state.deleteNoticeUUID = action.payload.accountId,
                managerIsDeleteView: !state.managerIsDeleteView
            }
        case(types.MANAGER_DELETE_NOTICE_LOADING):
            return {
                ...state,
                deleteLoading: state.deleteLoading = true,
                deleteError: state.deleteError = {},
            }
        case(types.MANAGER_DELETE_NOTICE_SUCCESS):
            return {
                ...state,
                deleteLoading: state.deleteLoading = false,
                managerIsDeleteView: state.managerIsDeleteView = false
            }
        case(types.MANAGER_DELETE_NOTICE_FAILURE):
            return {
                ...state,
                deleteLoading: state.deleteLoading = false,
                deleteError: state.deleteError = action.payload.error
            }
        /****
         * お知らせアカウント検索
         ******/
        case(types.MANAGER_SEARCH_ACCOUNT_BY_KEYWORD):
            console.log(action.payload.params.keyword)
            return {
                ...state,
                managerSearchKeyword: state.managerSearchKeyword = action.payload.params.keyword
            }
        case(types.MANAGER_SEARCH_ACCOUNT_BY_KEYWORD_LOADING):
            return {
                ...state,
                managerSearchAccountLoading: state.managerSearchAccountLoading = true,
                managerSearchAccountError: state.managerSearchAccountError = {},
                managerSearchAccountResult: state.managerSearchAccountResult = []
            }
        case(types.MANAGER_SEARCH_ACCOUNT_BY_KEYWORD_SUCCESS):
            return {
                ...state,
                managerSearchAccountLoading: state.managerSearchAccountLoading = false,
                managerSearchAccountResult: state.managerSearchAccountResult = action.payload.account_results
            }
        case(types.MANAGER_SEARCH_ACCOUNT_BY_KEYWORD_FAILURE):
            return {
                ...state,
                managerSearchAccountLoading: state.managerSearchAccountLoading = false,
                managerSearchAccountError: state.managerSearchAccountError = action.payload.error
            }
        case(types.MANAGER_NOTIFICATION_CHOOSE_SEARCH_ACCOUNT):
            return {
                ...state,
                managerSelectedAccount: state.managerSearchAccountLoading = action.payload.account
            }

        default:
            return state;
    }
}

import React from 'react';
import {Box, Card, makeStyles, TextField, Typography} from '@material-ui/core';
import {empty} from "../../../../../../../state/utils/Common";
import {UpdatedRow} from "../../../../../../atoms/rows/transferList/UpdatedRow";
import globalTheme from "../../../../../../theme/globalTheme";

/**
 * /manager/notices コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(1)
    },
    button: {
        fontWeight: 900,
    }
}));
/**
 *
 * /noticesのメインコンポーネント
 * @param managerLoadNoticeList
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const ModifiedPlansView = (
    {
        t,
        serviceContractContentAlterPlan,
        serviceContractContentAlterBasicPlan,
        allowModify = false,
        note,
        modifyNotes,
        undoPlanUpdate,
        undoBasicPlanUpdate
    }) => {

    const globalForms = globalTheme.defaultFormStyle();

    return (
        <>
            <Box>
                <Typography style={{fontSize: 13, marginBottom: 10, fontWeight: 900}}>
                    {t('service_contracts.contents.modify.changed_plan')}
                </Typography>
            </Box>
            {empty(serviceContractContentAlterPlan) && empty(serviceContractContentAlterBasicPlan) &&
                <Box mt={1}>
                    <Card style={{
                        height: 147,
                        borderWidth: 1,
                        borderColor: "#CCCCCC",
                        borderStyle: "dashed",
                        textAlign: "center",
                        lineHeight: "147px"
                    }}>
                        <div style={{display: "inline-block"}}>
                            <Typography>{t('service_contracts.contents.modify.confirm.empty_object')}</Typography>
                        </div>
                    </Card>
                </Box>
            }
            {(!empty(serviceContractContentAlterPlan) || !empty(serviceContractContentAlterBasicPlan)) &&
                <div className={globalForms.groupListRounded}>
                    {
                        !empty(serviceContractContentAlterBasicPlan) &&
                        <UpdatedRow
                            label={t('service_contracts.contents.modify.basic_plan_title_headers')}
                            type={serviceContractContentAlterBasicPlan.ChangeType}
                            tiers={serviceContractContentAlterBasicPlan.ItemData}
                            oldTier={serviceContractContentAlterBasicPlan?.PlanData}
                            undoPlanUpdate={undoBasicPlanUpdate}
                            product_id={serviceContractContentAlterBasicPlan?.PlanData?.id}
                            t={t}
                            allowModify={allowModify}
                        />
                    }
                    {serviceContractContentAlterPlan.map((item) => {
                        return (
                            <UpdatedRow
                                label={item?.PlanData?.product_rate_plan_name}
                                type={item?.ChangeType}
                                tiers={item.ItemData}
                                oldTier={item?.OldItemData}
                                undoPlanUpdate={undoPlanUpdate}
                                product_id={item?.PlanData?.product_rate_plan_id}
                                t={t}
                                allowModify={allowModify}
                            />)
                    })}
                </div>
            }
            {allowModify &&
            <Box paddingTop={3} paddingBottom={1}>
                <Typography style={{fontSize: 13, marginBottom: 10, fontWeight: 900}}>
                    {t('service_contracts.contents.modify.note')}
                </Typography>
                <TextField
                    fullWidth
                    style={{backgroundColor: "#FFFFFF"}}
                    value={note}
                    onChange={(e) => modifyNotes(e.target.value)}
                    placeholder="その他ご要望等ございましたらご記入ください。"
                    variant={"outlined"}
                    multiline
                    rows={10}
                    maxRows={4}
                />
            </Box>
            }
        </>
    );
}

export default ModifiedPlansView;

import {mainReducer as root, rootState, rootWatcherSagas} from "./root";

import {userListState, userReducer as user, userWatcherSagas} from './user/users';
import {addressListState, addressReducer as address, addressWatcherSagas} from './user/addresses';
import {
    serviceContractReducer as serviceContract,
    serviceContractState,
    serviceContractWatcherSagas
} from './user/service_contracts'
import {
    notificationListState,
    notificationReducer as notification,
    notificationWatcherSagas
} from "./user/notifications";
import {managerUserListState, managerUserReducer as managerUser, managerUserWatcherSagas} from "./manager/managerUsers";
import {
    managerAccountListState,
    managerAccountReducer as managerAccount,
    managerAccountWatcherSagas
} from "./manager/managerAccounts";
import {managerNoticeReducer as managerNotice, managerNoticeState, noticeWatcherSagas} from "./manager/managerNotices";
import {
    managerRequestReducer as managerRequest,
    managerRequestState,
    managerRequestWatcherSagas
} from "./manager/managerRequests";
import {requestReducer as request, requestState, RequestWatcherSagas} from "./user/requests";
import {
    serviceContractContentReducer as serviceContractContent,
    serviceContractContentSagas,
    serviceContractContentState
} from "./user/service_contracts/contents"
import {
    serviceContractTransferReducer as serviceContractTransfer,
    serviceContractTransferSagas,
    serviceContractTransferState
} from "./user/service_contracts/transfer";
import {
    fetchServiceContractTerminateSagas,
    serviceContractTerminateReducer as serviceContractTerminate,
    serviceContractTerminateState
} from "./user/service_contracts/terminate";
import {contactReducer as contact, contactState, ContactWatcherSagas} from "./user/contact";
import {
    transferApproveReducer as transferApprove,
    transferApproveState,
    transferApproveWatcherSagas
} from "./guest/transfer"

/***
 * ///////////////////////////////////////////////
 * REDUCKSメインファイル 🦆
 * ---------------------------
 *
 * これは、reduxの子グループを束ねるreducksのメインファイルです。
 * これで、ステイツ、リデューサー、レドゥーサガが結ばれる。
 *
 * reducksの詳細と、新しいreduxグループの追加については、以下のリンクをご参照ください。
 * reducksのテンプレート化の例 :  https://github.com/alexnm/re-ducks#enter-re-ducks
 * //////////////////////////////////////////////
 */
import {all, call, spawn} from 'redux-saga/effects';


/**
 * すべての子のreduxステートを束ねる
 */
export const StoreState = {
    root: rootState,
    transferApprove: transferApproveState,


    user: userListState,
    address: addressListState,
    serviceContract: serviceContractState,
    serviceContractContent: serviceContractContentState,
    serviceContractTransfer: serviceContractTransferState,
    serviceContractTerminate: serviceContractTerminateState,
    request: requestState,
    contact: contactState,
    notification: notificationListState,

    managerUser: managerUserListState,
    managerAccount: managerAccountListState,
    managerNotice: managerNoticeState,
    managerApplication: managerRequestState
};

/**
 * すべてのリデューサーイベントを束ねる
 */
export const reducers = {
    root,

    transferApprove,

    user,
    address,
    serviceContract,
    serviceContractContent,
    serviceContractTransfer,
    serviceContractTerminate,
    request,
    contact,
    notification,

    managerUser,
    managerAccount,
    managerNotice,
    managerRequest
};

/**
 * すべてのサガ・ウォッチャーを束ね、束ねられたウォッチャーの呼び出しに成功した場合、グローバル・キャッチを設定します。
 */
export function* rootSaga() {
    const watchers = [
        ...userWatcherSagas,
        ...addressWatcherSagas,
        ...managerAccountWatcherSagas,
        ...rootWatcherSagas,
        ...managerUserWatcherSagas,
        ...serviceContractWatcherSagas,
        ...serviceContractContentSagas,
        ...serviceContractTransferSagas,
        ...fetchServiceContractTerminateSagas,
        ...noticeWatcherSagas,
        ...managerRequestWatcherSagas,
        ...RequestWatcherSagas,
        ...ContactWatcherSagas,
        ...notificationWatcherSagas,
        ...transferApproveWatcherSagas
    ];

    yield all(
        watchers.map((saga) =>
            spawn(function* () {
                while (true) {
                    try {
                        yield call(saga);
                        break;
                    } catch (ex) {
                        console.log(ex);
                    }
                }
            }),
        ),
    );
}

import actions from "./actions";

/***
 * /////////////////////////////////////////////////////////
 * OPERATIONS オペレーション
 * --------------------
 *
 * ミドルウェアを必要とするすべてのアクションまたはアクションのためのバインドで、一緒にバインドされます。
 * このファイルでは、ミドルウェアのインジェクションを必要とするアクションはありませんが、その例がここにあります。
 * 例: https://github.com/alexnm/re-ducks#operations (英語)
 * ////////////////////////////////////////////////////////
 */


const saveTransferForm = actions.saveTransferForm
const transferToggleTerms = actions.transferToggleTerms
const transferResetTerms = actions.transferResetTerms

const getTransferData = actions.getTransferData

const sendTransferRequest = actions.sendTransferRequest


const operations = {
    saveTransferForm,
    transferToggleTerms,
    transferResetTerms,

    sendTransferRequest,

    getTransferData,
}
export default operations;

/***
 * ////////////////////////////////////////////////
 * ACTIONS (アクション)
 * ------------------
 *
 * コンポーネントやコンテナから要求・呼び出しされるアクション
 * アクションには、REDUCER FILEまたはSAGAミドルウェアにイベントをブロードキャストするためのTYPEキーが必要で、REDUCERまたはSAGAミドルウェアは以下を解析します。
 * ////////////////////////////////////////////////
 */

/*********************************
 * エビスアカウント管理の契約内容照会
 ***********************************/

/*********************************
 * 一覧取得
 ***********************************/

import types from "./types";
import {empty} from "../../../../utils/Common";

/**
 * 申契約内容照会取得エベント
 * @param access_token
 * @param account_id
 * @param request_id
 * @returns {{payload: {access_token}, type: string}}
 */
export function serviceContractContentLoad(access_token, account_id, service_contract_id) {
    return {
        type: types.SERVICE_CONTRACT_CONTENT,
        payload: {
            access_token: access_token,
            account_id: account_id,
            service_contract_id: service_contract_id
        }
    }
}

/**
 * 契約内容照会ロード中エベント
 * @returns {{type: string}}
 */
export function serviceContractContentLoading() {
    return {
        type: types.SERVICE_CONTRACT_CONTENT_LOADING,
    }
}

/**
 * 契約内容照会取得成功エベント
 * @returns {{payload: {requests}, type: string}}
 * @param service_contract_content
 */
export function serviceContractContentLoadSuccess(service_contract_content) {
    return {
        type: types.SERVICE_CONTRACT_CONTENT_SUCCESS,
        payload: {
            service_contract_content: service_contract_content
        }
    }
}

/**
 * 契約内容照会取得失敗エベント
 * @param e
 * @returns {{payload: {error}, type: string}}
 */
export function serviceContractContentLoadFailure(e) {
    return {
        type: types.SERVICE_CONTRACT_CONTENT_FAILURE,
        payload: {
            error: e
        }
    }
}

/*********************************
 * すべてのプラン取得
 ***********************************/
export function serviceContractContentAllPlansLoad(access_token, account_id, service_contract_id) {
    return {
        type: types.SERVICE_CONTRACT_CONTENT_ALL_PLAN,
        payload: {
            access_token: access_token,
            account_id: account_id,
            service_contract_id:service_contract_id
        }
    }
}

export function serviceContractContentAllPlansLoading() {
    return {
        type: types.SERVICE_CONTRACT_CONTENT_ALL_PLAN_LOADING,
    }
}

export function serviceContractContentAllPlansSuccess(results) {
    return {
        type: types.SERVICE_CONTRACT_CONTENT_ALL_PLAN_SUCCESS,
        payload: {
            all_plans: results
        }
    }
}
export function serviceContractContentAllPlansFailure(e) {
    return {
        type: types.SERVICE_CONTRACT_CONTENT_ALL_PLAN_FAILURE,
        payload: {
            error: e
        }
    }
}

/*******************************
 * ナビアクション
 *******************************/

export function contractUpdateToConfirmPage() {
    return {
        type: types.SERVICE_CONTRACT_CONTENT_UPDATE_TO_CONFIRM
    }
}

export function contractBackToUpdatePage() {
    return {
        type: types.SERVICE_CONTRACT_CONTENT_BACK_TO_UPDATE
    }
}

export function resetForm() {
    return {
        type: types.SERVICE_CONTRACT_CONTENT_RESET_UPDATE_FORM
    }
}

/*******************************
 * ご契約内容の変更の承認アクション
 *******************************/
export function confirmContractUpdate(accessToken, accountId, serviceContractId, changePlanData, inquiry) {
    console.log(changePlanData);


    const OptimisedArray = changePlanData.map((item) => {
        let optimisedItem = {
            type: item?.ChangeType,
        }
        switch (item?.ChangeType) {
            case ("RemoveProduct"):
                return {
                    ...optimisedItem,
                    from_rate_plan_charge_id: item.PlanData.product_rate_plan_charge_id
                }
            case("NewProduct"):
                return {
                    ...optimisedItem,
                    to_rate_plan_charge_id: item.PlanData.product_rate_plan_charge_id,
                    to_rate_plan_charge_tier_id: !empty(item.ItemData) ? item.ItemData[0].id : null
                }
            case("UpdateProduct"):
                return {
                    ...optimisedItem,
                    from_rate_plan_charge_id: item.PlanData.product_rate_plan_charge_id,
                    to_rate_plan_charge_id: item.PlanData.product_rate_plan_charge_id,
                    from_rate_plan_charge_tier_id: item.OldItemData[0].id,
                    to_rate_plan_charge_tier_id: item.ItemData[0].id
                }
            case("UpdatePlan"):
                return {
                    type: "UpdateProduct",
                    from_rate_plan_charge_id: item.PlanData.product_rate_plan_charge_id,
                    to_rate_plan_charge_id: item.ItemData.product_rate_plan_charge_id
                }
            default:
                return {

                }
        }
    })

    console.log(OptimisedArray);
    return {
        type: types.SERVICE_CONTRACT_CONTENT_UPDATE_CONFIRMED,
        payload: {
            accessToken: accessToken,
            account_id: accountId,
            serviceContractId: serviceContractId,
            plans: OptimisedArray,
            inquiry: inquiry
        }
    }
}

/**
 * ロード中
 */
export function confirmContractUpdateLoading() {
    return {
        type: types.SERVICE_CONTRACT_CONTENT_UPDATE_CONFIRMED_LOADING
    }
}

/**
 * 成功
 * @returns {{type: string}}
 */
export function confirmContractUpdateSuccess() {
    return {
        type: types.SERVICE_CONTRACT_CONTENT_UPDATE_CONFIRMED_SUCCESS
    }
}

/**
 * 失敗
 * @param error
 * @returns {{payload: {error}, type: string}}
 */
export function confirmContractUpdateFailure(error) {
    return {
        type: types.SERVICE_CONTRACT_CONTENT_UPDATE_CONFIRMED_FAILURE,
        payload: {
            error: error
        }
    }
}

/*******************************
 * プラン変更アクション
 *******************************/

/**
 * 新規プランの追加。
 * @param PlanData
 * @param ItemData
 * @returns {{payload: {PlanData, ChangeType: string, ItemData}, type: string}}
 */
export function addPlan(PlanData, ItemId) {


    const ItemData = PlanData?.tiers.filter(function(value, index) {
        return value.id === ItemId
    })

    return {
        type: types.SERVICE_CONTRACT_CONTENT_ADD_PLAN,
        payload: {
            ProductObject: {
                ChangeType: "NewProduct",
                PlanData: PlanData,
                ItemData: ItemData
            }
        }
    }
}

/**
 * プラン条件の変更
 * @param PlanData
 * @param OldItemId
 * @param NewItemId
 * @returns {{payload: {ProductObject: {PlanData, ChangeType: string, NewItemData: *, OldItemData: *}}, type: string}}
 */
export function changeItem(PlanData, OldItemId, NewItemId) {

    const OldItemData = PlanData?.tiers.filter(function(value, index) {
        return value.id === OldItemId
    })

    const ItemData = PlanData?.tiers.filter(function(value, index) {
        return value.id === NewItemId
    })

    return {
        type: types.SERVICE_CONTRACT_CONTENT_UPDATE_ITEM,
        payload: {
            ProductObject: {
                ChangeType: "UpdateProduct",
                PlanData: PlanData,
                OldItemData: OldItemData,
                ItemData: ItemData
            }
        }
    }
}

/**
 * プランの変更
 * @param PlanData
 * @param NewPlanData
 * @returns {{payload: {PlanData, ChangeType: string, ItemData}, type: string}}
 */
export function changePlan(PlanData, NewPlanData) {
    if(!empty(NewPlanData)) {
        NewPlanData = NewPlanData[0];
    }
    console.log(PlanData);
    console.log(NewPlanData);

    return {
        type: types.SERVICE_CONTRACT_CONTENT_UPDATE_PLAN,
        payload: {
            ProductObject: {
                ChangeType: "UpdatePlan",
                PlanData: PlanData,
                ItemData: NewPlanData
            }
        }
    }
}

/**
 * ご契約中のプランの削除
 * @param PlanData
 * @param ItemData
 * @returns {{payload: {PlanData, ChangeType: string, ItemData}, type: string}}
 */
export function removePlan(PlanData, ItemData) {
    return {
        type: types.SERVICE_CONTRACT_CONTENT_REMOVE_PLAN,
        payload: {
            ProductObject: {
                ChangeType: "RemoveProduct",
                PlanData: PlanData,
                ItemData: null
            }
        }
    }
}

/**
 * 前の状態に戻す
 * @param PlanId
 * @returns {{payload: {PlanId}, type: string}}
 */
export function undoPlanUpdate(PlanId) {
    return {
        type: types.SERVICE_CONTRACT_CONTENT_UNDO_UPDATE_PLAN,
        payload: {
            PlanId: PlanId
        }
    }
}

export function undoBasicPlanUpdate() {
    return {
        type: types.SERVICE_CONTRACT_CONTENT_UNDO_BASIC_PLAN,
    }
}

export function modifyNotes(notes) {
    return {
        type: types.SERVICE_CONTRACT_MODIFY_NOTES,
        payload: {
            notes: notes
        }
    }
}

export function toggleTerms(termsFlag) {
    console.log(termsFlag)
    return {
        type: types.SERVICE_CONTRACT_ACCEPT_TERMS_AND_CONDITIONS,
        payload: {
            terms_agreed: termsFlag
        }
    }
}


const actions = {
    serviceContractContentLoad,
    serviceContractContentLoading,
    serviceContractContentLoadSuccess,
    serviceContractContentLoadFailure,

    serviceContractContentAllPlansLoad,
    serviceContractContentAllPlansLoading,
    serviceContractContentAllPlansSuccess,
    serviceContractContentAllPlansFailure,

    contractUpdateToConfirmPage,
    contractBackToUpdatePage,
    resetForm,

    confirmContractUpdate,
    confirmContractUpdateLoading,
    confirmContractUpdateSuccess,
    confirmContractUpdateFailure,

    addPlan,
    changeItem,
    changePlan,
    removePlan,
    undoPlanUpdate,
    undoBasicPlanUpdate,
    modifyNotes,

    toggleTerms
};
export default actions;

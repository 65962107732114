import React from 'react';
import {Box, Breadcrumbs, Card, Container, Grid, makeStyles, Typography} from '@material-ui/core';
import Page from "../../../../../Page";
import clsx from "clsx";
import globalTheme from "../../../../../../../theme/globalTheme";
import ConfirmView from "./steps/Confirm";
import CompleteView from "./steps/Complete";
import urlLinks from "../../../../../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json";
import {Link} from "react-router-dom";
import AdditionalSteps from "../apply_new/steps/AdditionalSteps";
import {useHistory} from "react-router";

/**
 * /manager/notices コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    },
    button: {
        fontWeight: 900,
    },
    strong: {
        fontWeight: "bold"
    }
}));
/**
 *
 * /noticesのメインコンポーネント
 * @param managerLoadNoticeList
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const RequestApplyPage = (
    {
        requestDetailLoading,
        requestQuote,
        requestStartUpInformationStep,
        contractUpdateRequest,
        changePlanApprovedLoading,
        changePlanApprovedError,
        applyToggleTerms,
        hasScrolledToTermsBottom,
        scrolledToTermsBottom,
        managerProfile,
        quoteId,
        settingApplyConfirm,
        requestId,
        requestApplyAcceptTerms,
        termsAndConditionsIsOpen,
        requestDetail,
        closeTermsAndConditionsModal,
        openTermsAndConditionsModal,
        additionalOptionInformation,
        updateRequiresOptionInformation,
        updateOptionStep,
        additionalFieldsArray,
        settingAdditionalSteps,
        additionalOptionValidation,
        settingUpdateAdditionalSteps,
        backToAdditionalSteps,
        formattedAdditionalOptionInformation,
        t
    }) => {
    const classes = useStyles();
    const globalFormStyle = globalTheme.wizard();
    const history = useHistory();
    const colWidth = updateRequiresOptionInformation === true ? 4 : 6
    let step = 1;
    return (
        <Page
            className={classes.root}
            title={t('requests.detail.quotes.title')}
        >
            {requestDetailLoading !== true &&
            <Container maxWidth={false}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h2">{t('requests.detail.quotes.title')}</Typography>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Breadcrumbs separator={">"} aria-label="breadcrumb">
                            <Link to={urlLinks.navigation.top.index.href}>
                                <Typography color="textPrimary">{t('general.top')}</Typography>
                            </Link>
                            <Link to={urlLinks.navigation.requests.index.href}>
                                <Typography color="textPrimary">{t('requests.title_user')}</Typography>
                            </Link>
                            <Link
                                to={urlLinks.navigation.requests.index.sub_links.detail.href.replace('{RID}', requestId)}>
                                <Typography color="textPrimary">{t('requests.detail.title')}</Typography>
                            </Link>
                            <Link
                                to={urlLinks.navigation.requests.index.sub_links.detail.sub_links.quote.href.replace('{RID}', requestId).replace('{QID}', quoteId)}>
                                <Typography color="textPrimary">{t('requests.detail.quotes.title')}</Typography>
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Box mt={1}>
                    <Card>
                        <Box className={clsx(classes.root)}>
                            <Grid item xs={12} md={12}>
                                <Typography variant="h4">{t('requests.detail.quotes.apply_new.title')}</Typography>
                            </Grid>
                        </Box>
                        <Grid container spacing={0}>
                            {updateRequiresOptionInformation === true &&
                            <Grid item xs={colWidth} className={
                                clsx({
                                    [globalFormStyle.headers]: true,
                                    ['active']: updateOptionStep === 0,
                                    ['complete']: updateOptionStep > 0
                                })}>
                                <Typography
                                    variant={"body1"}>{t('requests.detail.quotes.apply.wizard_titles.step_additional_option', {step: step++})}
                                </Typography>
                            </Grid>
                            }
                            <Grid item xs={colWidth} className={
                                clsx({
                                    [globalFormStyle.headers]: true,
                                    ['active']: requestStartUpInformationStep === 0 && (updateOptionStep > 0 || updateRequiresOptionInformation === false),
                                    ['complete']: requestStartUpInformationStep > 0 && (updateOptionStep > 0 || updateRequiresOptionInformation === false)
                                })}>
                                <Typography
                                    variant={"body1"}>{t('requests.detail.quotes.apply.wizard_titles.step_confirm', {step: step++})}
                                </Typography>
                            </Grid>
                            <Grid item xs={colWidth} className={
                                clsx({
                                    [globalFormStyle.headers]: true,
                                    ['active']: requestStartUpInformationStep === 1
                                })}>
                                <Typography
                                    variant={"body1"}>{t('requests.detail.quotes.apply.wizard_titles.step_complete', {step: step++})}
                                </Typography>
                            </Grid>
                        </Grid>

                        {updateRequiresOptionInformation === true && updateOptionStep === 0 &&
                        <Box className={clsx(classes.root)}>
                            <AdditionalSteps
                                t={t}
                                quoteId={quoteId}
                                requestId={requestId}
                                settingAdditionalSteps={settingUpdateAdditionalSteps}
                                settingEbisBackButton={() => {history.push('/requests/' + requestId + "/quotes/" + quoteId + '/')}}
                                additionalOptionInformation={additionalOptionInformation}
                                additionalFieldsArray={additionalFieldsArray}
                                additionalOptionValidation={additionalOptionValidation}
                            />
                        </Box>
                        }

                        {requestStartUpInformationStep === 0 && (updateOptionStep > 0 || updateRequiresOptionInformation === false) &&
                        <ConfirmView
                            t={t}
                            requestDetail={requestDetail}
                            requestQuote={requestQuote}
                            requestApplyAcceptTerms={requestApplyAcceptTerms}
                            applyToggleTerms={applyToggleTerms}
                            hasScrolledToTermsBottom={hasScrolledToTermsBottom}
                            scrolledToTermsBottom={scrolledToTermsBottom}
                            settingApplyConfirm={settingApplyConfirm}
                            termsAndConditionsIsOpen={termsAndConditionsIsOpen}
                            closeTermsAndConditionsModal={closeTermsAndConditionsModal}
                            openTermsAndConditionsModal={openTermsAndConditionsModal}
                            contractUpdateRequest={contractUpdateRequest}
                            additionalOptionInformation={additionalOptionInformation}
                            updateRequiresOptionInformation={updateRequiresOptionInformation}
                            backToAdditionalSteps={backToAdditionalSteps}
                            changePlanApprovedLoading={changePlanApprovedLoading}
                            changePlanApprovedError={changePlanApprovedError}
                            managerProfile={managerProfile}
                            formattedAdditionalOptionInformation={formattedAdditionalOptionInformation}
                            quoteId={quoteId}
                            requestId={requestId}
                        />
                        }
                        {requestStartUpInformationStep === 1 &&
                        <Box className={clsx(classes.root)}>
                            <CompleteView t={t}/>
                        </Box>
                        }
                    </Card>
                </Box>
            </Container>
            }
        </Page>
    );
}

export default RequestApplyPage;

import validate from "validate.js";
import {empty, stringIndexSearch, stringOperatorsToMath} from "./Common";
import moment from "moment";
import {element} from "prop-types";
import axios from "axios";

/***
 * ////////////////////////////////
 * バリデーションユーティリティーヘルパー(ansman/validate.js を使用)
 * ----------------------
 *
 * このプロジェクトのすべてのフォームを妥当性確認するユーティリティです。
 * そして、フォームのキーを読み取り可能なエラーメッセージに変換します。
 * @dependency https://github.com/ansman/validate.js
 * ////////////////////////////////
 */

validate.extend(validate.validators.datetime, {
    // The value is guaranteed not to be null or undefined but otherwise it
    // could be anything.
    parse: function(value, options) {
        return +moment.utc(value);
    },
    // Input is a unix timestamp
    format: function(value, options) {
        var format = options.dateOnly ? "YYYY/MM/DD" : "YYYY/MM/DD hh:mm:ss";
        return moment.utc(value).format(format);
    }
});



/**
 * フィールドが空であるかどうかをチェックするバリデーションチェッカ
 * 翻訳されるために、メッセージを書き換える
 */
validate.validators.presence.options =
    {
        message: function (value, attribute) {
            return validate.format("^%{attribute}%{required}", {
                attribute: "{label_name." + attribute + "}",
                required: "{validation.required}"
            });
        },
        allowEmpty: false
    };

/**
 * 数字チェッカー
 */
validate.validators.numericality.options = {message: '^validator.number_error'}

/**
 * メールチェッカー
 */
validate.validators.email.options = {message: '^validator.email_error'}

validate.validators.url.options = {message: '^validator.url_error'}

/**
 * 最大文字数制限チェッカー
 */
validate.validators.length.options = {
    message: function (value, options, attribute) {
        // 正確な文字の長さ
        if(!empty(attribute) && 'is' in attribute) {
            return validate.format("^%{exact}%{exact_length}", {
                exact: "{" + attribute?.is.toString() + "}",
                exact_length: "{validation.not_exact}"
            })
        }
        // 最大文字数
        return validate.format("^%{limit}%{max_length}", {
            limit: "{" + attribute?.maximum.toString() + "}",
            max_length: "{validation.max_length}"
        })
    }
}

validate.validators.typeOfArray = function (value, options, attribute, attributes, globalOptions) {
    let arrayItemErrors = [];
    if(!empty(value)) {
        arrayItemErrors = value.reduce((errors, item, index) => {
            const error = validate(item, options)
            if (error) errors[index] = { error: error }
            return errors
        }, {});
    }

    return empty(arrayItemErrors) ? null : { errors: arrayItemErrors }
}

/**
 * フィールドは "どちらか "が提供されている場合、必須でなければならない」カスタムバリデーション
 *
 * @param value
 * @param options
 * @param attribute
 * @param attributes
 * @param globalOptions
 * @returns {string}
 */
validate.validators.eitherFieldRequired = function (value, options, attribute, attributes, globalOptions) {
    if (empty(options.compareField)) {
        return "^validator.invalid_compare_field";
    }
    if (empty(value) && empty(attributes[options.compareField])) {
        return "^validator.telephone_number_required"
    }
}

/**
 * 小文字の半角英字、半角数字、「_」のみ利用可。
 * @param value
 * @param options
 * @param attribute
 * @param attributes
 * @param globalOptions
 * @returns {string}
 */
validate.validators.smallLetterAndNumbersValidation = function (value, options, attribute, attributes, globalOptions) {
    if(!empty(value)) {
        const regSyntax = new RegExp(/^[a-z0-9_]*$/);
        if(!regSyntax.test(value)) {
            return "^validator.number_letters_validation"
        }
    }
}

/**
 * 半角英字、半角数字、のみ利用可。
 * @param value
 * @param options
 * @param attribute
 * @param attributes
 * @param globalOptions
 * @returns {string}
 */
validate.validators.halfCharacterEnglishAndNumbersOnlyValidation = function (value, options, attribute, attributes, globalOptions) {
    if(!empty(value)) {
        const regSyntax = new RegExp(/^[a-zA-Z0-9]*$/);
        if(!regSyntax.test(value)) {
            return "^validator.half_character_english_and_numbers_only"
        }
    }
}

validate.validators.firstCharacterMustBeHalfEnglishCharacter = function (value, options, attribute, attributes, globalOptions) {
    if (!empty(value)) {
        const regSyntax = new RegExp(/^[a-z].*$/);
        if (!regSyntax.test(value)) {
            return "^validator.first_character_validation"
        }
    }
}

validate.validators.databaseSafe = function (value, options, attribute, attributes, globalOptions) {
    if (!empty(value)) {
        const regSyntax = new RegExp(/^[^&`']+$/);
        if (!regSyntax.test(value)) {
            return "^validator.database_safe"
        }
    }
}

validate.validators.presenceIf = function (value, options, attribute, attributes, globalOptions) {
    // console.log(value);
    // console.log(options);
    // console.log(attributes)
    if(!empty(options) && !empty(options.field) && !empty(options.value)) {
        if(!empty(attributes[options.field])) {
            let isRequired = false;
            switch (options.operator) {
                case('>'):
                    isRequired = (attributes[options.field] > options.value);
                    break;
                case('>='):
                    isRequired = (attributes[options.field] >= options.value)
                    break;
                case('='):
                default:
                    isRequired = (attributes[options.field] === options.value)
                    break;
                case('<='):
                    isRequired = (attributes[options.field] <= options.value)
                    break;
                case('<'):
                    isRequired = (attributes[options.field] < options.value)
                    break;
            }
            console.log(isRequired)
            console.log(value);
            if(isRequired && empty(value)) {
                return `^validator.${options.errorKey}.required`
            }
        }
        return ""
    }
    return "^validator.insufficient_fields"
}

/**
 * カスタム電話妥当性確認オブジェクト
 *
 * @param value
 * @param options
 * @param attribute
 * @param attributes
 * @param globalOptions
 * @returns {string}
 */
validate.validators.phone_validation = function (value, options, attribute, attributes, globalOptions) {
    if(!empty(value)) {
        const regSyntax = new RegExp(/^[0-9 -]+$/);
        if(!regSyntax.test(value)) {
            return "^validator.telephone_format"
        }
    }
}

/**
 * 日付比較妥当性
 *
 * @param value
 * @param options
 * @param attribute
 * @param attributes
 * @param globalOptions
 * @returns {string}
 */
validate.validators.dateRangeValidation = function(value, options, attribute, attributes, globalOptions) {
    /**
     * 日付比較項目は空の場合
     */
    if (empty(options.compareField)) {
        return "^validator.invalid_compare_field";
    }

    /**
     * 数学演算子は空の場合
     */
    if (empty(options.operator)) {
        return "^validator.empty_operator"
    }

    let homeDate = null;
    let relationalDate = null;

    /**
     * 項目の日付は空じゃない場合、Dateオブジェクトを作成する.
     */
    if(!empty(value)) {
        const isValidDate = Date.parse(value);
        if(isNaN(isValidDate)) {
            return "^validator.bad_date"
        }
        homeDate = new Date(value);
    }

    /**
     * 日付比較項目は空じゃない場合、Dateオブジェクトを作成する.
     */
    if(!empty(attributes[options.compareField])) {
        const relationIsValidDate = Date.parse(attributes[options.compareField]);
        if(isNaN(relationIsValidDate)) {
            return "^validator.bad_date"
        }
        relationalDate = new Date(attributes[options.compareField])
    }

    /**
     * 日付比較項目と
     * 現在の項目の比較
     */
    if(homeDate instanceof Date && relationalDate instanceof Date) {
        if(!stringOperatorsToMath[options.operator](homeDate, relationalDate)) {
            return "^validator.invalid_date_range"
        }
    }
}

/**
 * i18nextオブジェクトであらゆるバリデーションエラーを翻訳
 * @param {*} translation i18next.t Object
 * @param {Object} translationObject すべての妥当性確認エラーオブジェクト
 * @returns {*}
 */
export const translate = (translation, translationObject) => {
    if (!empty(translationObject)) {
        Object.keys(translationObject).forEach(function (key, value) {
            if (!empty(translationObject[key][0]) && translationObject[key][0].includes("{")) {
                let translatedString = ""
                // ラベルと入力欄の翻訳機能
                stringIndexSearch('{', translationObject[key][0]).forEach((v) => {
                    translatedString = translatedString +
                        translation(
                            translationObject[key][0].substr(
                                v + 1,
                                translationObject[key][0].slice(v).indexOf('}') - 1
                            )
                        )
                })
                translationObject[key] = translatedString;
            } else {
                // メッセージだけの翻訳
                translationObject[key] = translation(translationObject[key])
            }
        });
        console.log(translationObject)
        return translationObject;
    }
}
export default validate;

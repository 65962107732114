import {Box, Grid, makeStyles, Typography,} from "@material-ui/core";
import React from "react";
import {commonStyles} from "../../../../../theme/user/styles/common";
import {FixedSizeList} from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import {ManagerQuotesRow} from "./ManagerQuotesRow";

/**
 * コンポーネントスタイル
 *
 * @type {(props?: any) => ClassNameMap<"paper"|"root">}
 */
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        border: "1px #CCCCCC solid",
        boxShadow: "none"
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));
/**
 * 通常ユーザー側の申込詳細画面の見積り一覧
 *
 * @param {makeStyles} className - コンポーネントのスタイル
 * @param requests
 * @param requestListLoading
 * @param {*} t - 翻訳オブジェクト
 * @returns {JSX.Element}
 * @constructor
 */
const ManagerQuotesList =
    ({
         className,
         requestUUID,
         related_quotes,
         t
     }) => {
        const common = commonStyles();
        return (
            <>
                <Grid container className={common.tableHeader} style={{paddingRight: 29}}>
                    <Grid item xs={1}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('requests.detail.quote_list_headers.status')}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('requests.detail.quote_list_headers.quote_name')}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('requests.detail.quote_list_headers.quote_number')}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('requests.detail.quote_list_headers.quote_day')}
                        </Typography>
                    </Grid>
                </Grid>
                <Box style={{height: 450}} data-testid={"has-users"}>
                    <AutoSizer>
                        {({height, width}) => (
                            <FixedSizeList
                                width={width}
                                height={height}
                                itemCount={related_quotes?.length}
                                itemSize={45}
                                itemData={{
                                    related_quotes: related_quotes,
                                    requestUUID: requestUUID,
                                    t: t,
                                }}
                            >
                                {ManagerQuotesRow}
                            </FixedSizeList>
                        )}
                    </AutoSizer>
                </Box>
            </>
        );
    };

export default ManagerQuotesList;

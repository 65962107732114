import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core';
import TopBar from './TopBar';
import Contents from "../../components/user/Contents";
import Footer from "./Footer";

/**
 * コンポーネントスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 92
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden'
    },
    content: {
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto'
    }
}));

/**
 * 通常ユーザーユーザー側のダッシュボードレイアウト
 *
 * @param getProfile
 * @param managerProfile
 * @param sidebarLinks
 * @returns {JSX.Element}
 * @constructor
 */
const GuestDashboardLayout = ({getProfile, sidebarLinks}) => {
    // コンポーネントスタイルの取得
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <TopBar/>
            <div className={classes.wrapper}>
                <div className={classes.contentContainer}>
                    <div className={classes.content}>
                        <Contents/>
                        <Footer/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GuestDashboardLayout;

import React from 'react';
import {Box, Breadcrumbs, Card, Container, Grid, makeStyles, Typography} from '@material-ui/core';
import Page from "../../../../../Page";
import clsx from "clsx";
import EbisInformation from "./steps/EbisInformation";
import globalTheme from "../../../../../../../theme/globalTheme";
import ConfirmView from "./steps/Confirm";
import CompleteView from "./steps/Complete";
import {Link} from "react-router-dom";
import urlLinks from "../../../../../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json";
import AdditionalSteps from "./steps/AdditionalSteps";
import {empty} from "../../../../../../../../state/utils/Common";

/**
 * /manager/notices コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    },
    button: {
        fontWeight: 900,
    },
    strong: {
        fontWeight: "bold"
    }
}));
/**
 *
 * /noticesのメインコンポーネント
 * @param managerLoadNoticeList
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const RequestApplyNewPage = (
    {
        requestDetailLoading,
        requestQuote,
        requestDetail,
        additionalFieldsArray,
        additionalOptionValidation,
        settingAdditionalSteps,
        requestStartUpInformationStep,
        requestStartUpInformation,
        settingSubmitEbisFormInformation,
        settingConfirmEbisFormInformation,
        applyToggleTerms,

        scrolledToTermsBottom,
        hasScrolledToTermsBottom,

        managerProfile,
        quoteId,
        requestId,
        requestApplyAcceptTerms,
        settingEbisBackButton,
        termsAndConditionsIsOpen,
        closeTermsAndConditionsModal,
        openTermsAndConditionsModal,
        ebisAccountConfirmedLoading,
        ebisAccountConfirmedError,
        additionalOptionInformation,
        formattedAdditionalOptionInformation,
        t
    }) => {
    const classes = useStyles();
    const globalFormStyle = globalTheme.wizard()
    const spacing = !empty(additionalFieldsArray.length) ? 3 : 4;
    let step = 1;
    return (
        <Page
            className={classes.root}
            title={t('requests.detail.quotes.title')}
        >
            {requestDetailLoading !== true &&
                <Container maxWidth={false}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                            <Typography variant="h2">{t('requests.detail.quotes.title')}</Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <Breadcrumbs separator={">"} aria-label="breadcrumb">
                                <Link to={urlLinks.navigation.top.index.href}>
                                    <Typography color="textPrimary">{t('general.top')}</Typography>
                                </Link>
                                <Link to={urlLinks.navigation.requests.index.href}>
                                    <Typography color="textPrimary">{t('requests.title_user')}</Typography>
                                </Link>
                                <Link
                                    to={urlLinks.navigation.requests.index.sub_links.detail.href.replace('{RID}', requestId)}>
                                    <Typography color="textPrimary">{t('requests.detail.title')}</Typography>
                                </Link>
                                <Link
                                    to={urlLinks.navigation.requests.index.sub_links.detail.sub_links.quote.href.replace('{RID}', requestId).replace('{QID}', quoteId)}>
                                    <Typography color="textPrimary">{t('requests.detail.quotes.title')}</Typography>
                                </Link>
                            </Breadcrumbs>
                        </Grid>
                    </Grid>
                    <Box mt={1}>
                        <Card>
                            <Box className={clsx(classes.root)}>
                                <Grid item xs={12} md={12}>
                                    <Typography variant="h4">{t('requests.detail.quotes.apply_new.title')}</Typography>
                                </Grid>
                            </Box>
                            <Grid container spacing={0}>
                                <Grid item xs={spacing} className={
                                    clsx({
                                        [globalFormStyle.headers]: true,
                                        ['active']: requestStartUpInformationStep === 0,
                                        ['complete']: requestStartUpInformationStep > 0
                                    })}>
                                    <Typography
                                        variant={"body1"}>{t('requests.detail.quotes.apply_new.wizard_titles.step_ebis', {step: step++})}
                                    </Typography>
                                </Grid>
                                {!empty(additionalFieldsArray.length) &&
                                <Grid item xs={spacing} className={
                                    clsx({
                                        [globalFormStyle.headers]: true,
                                        ['active']: requestStartUpInformationStep === 1,
                                        ['complete']: requestStartUpInformationStep > 1
                                    })}>
                                    <Typography
                                        variant={"body1"}>{t('requests.detail.quotes.apply_new.wizard_titles.step_additional_option', {step: step++})}</Typography>
                                </Grid>
                                }
                                <Grid item xs={spacing} className={
                                    clsx({
                                        [globalFormStyle.headers]: true,
                                        ['active']: requestStartUpInformationStep === 2,
                                        ['complete']: requestStartUpInformationStep > 2
                                    })}>
                                    <Typography
                                        variant={"body1"}>{t('requests.detail.quotes.apply_new.wizard_titles.step_confirm', {step: step++})}
                                    </Typography>
                                </Grid>
                                <Grid item xs={spacing} className={
                                    clsx({
                                        [globalFormStyle.headers]: true,
                                        ['active']: requestStartUpInformationStep === 3
                                    })}>
                                    <Typography
                                        variant={"body1"}>{t('requests.detail.quotes.apply_new.wizard_titles.step_complete', {step: step++})}
                                    </Typography>
                                </Grid>
                            </Grid>

                            {requestStartUpInformationStep === 0 &&
                                <Box className={clsx(classes.root)}>
                                    <EbisInformation
                                        t={t}
                                        quoteId={quoteId}
                                        requestId={requestId}
                                        managerProfile={managerProfile}
                                        requestStartUpInformation={requestStartUpInformation}
                                        settingSubmitEbisFormInformation={settingSubmitEbisFormInformation}
                                        additionalFieldsArray={additionalFieldsArray}
                                    />
                                </Box>
                            }
                            {requestStartUpInformationStep === 1 &&
                            <Box className={clsx(classes.root)}>
                                <AdditionalSteps
                                    t={t}
                                    quoteId={quoteId}
                                    requestId={requestId}
                                    settingAdditionalSteps={settingAdditionalSteps}
                                    settingEbisBackButton={settingEbisBackButton}
                                    additionalOptionInformation={additionalOptionInformation}
                                    additionalFieldsArray={additionalFieldsArray}
                                    additionalOptionValidation={additionalOptionValidation}
                                />
                            </Box>
                            }
                            {requestStartUpInformationStep === 2 &&
                                <ConfirmView
                                    t={t}
                                    formattedAdditionalOptionInformation={formattedAdditionalOptionInformation}
                                    additionalOptionInformation={additionalOptionInformation}
                                    requestQuote={requestQuote}
                                    requestDetail={requestDetail}
                                    managerProfile={managerProfile}
                                    quoteId={quoteId}
                                    requestId={requestId}
                                    additionalFieldsArray={additionalFieldsArray}
                                    requestApplyAcceptTerms={requestApplyAcceptTerms}
                                    applyToggleTerms={applyToggleTerms}
                                    scrolledToTermsBottom={scrolledToTermsBottom}
                                    hasScrolledToTermsBottom={hasScrolledToTermsBottom}
                                    requestStartUpInformation={requestStartUpInformation}
                                    settingConfirmEbisFormInformation={settingConfirmEbisFormInformation}
                                    settingEbisBackButton={settingEbisBackButton}
                                    termsAndConditionsIsOpen={termsAndConditionsIsOpen}
                                    closeTermsAndConditionsModal={closeTermsAndConditionsModal}
                                    openTermsAndConditionsModal={openTermsAndConditionsModal}
                                    ebisAccountConfirmedLoading={ebisAccountConfirmedLoading}
                                    ebisAccountConfirmedError={ebisAccountConfirmedError}
                                />

                            }
                            {requestStartUpInformationStep === 3 &&
                                <Box className={clsx(classes.root)}>
                                    <CompleteView t={t}/>
                                </Box>
                            }
                        </Card>
                    </Box>
                </Container>
            }
        </Page>
    );
}

export default RequestApplyNewPage;

import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import React from "react";
import ModifiedPlansView from "../../../../../components/user/serviceContracts/contents/modify/modifiedPlans/Page";
import {serviceContractContentOperations} from "../../../../../../state/ducks/user/service_contracts/contents";


/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,
        serviceContractContentAlterPlan: state.serviceContractContent.serviceContractContentAlterPlan,
        serviceContractContentAlterBasicPlan: state.serviceContractContent.serviceContractContentAlterBasicPlan,
        note: state.serviceContractContent.note
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    undoPlanUpdate: serviceContractContentOperations.undoPlanUpdate,
    undoBasicPlanUpdate: serviceContractContentOperations.undoBasicPlanUpdate,
    modifyNotes: serviceContractContentOperations.modifyNotes
}

/***
 * ///////////////////////////////////////////////////////////
 * CONTAINER VS COMPONENT (コンテイナ対コンポーネント)
 * --------------------------------
 * コンテナは、任意のアクションを *実行* し、任意の redux ストアやオペレーションをロードして、コンポーネントにプッシュします。
 * コンポーネントは、可能な限りダムでなければなりません。つまり、目に見えるデータのみを表示する必要があります。
 * ///////////////////////////////////////////////////////////
 */

/*
通常ユーザー側の申込コンテイナー
*/
const _modifiedPlansContainer = (
    {
        allowModification = false,
        serviceContractContentAlterPlan,
        serviceContractContentAlterBasicPlan,
        undoPlanUpdate,
        undoBasicPlanUpdate,
        note,
        modifyNotes,
        t,
    }) => {

    return (
        <ModifiedPlansView
            t={t}
            allowModify={allowModification}
            serviceContractContentAlterPlan={serviceContractContentAlterPlan}
            serviceContractContentAlterBasicPlan={serviceContractContentAlterBasicPlan}
            undoPlanUpdate={undoPlanUpdate}
            note={note}
            modifyNotes={modifyNotes}
            undoBasicPlanUpdate={undoBasicPlanUpdate}
        />
    )
}

/**
 *
 * * Redux
 * * i18next Translations
 * を
 * UserView コンポーネントに追加する。
 */
const ModifiedPlansContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_modifiedPlansContainer)

export default ModifiedPlansContainer;

import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {useAuth0} from "@auth0/auth0-react";
import React, {useEffect} from "react";
import {rootOperations} from "../../../../../../state/ducks/root";
import userSideBarLinks from "../../../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json"
import {empty} from "../../../../../../state/utils/Common";
import Auth0Utils from "../../../../../../state/utils/Auth0Utils";
import {requestOperations} from "../../../../../../state/ducks/user/requests";
import {useParams} from "react-router";
import RequestQuoteDetailView from "../../../../../components/user/requests/detail/quote/Page";


/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,

        requestDetail: state.request.requestDetail,
        requestQuote: state.request.requestQuote,
        requestQuoteLoading: state.request.requestQuoteLoading,
        termsAndConditionsIsOpen: state.request.termsAndConditionsIsOpen,
        pdfIsLoading: state.request.pdfIsLoading
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    loadSidebarItems: rootOperations.loadSidebarItems,
    requestsDetailQuoteDetailLoad: requestOperations.requestsDetailQuoteDetailLoad,
    closeTermsAndConditionsModal: requestOperations.closeTermsAndConditionsModal,
    openTermsAndConditionsModal: requestOperations.openTermsAndConditionsModal,
    downloadQuotePDF: requestOperations.downloadQuotePDF,
}

/***
 * ///////////////////////////////////////////////////////////
 * CONTAINER VS COMPONENT (コンテイナ対コンポーネント)
 * --------------------------------
 * コンテナは、任意のアクションを *実行* し、任意の redux ストアやオペレーションをロードして、コンポーネントにプッシュします。
 * コンポーネントは、可能な限りダムでなければなりません。つまり、目に見えるデータのみを表示する必要があります。
 * ///////////////////////////////////////////////////////////
 */

/*
通常ユーザー側の申込詳細コンテイナー
*/
const _quoteContainer = (
    {
        managerProfile,
        loadSidebarItems,
        requestQuote,
        requestQuoteLoading,
        requestsDetailQuoteDetailLoad,
        termsAndConditionsIsOpen,
        closeTermsAndConditionsModal,
        openTermsAndConditionsModal,
        downloadQuotePDF,
        requestDetail,
        pdfIsLoading,
        t
    }) => {
    // auth0 アクセストークン取得
    const {getAccessTokenSilently} = useAuth0();
    let {rid, qid} = useParams();
    // 利用契約一覧をAPIから取得する。
    useEffect(() => {
        // ログインしたユーザープロフィール取得したかどうかのチェック
        if (!empty(managerProfile)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    },
                );
                requestsDetailQuoteDetailLoad(access_token, managerProfile?.account?.uuid, rid, qid)
            })()
        }
    }, [rid, qid, getAccessTokenSilently, requestsDetailQuoteDetailLoad, managerProfile])
    // 利用契約一覧をAPIから取得する。
    useEffect(() => {
        loadSidebarItems(userSideBarLinks.navigation.requests)
    }, [loadSidebarItems])

    return (
        <RequestQuoteDetailView
            requestId={rid}
            downloadQuotePDF={downloadQuotePDF}
            quoteId={qid}
            pdfIsLoading={pdfIsLoading}
            managerProfile={managerProfile}
            getAccessTokenSilently={getAccessTokenSilently}
            closeTermsAndConditionsModal={closeTermsAndConditionsModal}
            openTermsAndConditionsModal={openTermsAndConditionsModal}
            termsAndConditionsIsOpen={termsAndConditionsIsOpen}
            requestQuoteLoading={requestQuoteLoading}
            requestQuote={requestQuote}
            requestDetail={requestDetail}
            t={t}
        />
    )
}

/**
 *
 * * Redux
 * * i18next Translations
 * を
 * UserView コンポーネントに追加する。
 */
const QuoteContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_quoteContainer)

export default QuoteContainer;

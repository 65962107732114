import {TOGGLE_TERMS_AND_CONDITIONS} from "../terminate/types";

/***
 * //////////////////////////////////////////////////////////////
 * TYPES (タイプ)
 * ----------------------
 *
 * タイプは、アクション、リデューサ、saga(または他の)ミドルウェアで使用するための定数です。
 * Typeはアクションの中で、ミドルウェアやリデューサーに命令を伝えるために使われます。
 * 全てのアクションタイプは、グループ化しやすいように以下の配列に格納され、選択されます。
 * //////////////////////////////////////////////////////////////
 */


export const SAVE_TRANSFER_FORM_INFORMATION = "SAVE_TRANSFER_FORM_INFORMATION";

export const GET_SERVICE_CONTRACT_TRANSFER_DATA = "GET_SERVICE_CONTRACT_TRANSFER_DATA"
export const GET_SERVICE_CONTRACT_TRANSFER_DATA_LOADING = "GET_SERVICE_CONTRACT_TRANSFER_DATA_LOADING"
export const GET_SERVICE_CONTRACT_TRANSFER_DATA_SUCCESS = "GET_SERVICE_CONTRACT_TRANSFER_DATA_SUCCESS"
export const GET_SERVICE_CONTRACT_TRANSFER_DATA_FAILED = "GET_SERVICE_CONTRACT_TRANSFER_DATA_FAILED"

export const SEND_TRANSFER_FORM_REQUEST = "SEND_TRANSFER_FORM_REQUEST";
export const SEND_TRANSFER_FORM_REQUEST_LOADING = "SEND_TRANSFER_FORM_REQUEST_LOADING";
export const SEND_TRANSFER_FORM_REQUEST_SUCCESS = "SEND_TRANSFER_FORM_REQUEST_SUCCESS";
export const SEND_TRANSFER_FORM_REQUEST_FAILURE = "SEND_TRANSFER_FORM_REQUEST_FAILURE";
export const TRANSFER_TOGGLE_TERMS_AND_CONDITIONS = "TRANSFER_TOGGLE_TERMS_AND_CONDITIONS"
export const TRANSFER_RESET_TERMS_AND_CONDITIONS = "TRANSFER_RESET_TERMS_AND_CONDITIONS"

const types = {
    SAVE_TRANSFER_FORM_INFORMATION,

    GET_SERVICE_CONTRACT_TRANSFER_DATA,
    GET_SERVICE_CONTRACT_TRANSFER_DATA_LOADING,
    GET_SERVICE_CONTRACT_TRANSFER_DATA_SUCCESS,
    GET_SERVICE_CONTRACT_TRANSFER_DATA_FAILED,

    SEND_TRANSFER_FORM_REQUEST,
    SEND_TRANSFER_FORM_REQUEST_LOADING,
    SEND_TRANSFER_FORM_REQUEST_SUCCESS,
    SEND_TRANSFER_FORM_REQUEST_FAILURE,
    TRANSFER_TOGGLE_TERMS_AND_CONDITIONS,
    TRANSFER_RESET_TERMS_AND_CONDITIONS
}

export default types;

import React from 'react';
import {Box, Breadcrumbs, Button, Container, Grid, makeStyles, Typography} from '@material-ui/core';
import Page from "../../../Page";
import CurrentPlanContentsContainer
    from "../../../../../containers/user/serviceContracts/contents/modify/CurrentPlanContentsContainer";
import AllPlanContainer from "../../../../../containers/user/serviceContracts/contents/modify/AllPlanContainer";
import ModifiedPlansContainer
    from "../../../../../containers/user/serviceContracts/contents/modify/ModifiedPlansContainer";
import {Link} from "react-router-dom";
import {empty} from "../../../../../../state/utils/Common";
import urlLinks from "../../../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json"
import EditableCheck from "../../../../../atoms/EditableCheck";
import NotEditableCheck from "../../../../../atoms/NotEditableCheck";

/**
 * /manager/notices コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(1)
    },
    button: {
        fontWeight: 900,
    }
}));
/**
 *
 * /noticesのメインコンポーネント
 * @param managerLoadNoticeList
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const ServiceContractsContentsModifyView = (
    {
        t,
        contractUpdateToConfirmPage,
        serviceContractContentData,
        serviceContractId,
        serviceContractContentAlterBasicPlan,
        serviceContractContentAlterPlan
    }) => {
    const classes = useStyles();
    return (
        <Page
            className={classes.root}
            title={t('service_contracts.contents.modify.title', {contract_name: !empty(serviceContractContentData?.service_contract?.service_account_name) ? serviceContractContentData?.service_contract?.service_account_name : ""})}
        >
            <Container maxWidth={false}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography style={{marginBottom: 6}}
                                    variant="h2">{t('service_contracts.contents.modify.title', {contract_name: !empty(serviceContractContentData?.service_contract?.service_account_name) ? serviceContractContentData?.service_contract?.service_account_name : ""})}</Typography>
                    </Grid>
                </Grid>
                <Grid container alignContent={"center"} alignItems={"center"}>
                    <Grid item xs={12} md={12}>
                        <Breadcrumbs separator={">"} aria-label="breadcrumb">
                            <Link to={urlLinks.navigation.top.index.href}>
                                <Typography color="textPrimary">{t('general.top')}</Typography>
                            </Link>
                            <Link to={urlLinks.navigation.service_contracts.index.href}>
                                <Typography color="textPrimary">{t('service_contracts.title')}</Typography>
                            </Link>
                            <Link
                                to={urlLinks.navigation.service_contracts.index.sub_links.contents.href.replace('{SCID}', serviceContractId)}>
                                <Typography
                                    color="textPrimary">{t('service_contracts.contents.breadcrumb')}</Typography>
                            </Link>
                            <Link
                                to={urlLinks.navigation.service_contracts.index.sub_links.contents.sub_links.modify.href.replace('{SCID}', serviceContractId)}>
                                <Typography
                                    color="textPrimary">{t('service_contracts.contents.modify.breadcrumb')}</Typography>
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item md={7} style={{paddingRight: 28, borderRight: "1px solid #CFCFCF"}}>
                        <CurrentPlanContentsContainer/>
                        <AllPlanContainer/>
                    </Grid>
                    <Grid item md={5} style={{paddingLeft: 28}}>
                        <ModifiedPlansContainer allowModification={true}/>
                        <Box style={{backgroundColor: "#EDE8DF", marginTop: 20, borderRadius: 3, padding: 13}}>
                            <Typography variant={"body1"}>
                                変更希望内容を弊社にて確認の上、担当者よりご案内をさせていただきます。 <br/>
                                内容やタイミングによっては、ご希望に添えない場合がございます。ご了承ください。
                            </Typography>
                        </Box>
                        <Box mt={4} style={{textAlign: "right"}}>

                            <Link to={'/service_contracts/' + serviceContractId + '/contents'}><Button
                                variant={"contained"} color={"secondary"}>{t('actions.cancel')}</Button></Link>
                            <EditableCheck>
                                <Button
                                    disabled={empty(serviceContractContentAlterBasicPlan) && empty(serviceContractContentAlterPlan)}
                                    onClick={() => contractUpdateToConfirmPage()} variant={"contained"}
                                    color={"primary"}
                                    style={{marginLeft: 20}}>{t('service_contracts.contents.modify.confirm_plan_change_button')}</Button>
                            </EditableCheck>
                            <NotEditableCheck>
                                <Button disabled={true} color={"primary"} variant={"contained"}
                                        style={{marginLeft: 20}}>{t('service_contracts.contents.modify.confirm_plan_change_button')}</Button>
                            </NotEditableCheck>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}

export default ServiceContractsContentsModifyView;

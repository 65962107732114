import React from 'react';
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    Container,
    Divider,
    Grid,
    LinearProgress,
    makeStyles,
    Typography
} from '@material-ui/core';
import Page from "../../../Page";
import clsx from "clsx";
import ManagerServiceChargeQuoteSegment from "./segments/ManagerServiceChargeQuoteSegment";
import ManagerCorrespondence from "./segments/ManagerCorrespondence";
import ManagerBasicInformation from "./segments/ManagerBasicInformation";
import MangerNotes from "./segments/MangerNotes";
import Auth0Utils from "../../../../../../state/utils/Auth0Utils";
import {GetAppRounded} from "@material-ui/icons";
import managerUrlLinks from "../../../../../../state/utils/data/sitemap/admin/sidebar_navigation_urls.json";
import {Link} from "react-router-dom";

/**
 * /manager/notices コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(4),
    },
    button: {
        fontWeight: 900,
    },
    strong: {
        fontWeight: "bold"
    }
}));
/**
 *
 * /noticesのメインコンポーネント
 * @param managerLoadNoticeList
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const ManagerRequestQuoteDetailView = (
    {
        requestId,
        quoteId,
        requestQuoteLoading,
        requestQuote,
        requestDetail,
        termsIsOpen,
        termsOpen,
        termsClose,
        managerPdfIsLoading,
        managerPdfError,
        managerDownloadQuotePDF,
        getAccessTokenSilently,
        t
    }) => {
    const classes = useStyles();
    return (
        <Page
            className={classes.root}
            title={t('requests.detail.quotes.title')}
        >
            {requestQuoteLoading !== true &&
            <Container maxWidth={false}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h2">{t('requests.detail.quotes.title')}</Typography>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Breadcrumbs separator={">"} style={{color: "#000000"}} aria-label="breadcrumb">
                            <Link to={managerUrlLinks.navigation.requests.index.href}>
                                <Typography color="textPrimary">{t('requests.manager.breadcrumb')}</Typography>
                            </Link>
                            <Link
                                to={managerUrlLinks.navigation.requests.index.sub_links.detail.href.replace('{RID}', requestDetail.uuid)}>
                                <Typography color="textPrimary">{t('requests.manager.detail.breadcrumb')}</Typography>
                            </Link>
                            <Link
                                to={managerUrlLinks.navigation.requests.index.sub_links.detail.sub_links.quote.href.replace('{RID}', requestDetail.uuid).replace('{QID}', requestQuote.uuid)}>
                            <Typography
                                color="textPrimary">{t('requests.manager.detail.quote.breadcrumb')}</Typography>
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item xs={6} md={6} style={{textAlign: "right", marginTop: "-10px"}}>
                        <Button type={"button"} variant={"text"} disabled={managerPdfIsLoading}
                                onClick={async () => {
                                    const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                                        () => {
                                            return getAccessTokenSilently();
                                        },
                                    );
                                    await managerDownloadQuotePDF(access_token, requestId, quoteId, requestQuote.number);
                                }}
                                style={{
                                    border: "none",
                                    fontSize: 11
                                }}><GetAppRounded/> {t('requests.detail.quotes.download_document')}</Button>

                    </Grid>
                    <Grid container alignItems={"flex-end"} alignContent={"flex-end"} justify={"flex-end"}>
                        <Grid item>
                            {managerPdfIsLoading === true &&
                            <LinearProgress
                                style={{width: 132, marginRight: 10, marginTop: "-10px", float: "right"}}/>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Box mt={1}>
                    <Card>
                        <Divider/>
                        <ManagerCorrespondence requestDetail={requestDetail} requestId={requestId} t={t}
                                               quoteId={quoteId} requestQuoteLoading={requestQuoteLoading}/>
                        <Divider/>
                        <ManagerBasicInformation requestQuoteLoading={requestQuoteLoading}
                                                 requestQuote={requestQuote}
                                                 t={t} quoteId={quoteId} requestId={requestId}/>
                        <Divider/>
                        <ManagerServiceChargeQuoteSegment requestQuote={requestQuote} requestDetail={requestDetail}
                                                          t={t}/>
                        <Divider/>
                        <MangerNotes
                            requestId={requestId}
                            t={t}
                            requestQuote={requestQuote}
                            requestDetail={requestDetail}
                            requestQuoteLoading={requestQuoteLoading}
                            quoteId={quoteId}
                            termsAndConditionsIsOpen={termsIsOpen}
                            closeTermsAndConditionsModal={termsClose}
                            openTermsAndConditionsModal={termsOpen}
                        />
                        <Divider/>
                        {/*<Box className={clsx(classes.root)} marginY={1} alignContent="middle">*/}
                        {/*    <Grid container justify="center" alignContent={"center"} alignItems={"center"}>*/}
                        {/*        <Typography*/}
                        {/*            variant="body1">{t('requests.detail.request_cancel_final_date')}：{requestDetail.cancel_limit_at}</Typography>*/}
                        {/*    </Grid>*/}
                        {/*</Box>*/}
                    </Card>
                </Box>
            </Container>
            }
        </Page>
    );
}

export default ManagerRequestQuoteDetailView;

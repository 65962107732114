import React from 'react';
import {Box, Breadcrumbs, Container, Grid, Typography} from '@material-ui/core';
import Page from "../../Page";
import AccountUserList from "./list/AccountUserList";
import {Link} from "react-router-dom";
import globalTheme from "../../../../theme/globalTheme";
import managerUrlLinks from "../../../../../state/utils/data/sitemap/admin/sidebar_navigation_urls.json";

/**
 * アカウントのユーザー一覧
 * @param {Object} classes
 * @param {boolean} managerAccountUsersLoading - ロード中かどうか
 * @param {Object} managerAccountActiveUsers - アカウント ユーザーリスト データ
 * @param managerDetailAccountData
 * @param {*} t - 翻訳ファイル
 * @returns {JSX.Element}
 * @constructor
 */
const ManagerAccountUserView = (
    {
        classes,
        managerAccountUsersLoading,
        managerAccountActiveUsers,
        managerDetailAccountData,
        accountId,
        t
    }) => {
    const globalClass = globalTheme._default();
    return (
        <Page
            className={classes.root}
            title={t('accounts.title')}
        >
            <Container maxWidth={false}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                        <Typography
                            variant="h2">{t('accounts.users.title', {account_name: managerDetailAccountData?.name})}</Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Breadcrumbs aria-label="breadcrumb" color={"#000000"} separator={">"} style={{fontSize: 11}}>
                            <Link color="inherit" className={globalClass.a} style={{textDecoration: "none"}}
                                  to={managerUrlLinks.navigation.accounts.index.href}>
                                {t('accounts.breadcrumb')}
                            </Link>
                            <Link color="inherit" className={globalClass.a} style={{textDecoration: "none"}}
                                  to={managerUrlLinks.navigation.accounts.index.sub_links.users.href.replace('{UID}', accountId)}>
                                {t('accounts.users.breadcrumb')}
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Box mt={3}>
                    <AccountUserList
                        managerAccountActiveUsers={managerAccountActiveUsers}
                        managerAccountUsersLoading={managerAccountUsersLoading}
                        managerDetailAccountData={managerDetailAccountData}
                        t={t}
                    />
                </Box>
            </Container>
        </Page>
    );
};

export default ManagerAccountUserView;

import clsx from "clsx";
import {Box, Card, Grid, makeStyles, Typography,} from "@material-ui/core";
import React from "react";
import {empty} from "../../../../../state/utils/Common";
import Loading from "../../../../atoms/Loading";
import {commonStyles} from "../../../../theme/user/styles/common";
import globalTheme from "../../../../theme/globalTheme";
import AutoSizer from "react-virtualized-auto-sizer";
import {FixedSizeList} from "react-window";
import {UsersListRow} from "./UserListRow";
import Auth0Utils from "../../../../../state/utils/Auth0Utils";
import {useAuth0} from "@auth0/auth0-react";

/**
 * コンポーネントスタイル
 * @type {(props?: any) => ClassNameMap<"paper"|"root">}
 */
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        border: "1px #CCCCCC solid",
        boxShadow: "none"
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

/**
 * /user コンポーネント (user/user 一覧)
 *
 * @param {Object} className - 親からのスタイル
 * @param {boolean} loading - 一覧ロードの状態。
 * @param account_id
 * @param {Object} users - 取得したユーザーデータ
 * @param {*} viewRegisterToggle - 新規ユーザーモーダル表示・非表示状態。
 * @param {*} viewDeleteToggle - ユーザー削除モーダル表示・非表示状態。
 * @param {String} loginUserID - ログインしているユーザーのID
 * @param unlockAuth0User
 * @param resendAuth0VerifyMail
 * @param {*} t - Translation object - 翻訳ファイル
 * @returns {JSX.Element}
 * @constructor
 */
const UsersList = (
    {
        className,
        loading,
        account_id,
        users,
        viewRegisterToggle,
        viewDeleteToggle,
        loginUserID,
        unlockAuth0User,
        resendAuth0VerifyMail,
        t
    }) => {
    // アプリケーションテーマ
    const classes = useStyles();
    const common = commonStyles()
    const _globalTheme = globalTheme._default();

    // ユーザー一覧の編集選択アクション
    function handleEditClick(id) {
        viewRegisterToggle(id)
    }

    // ユーザー一覧の削除選択
    function handleDeleteClick(id) {
        viewDeleteToggle(id)
    }

    // auth0 アクショントークンを取得
    const {getAccessTokenSilently} = useAuth0();

    return (
        <Card
            className={clsx(classes.root, className)}
        >
            <Box p={2}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                            style={{fontWeight: 900}}
                        >
                            {t('users.list')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                            style={{'textAlign': 'right'}}
                        >
                            {t('general.list_amount', {amount: !empty(users) ? users.length : 0})}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Grid container className={common.tableHeader} style={{paddingRight: 0}}>
                <Grid item xs={3}>
                    <Typography style={{fontWeight: "bold"}}>
                        {t('users.list_headers.user_name')}
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography style={{fontWeight: "bold"}}>
                        {t('users.list_headers.mail_address')}
                    </Typography>
                </Grid>
                <Grid item xs={1} style={{textAlign:"center"}}>
                    <Typography style={{fontWeight: "bold"}}>
                        {t('users.list_headers.edit')}
                    </Typography>
                </Grid>
                <Grid item xs={1} style={{textAlign: "center"}}>
                    <Typography style={{fontWeight: "bold"}}>
                        {t('users.list_headers.password_lock')}
                    </Typography>
                </Grid>
                <Grid item xs={1} style={{textAlign: "center"}}>
                    <Typography style={{fontWeight: "bold"}}>
                        {t('users.list_headers.authentication_mail')}
                    </Typography>
                </Grid>
                <Grid item xs={1} style={{textAlign: "center"}}>
                    <Typography style={{fontWeight: "bold"}}>
                        {t('users.list_headers.delete')}
                    </Typography>
                </Grid>
            </Grid>
            {loading === true ?
                <Box className={_globalTheme.list} display="flex" alignItems="center">
                    <Grid container
                          direction="row"
                          justify="center"
                          alignItems="center"
                          data-testid="loading"
                    >
                        <Grid item xs={12}>
                            <Loading/>
                        </Grid>
                    </Grid>
                </Box>
                : !empty(users) &&
                <Box className={_globalTheme.list} data-testid="has-users">
                    <AutoSizer>
                        {({height, width}) => (
                            <FixedSizeList
                                width={width}
                                height={height}
                                itemCount={users.length}
                                itemSize={45}
                                itemData={{
                                    users: users,
                                    loginUserID: loginUserID,
                                    t: t,
                                    handleEditClick: (id) => handleEditClick(id),
                                    handleDeleteClick: (id) => handleDeleteClick(id),
                                    unlockAuth0User: (async (userId) => {
                                            const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                                                () => {
                                                    return getAccessTokenSilently();
                                                },
                                            );
                                            unlockAuth0User(access_token, userId, account_id)
                                        }),
                                    resendAuth0VerifyMail: (async (userId) => {
                                        const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                                            () => {
                                                return getAccessTokenSilently();
                                            },
                                        );
                                        resendAuth0VerifyMail(access_token, userId, account_id)
                                    })
                                }}
                            >
                                {UsersListRow}
                            </FixedSizeList>
                        )}
                    </AutoSizer>
                </Box>
            }
        </Card>
    );
};

export default UsersList;

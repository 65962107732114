/***
 * /////////////////////////////////////////////////////////////////
 * CONSTANTS (定数）
 * ----------------------------
 *
 * TYPEではないrequestsに関連するすべての定数について
 * 例を挙げると
 * API エンドポイント
 * /////////////////////////////////////////////////////////////////
 *
 */
export const GET_REQUESTS_API_PATH = "/accounts/{UID}/requests/";
export const GET_REQUEST_DETAIL_API_PATH = "/accounts/{UID}/requests/{RID}"
export const GET_REQUEST_DETAIL_QUOTE_DETAIL_PATH = "/accounts/{UID}/requests/{RID}/quotes/{QID}"
export const GET_REQUEST_DETAIL_QUOTE_DETAIL_PATH_PDF_DOWNLOAD = "/accounts/{UID}/requests/{RID}/quotes/{QID}/pdf"
export const PUT_REQUEST_EBIS_SETTING_INFORMATION = "/accounts/{UID}/requests/{RID}/quotes/{QID}/apply"
export const PUT_REQUEST_DETAIL_CANCEL = "/accounts/{UID}/requests/{RID}/cancel"
export const GET_CONFIRM_LOG_ID = "/accounts/{UID}/requests/{RID}/quotes/{QID}/apply/validate"

/***
 * //////////////////////////////////////////////////////////////
 * TYPES (タイプ)
 * ----------------------
 *
 * タイプは、アクション、リデューサ、saga(または他の)ミドルウェアで使用するための定数です。
 * Typeはアクションの中で、ミドルウェアやリデューサーに命令を伝えるために使われます。
 * 全てのアクションタイプは、グループ化しやすいように以下の配列に格納され、選択されます。
 * //////////////////////////////////////////////////////////////
 */

export const SAVE_TERMINATE_FORM_DATA = "SAVE_TERMINATE_FORM_DATA"
export const TOGGLE_TERMS_AND_CONDITIONS = "TOGGLE_TERMS_AND_CONDITIONS"
export const RESET_CANCELLATION_FORM = "RESET_CANCELLATION_FORM"

export const GET_SERVICE_CONTRACT_CANCEL_REASONS = "GET_SERVICE_CONTRACT_CANCEL_REASONS"
export const GET_SERVICE_CONTRACT_CANCEL_REASONS_LOADING = "GET_SERVICE_CONTRACT_CANCEL_REASONS_LOADING"
export const GET_SERVICE_CONTRACT_CANCEL_REASONS_SUCCESS = "GET_SERVICE_CONTRACT_CANCEL_REASONS_SUCCESS"
export const GET_SERVICE_CONTRACT_CANCEL_REASONS_FAILED = "GET_SERVICE_CONTRACT_CANCEL_REASONS_FAILED"

export const SEND_SERVICE_CONTRACT_CANCEL_REQUEST = "SEND_SERVICE_CONTRACT_CANCEL_REQUEST"
export const SEND_SERVICE_CONTRACT_CANCEL_REQUEST_LOADING = "SEND_SERVICE_CONTRACT_CANCEL_REQUEST_LOADING"
export const SEND_SERVICE_CONTRACT_CANCEL_REQUEST_SUCCESS = "SEND_SERVICE_CONTRACT_CANCEL_REQUEST_SUCCESS"
export const SEND_SERVICE_CONTRACT_CANCEL_REQUEST_FAILED = "SEND_SERVICE_CONTRACT_CANCEL_REQUEST_FAILED"
export const RESET_TERMS_AND_CONDITIONS = "RESET_TERMS_AND_CONDITIONS"
const types = {
    SAVE_TERMINATE_FORM_DATA,
    TOGGLE_TERMS_AND_CONDITIONS,
    RESET_CANCELLATION_FORM,

    GET_SERVICE_CONTRACT_CANCEL_REASONS,
    GET_SERVICE_CONTRACT_CANCEL_REASONS_LOADING,
    GET_SERVICE_CONTRACT_CANCEL_REASONS_SUCCESS,
    GET_SERVICE_CONTRACT_CANCEL_REASONS_FAILED,

    SEND_SERVICE_CONTRACT_CANCEL_REQUEST,
    SEND_SERVICE_CONTRACT_CANCEL_REQUEST_LOADING,
    SEND_SERVICE_CONTRACT_CANCEL_REQUEST_SUCCESS,
    SEND_SERVICE_CONTRACT_CANCEL_REQUEST_FAILED,
    RESET_TERMS_AND_CONDITIONS,
}
export default types;

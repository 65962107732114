import React from 'react';
import {Box, Breadcrumbs, Button, Card, Container, Divider, Grid, makeStyles, Typography} from '@material-ui/core';
import Page from "../../Page";
import clsx from "clsx";
import {empty} from "../../../../../state/utils/Common";
import ManagerEstimatesListContainer
    from "../../../../containers/manager/requests/detail/ManagerEstimatesListContainer";
import requestTypes from "../../../../../state/utils/data/application_request_type_flat.json"
import requestStatus from "../../../../../state/utils/data/application_request_status_flat.json"
import RestrictSales from "../../../../atoms/RestrictSales";
import {ConfirmationModal} from "../../../../atoms/ConfirmationModal";
import {AlertModal} from "../../../../atoms/AlertModal";
import managerUrlLinks from "../../../../../state/utils/data/sitemap/admin/sidebar_navigation_urls.json";
import {Link} from "react-router-dom";

/**
 * /manager/notices コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(4),
    },
    button: {
        fontWeight: 900,
    },
    strong: {
        fontWeight: "bold"
    }
}));
/**
 *
 * /noticesのメインコンポーネント
 * @param managerLoadNoticeList
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const ManagerRequestDetailView = (
    {
        requestDetailLoading,
        requestDetail,

        isManagerCancelConfirmationOpen,
        toggleCancelConfirmation,

        managerCancelRequestLoading,
        managerCancelRequestFailed,
        cancelRequestSend,
        isManagerCancelConfirmedModalOpen,
        history,

        isManagerAcceptTerminatedModalOpen,
        isManagerAcceptTerminateConfirmationOpen,
        toggleApproveTerminateRequest,
        approveTerminateRequest,

        managerTerminateRequestLoading,
        managerTerminateRequestError,

        related_quotes,

        t
    }) => {
    const classes = useStyles();
    return (
        <Page
            className={classes.root}
            title={t('requests.title')}
        >
            {requestDetailLoading !== true &&
            <Container maxWidth={false}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h2">{t('requests.detail.title')}</Typography>
                    </Grid>
                </Grid>
                <Grid container alignContent={"center"} alignItems={"center"}>
                    <Grid item xs={12} md={12} style={{marginTop: 7, marginBottom: 15}}>
                        <Breadcrumbs separator={">"} style={{color: "#000000"}} aria-label="breadcrumb">
                            <Link to={managerUrlLinks.navigation.requests.index.href}>
                                <Typography color="textPrimary">{t('requests.manager.breadcrumb')}</Typography>
                            </Link>
                            <Link
                                to={managerUrlLinks.navigation.requests.index.sub_links.detail.href.replace('{RID}', requestDetail.uuid)}>
                                <Typography color="textPrimary">{t('requests.manager.detail.breadcrumb')}</Typography>
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Box mt={1}>
                    <Card>
                        <Box paddingY={2} paddingX={2}>
                            <Typography variant="h4"> {t('requests.detail.information')}</Typography>
                            <Grid container style={{marginTop: 26}}>
                                <Grid item xs={2} xl={1}>
                                    <Typography variant="body1"
                                                className={classes.strong}>{t('requests.detail.request_manager')}</Typography>
                                </Grid>
                                <Grid item xs={10} xl={11}>
                                    <Typography variant="body1">{requestDetail.application_user_name}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container style={{marginTop: 15}}>
                                <Grid item xs={2} xl={1}>
                                    <Typography variant="body1"
                                                className={classes.strong}>{t('requests.detail.request_date')}</Typography>
                                </Grid>
                                <Grid item xs={10} xl={11}>
                                    <Typography variant="body1">{requestDetail.application_at}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container style={{marginTop: 15}}>
                                <Grid item xs={2} xl={1}>
                                    <Typography variant="body1"
                                                className={classes.strong}>{t('requests.detail.request_category')}</Typography>
                                </Grid>
                                <Grid item xs={10} xl={11}>
                                    <Typography variant="body1">{requestDetail.type_name}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container style={{marginTop: 15}}>
                                <Grid item xs={2} xl={1}>
                                    <Typography variant="body1"
                                                className={classes.strong}>{t('requests.detail.request_status')}</Typography>
                                </Grid>
                                <Grid item xs={10} xl={11}>
                                    <Typography variant="body1">{requestDetail.status_name}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container style={{marginTop: 15}}>
                                <Grid item xs={2} xl={1}>
                                    <Typography variant="body1"
                                                className={classes.strong}>{t('requests.detail.request_completed_date')}</Typography>
                                </Grid>
                                <Grid item xs={10} xl={11}>
                                    <Typography variant="body1">{requestDetail.application_complete_at}</Typography>
                                </Grid>
                            </Grid>
                        </Box>

                        <Divider/>

                        <Box paddingY={2} paddingX={2}>
                            <Typography variant="h4"> {t('requests.detail.detail')}</Typography>

                            {!empty(requestDetail.ebis_log_id) &&
                            <Grid container style={{marginTop: 26}}>
                                {/** アカウント情報 */}
                                <Grid item xs={2} xl={1}>
                                    <Typography variant="body1"
                                                className={classes.strong}>{t('requests.detail.related_account')}</Typography>
                                </Grid>
                                <Grid item xs={10} xl={11}>
                                    <Typography variant="body1">
                                        {requestDetail.ebis_log_id}&nbsp;&nbsp;
                                        {requestDetail.ebis_client_name}&nbsp;&nbsp;
                                        {/** 解約でない場合・自動更新の場合・requestDetail.renewal_ymが設定されている場合 */}
                                        {t('requests.detail.next_update')} {requestDetail.renewal_at}
                                    </Typography>
                                </Grid>
                            </Grid>
                            }
                            <Grid container style={{marginTop: 15}}>
                                {/** サービス名 */}
                                <Grid item xs={2} xl={1}>
                                    <Typography variant="body1"
                                                className={classes.strong}>{t('requests.detail.service_name')}</Typography>
                                </Grid>
                                <Grid item xs={10} xl={11}>
                                    <Typography variant="body1">
                                        {requestDetail.service_names.map((data) => {
                                            return (<p>{data}</p>)
                                        })}
                                    </Typography>
                                </Grid>
                            </Grid>

                            {/*{(requestDetail.type === requestTypes.CHANGE || requestDetail.type === requestTypes.CANCEL) &&*/}
                            {/*    /!***/}
                            {/*    <Grid container style={{marginTop: 15}}>*/}
                            {/*        <Grid item xs={2} xl={1}>*/}
                            {/*            <Typography variant="body1"*/}
                            {/*                        className={classes.strong}>{t('requests.detail.service_detail')}</Typography>*/}
                            {/*        </Grid>*/}
                            {/*        <Grid item xs={10} xl={11}>*/}
                            {/*            <Typography variant="body1">????</Typography>*/}
                            {/*        </Grid>*/}
                            {/*    </Grid>*/}
                            {/*    *!/*/}
                            {/*}*/}

                            {(!empty(requestDetail.reflect_at)) &&
                            <Grid container style={{marginTop: 15}}>
                                {/** 反映日 */}
                                <Grid item xs={2} xl={1}>
                                    <Typography variant="body1"
                                                className={classes.strong}>{t('requests.detail.responded_date')}</Typography>
                                </Grid>
                                <Grid item xs={10} xl={11}>
                                    <Typography variant="body1">{requestDetail.reflect_at}</Typography>
                                </Grid>
                            </Grid>
                            }
                            <Grid container style={{marginTop: 15}}>
                                {/** 備考 */}
                                <Grid item xs={2} xl={1}>
                                    <Typography variant="body1"
                                                className={classes.strong}>{t('requests.detail.note')}</Typography>
                                </Grid>
                                <Grid item xs={10} xl={11}>
                                    <Typography variant="body1" style={{whiteSpace: "pre-wrap"}}>{requestDetail?.inquiry}</Typography>
                                </Grid>
                            </Grid>

                            {(requestDetail.type === requestTypes.CANCEL) &&
                            <Grid container style={{marginTop: 15}}>
                                {/** 解約希望日 */}
                                <Grid item xs={2} xl={1}>
                                    <Typography variant="body1"
                                                className={classes.strong}>{t('requests.detail.intended_cancel_date')}</Typography>
                                </Grid>
                                <Grid item xs={10} xl={11}>
                                    <Typography variant="body1">{requestDetail?.cancel_prefferd_ym}</Typography>
                                </Grid>
                            </Grid>
                            }
                            {(requestDetail.type === requestTypes.CANCEL) &&
                            <Grid container style={{marginTop: 15}}>
                                {/** 解約理由１ */}
                                <Grid item xs={2} xl={1}>
                                    <Typography variant="body1"
                                                className={classes.strong}>{t('requests.detail.cancel_reason')}</Typography>
                                </Grid>
                                <Grid item xs={10} xl={11}>
                                    {!empty(requestDetail?.cancel_reasons1) && requestDetail?.cancel_reasons1.map((value) => {
                                        return (
                                            <Box paddingBottom={.5}>
                                                <Typography variant="body1">{value}</Typography>
                                            </Box>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                            }
                            {(requestDetail.type === requestTypes.CANCEL) &&
                            <Grid container style={{marginTop: 15}}>
                                {/** 解約理由２ */}
                                <Grid item xs={2} xl={1}>
                                    <Typography variant="body1"
                                                className={classes.strong}>{t('requests.detail.cancel_expected_functionality_reason')}</Typography>
                                </Grid>
                                <Grid item xs={10} xl={11}>
                                    <Typography variant="body1"
                                                style={{whiteSpace: "pre-line"}}>{requestDetail?.cancel_reason2}</Typography>
                                </Grid>
                            </Grid>
                            }
                            {(requestDetail.type === requestTypes.CANCEL) &&
                            <Grid container style={{marginTop: 15}}>
                                {/** 解約理由３ */}
                                <Grid item xs={2} xl={1}>
                                    <Typography variant="body1"
                                                className={classes.strong}>{t('requests.detail.cancel_next_tool')}</Typography>
                                </Grid>
                                <Grid item xs={10} xl={11}>
                                    <Typography variant="body1"
                                                style={{whiteSpace: "pre-line"}}>{requestDetail?.cancel_reason3}</Typography>
                                </Grid>
                            </Grid>
                            }
                        </Box>

                        {requestDetail.type !== requestTypes.CANCEL && !empty(related_quotes) &&
                        <>
                            <Divider/>
                            <Box paddingY={2} paddingX={2}>
                                <Grid container>
                                    <Grid item sm={6}>
                                        <Typography variant="h4">{t('requests.detail.quote_list')}</Typography>
                                    </Grid>
                                    <Grid item sm={6}>
                                        <Typography variant={"body1"} style={{textAlign: "right"}}>
                                            {t('general.list_amount', {amount: requestDetail?.related_quotes?.length})}
                                        </Typography>
                                    </Grid>
                                </Grid>

                            </Box>
                            <ManagerEstimatesListContainer requestUUID={requestDetail.uuid}/>
                        </>
                        }
                        <Box className={clsx(classes.root)} paddingY={2} paddingX={2} alignContent="middle">
                            <Grid container justify="center" alignContent={"center"} alignItems={"center"}>
                                {requestDetail.cancel_limit_at &&
                                <Typography
                                    variant="body1">{t('requests.detail.request_cancel_final_date')}：{requestDetail.cancel_limit_at}</Typography>
                                }
                                {(requestDetail.status === requestStatus.APPLIED
                                    && requestDetail.cancel_limit_at) &&
                                <RestrictSales>
                                    <Button variant={"outlined"}
                                            onClick={() => toggleCancelConfirmation(true)}
                                            style={{marginLeft: 15, fontWeight: 900}}
                                            color={"primary"}>
                                        {t('requests.detail.request_cancel')}
                                    </Button>
                                    <ConfirmationModal
                                        isOpen={isManagerCancelConfirmationOpen}
                                        setClose={() => toggleCancelConfirmation(false)}
                                        confirmationTitle={t('requests.manager.detail.quote.detailed.confirm_cancel_message')}
                                        cancelText={t('actions.cancel')}
                                        loadingText={t('actions.sending')}
                                        buttonText={t('actions.ok')}
                                        isLoading={managerCancelRequestLoading}
                                        error={managerCancelRequestFailed}
                                        id={requestDetail.uuid}
                                        event={(id, access_token, uuid) => cancelRequestSend(access_token, id)}
                                    />
                                    <AlertModal
                                        isOpen={isManagerCancelConfirmedModalOpen}
                                        event={() => history.push('/manager/requests')}
                                        buttonText={t('actions.ok')}
                                        confirmationTitle={t('requests.manager.detail.quote.detailed.confirmed_cancel_message')}
                                    />
                                </RestrictSales>
                                }

                                {(requestDetail.type === requestTypes.CANCEL && requestDetail.status == requestStatus.REQUEST) &&
                                <RestrictSales>
                                    <>
                                        <Button style={{marginLeft: 20}}
                                                onClick={() => toggleApproveTerminateRequest(true)}
                                                variant={"contained"}
                                                color={"primary"}>
                                            解約受理
                                        </Button>
                                        <ConfirmationModal
                                            isOpen={isManagerAcceptTerminateConfirmationOpen}
                                            setClose={() => toggleApproveTerminateRequest(false)}
                                            confirmationTitle={t('requests.manager.detail.quote.detailed.confirm_terminate_message')}
                                            cancelText={t('actions.cancel')}
                                            buttonText={t('actions.ok')}
                                            loadingText={t('actions.sending')}
                                            isLoading={managerTerminateRequestLoading}
                                            error={managerTerminateRequestError}
                                            id={requestDetail.uuid}
                                            event={(id, access_token, uuid) => approveTerminateRequest(access_token, id)}
                                        />
                                        <AlertModal
                                            isOpen={isManagerAcceptTerminatedModalOpen}
                                            event={() => history.push('/manager/requests')}
                                            buttonText={t('actions.ok')}
                                            confirmationTitle={t('requests.manager.detail.quote.detailed.confirmed_terminate_message')}
                                        />
                                    </>
                                </RestrictSales>
                                }
                            </Grid>
                        </Box>

                    </Card>
                </Box>
            </Container>
            }
        </Page>
    );
}

export default ManagerRequestDetailView;

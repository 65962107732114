import {addressListOperations} from "../../../../state/ducks/user/addresses";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import AddressView from "../../../components/user/addresses/Page";
import {rootOperations} from "../../../../state/ducks/root";
import {useAuth0} from "@auth0/auth0-react";
import {useParams} from "react-router";
import React, {useEffect} from "react";
import userSideBarLinks from "../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json";
import {empty} from "../../../../state/utils/Common";
import Auth0Utils from "../../../../state/utils/Auth0Utils";
import ContactView from "../../../components/user/serviceContracts/Contact";
import addressSideBarLinks from "../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json";

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        reLoad: state.root.reLoad,
        profile: state.root.managerProfile,

        loading: state.address.loading,
        addresses: state.address.addressList,
        isRegisterView: state.address.isRegisterView,
        addressRegistrationLoad: state.address.registerLoading,
        registerError: state.address.registerError,
        error: state.address.error,
        addressList_CurrentPage: state.address.addressList_CurrentPage,
        activeProfile: state.address.activeProfile,
        deleteView: state.address.deleteView,
        deleteLoading: state.address.deleteLoading,
        deleteError: state.address.deleteError,
        deleteErrorModalView: state.address.deleteErrorModalView
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    loadSidebarItems: rootOperations.loadSidebarItems,

    loadAddressList: addressListOperations.loadAddressList,
    toggleRegisterView: addressListOperations.toggleRegister,
    registerAddress: addressListOperations.registerAddress,
    changePage: addressListOperations.addressChangePage,
    updateAddress: addressListOperations.updateAddress,
    viewAddressDeleteToggle: addressListOperations.viewAddressDeleteToggle,
    deleteAddresses: addressListOperations.deleteAddresses,
    confirmDeleteAddressDoNotDelete: addressListOperations.confirmDeleteAddressDoNotDelete
}

/*
利用契約 -> 連絡先コンテイナ
 */
const _addressContainer = (
    {
        loadAddressList,
        loading,
        activeProfile,
        profile,
        updateAddress,
        isRegisterView,
        toggleRegisterView,
        addressRegistrationLoad,
        registerError,
        registerAddress,
        searchLoad,
        searchUserResult,
        reLoad,
        searchUser,
        addresses,
        addressList_CurrentPage,
        deleteView,
        deleteLoading,
        deleteError,
        viewAddressDeleteToggle,
        deleteAddresses,
        changePage,
        t,
        deleteErrorModalView,
        confirmDeleteAddressDoNotDelete,
        loadSidebarItems
    }) => {
    // auth0 アクセストークン取得
    const {getAccessTokenSilently} = useAuth0();
    // 利用契約一覧をAPIから取得する。
    //アプリケーションテーマを取得
    useEffect(() => {
        if (reLoad && !empty(profile)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    },
                );
                loadAddressList(access_token, profile?.account?.uuid);
            })();
        }
    }, [loadAddressList, getAccessTokenSilently, profile, reLoad])

    /**
     * ナビ項目のロードエベント
     */
    useEffect(() => {
        loadSidebarItems(addressSideBarLinks.navigation.address);
    }, [loadSidebarItems])


    return (
        <AddressView
            loading={loading}
            activeProfile={activeProfile}
            profile={profile}
            updateAddress={updateAddress}
            isRegisterView={isRegisterView}
            toggleRegisterView={toggleRegisterView}
            addressRegistrationLoad={addressRegistrationLoad}
            registerError={registerError}
            registerAddress={registerAddress}
            searchLoad={searchLoad}
            searchUserResult={searchUserResult}
            searchUser={searchUser}
            addresses={addresses}
            addressList_CurrentPage={addressList_CurrentPage}
            deleteView={deleteView}
            deleteLoading={deleteLoading}
            deleteError={deleteError}
            viewAddressDeleteToggle={viewAddressDeleteToggle}
            deleteAddresses={deleteAddresses}
            changePage={changePage}
            t={t}
            deleteErrorModalView={deleteErrorModalView}
            confirmDeleteAddressDoNotDelete={confirmDeleteAddressDoNotDelete}
        />
    )
}

/**
 * Redux
 * i18next Translations
 * を
 * _contentsContainer コンポーネントに追加する。
 */
const AddressContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_addressContainer)

export default AddressContainer;


/***
 * //////////////////////////////////////////////////////////////
 * TYPES (タイプ)
 * ----------------------
 *
 * タイプは、アクション、リデューサ、saga(または他の)ミドルウェアで使用するための定数です。
 * Typeはアクションの中で、ミドルウェアやリデューサーに命令を伝えるために使われます。
 * 全てのアクションタイプは、グループ化しやすいように以下の配列に格納され、選択されます。
 * //////////////////////////////////////////////////////////////
 */

export const REQUEST_SERVICE_CONTRACT = 'REQUEST_SERVICE_CONTRACTS'
export const REQUEST_SERVICE_CONTRACTS_LOADING = 'REQUEST_SERVICE_CONTRACTS_LOADING'
export const REQUEST_SERVICE_CONTRACTS_SUCCESS = 'REQUEST_SERVICE_CONTRACTS_SUCCESS'
export const REQUEST_SERVICE_CONTRACTS_FAILURE = 'REQUEST_SERVICE_CONTRACTS_FAILURE'

export const SERVICE_CONTRACTS_CHANGE_PAGE = 'SERVICE_CONTRACTS_CHANGE_PAGE'

export const EXTRACT_SERVICE_CONTRACT_DETAIL = 'EXTRACT_SERVICE_CONTRACT_DETAIL'

export const TOGGLE_SOLD_TO_MODAL = 'TOGGLE_SOLD_TO_MODAL'
export const TOGGLE_BILLED_TO_MODAL = 'TOGGLE_BILLED_TO_MODAL'

export const REQUEST_CONTACT_SERVICE_CONTRACT = "REQUEST_CONTACT_SERVICE_CONTRACT";
export const REQUEST_CONTACT_SERVICE_CONTRACT_LOADING = "REQUEST_CONTACT_SERVICE_CONTRACT_LOADING";
export const REQUEST_CONTACT_SERVICE_CONTRACT_SUCCESS = "REQUEST_CONTACT_SERVICE_CONTRACT_SUCCESS";
export const REQUEST_CONTACT_SERVICE_CONTRACT_FAILED = "REQUEST_CONTACT_SERVICE_CONTRACT_FAILED";

export const REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT = "REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT";
export const REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_LOADING = "REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_LOADING";
export const REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_SUCCESS = "REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_SUCCESS";
export const REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_FAILED = "REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_FAILED";

export const REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_ADDRESS = "REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_ADDRESS";
export const REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_ADDRESS_LOADING = "REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_ADDRESS_LOADING";
export const REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_ADDRESS_SUCCESS = "REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_ADDRESS_SUCCESS";
export const REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_ADDRESS_FAILED = "REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_ADDRESS_FAILED";
export const REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_ADDRESS_TOGGLE_SOLD_TO = "REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_ADDRESS_TOGGLE_SOLD_TO";
export const REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_ADDRESS_TOGGLE_BILLED_TO = "REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_ADDRESS_TOGGLE_BILLED_TO";
export const REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_ADDRESS_SELECT = "REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_ADDRESS_SELECT";

export const UPDATE_SERVICE_CONTRACT_CONTACT_REQUEST = "UPDATE_SERVICE_CONTRACT_CONTACT_REQUEST"
export const UPDATE_SERVICE_CONTRACT_CONTACT_LOADING = "UPDATE_SERVICE_CONTRACT_CONTACT_LOADING"
export const UPDATE_SERVICE_CONTRACT_CONTACT_SUCCESS = "UPDATE_SERVICE_CONTRACT_CONTACT_SUCCESS"
export const UPDATE_SERVICE_CONTRACT_CONTACT_FAILED = "UPDATE_SERVICE_CONTRACT_CONTACT_FAILED"

export const UPDATE_SERVICE_CONTRACT_CONTACT_ADDRESS_REQUEST = "UPDATE_SERVICE_CONTRACT_CONTACT_ADDRESS_REQUEST"
export const UPDATE_SERVICE_CONTRACT_CONTACT_ADDRESS_LOADING = "UPDATE_SERVICE_CONTRACT_CONTACT_ADDRESS_LOADING"
export const UPDATE_SERVICE_CONTRACT_CONTACT_ADDRESS_SUCCESS = "UPDATE_SERVICE_CONTRACT_CONTACT_ADDRESS_SUCCESS"
export const UPDATE_SERVICE_CONTRACT_CONTACT_ADDRESS_FAILED = "UPDATE_SERVICE_CONTRACT_CONTACT_ADDRESS_FAILED"

export const SAVE_CONTRACT_NAME_TO_SIDEBAR_LINK = "SAVE_CONTRACT_NAME_TO_SIDEBAR_LINK";

const types = {
    REQUEST_SERVICE_CONTRACT,
    REQUEST_SERVICE_CONTRACTS_LOADING,
    REQUEST_SERVICE_CONTRACTS_SUCCESS,
    REQUEST_SERVICE_CONTRACTS_FAILURE,

    SERVICE_CONTRACTS_CHANGE_PAGE,

    EXTRACT_SERVICE_CONTRACT_DETAIL,

    REQUEST_CONTACT_SERVICE_CONTRACT,
    REQUEST_CONTACT_SERVICE_CONTRACT_LOADING,
    REQUEST_CONTACT_SERVICE_CONTRACT_SUCCESS,
    REQUEST_CONTACT_SERVICE_CONTRACT_FAILED,

    REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT,
    REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_LOADING,
    REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_SUCCESS,
    REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_FAILED,

    REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_ADDRESS,
    REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_ADDRESS_LOADING,
    REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_ADDRESS_SUCCESS,
    REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_ADDRESS_FAILED,
    REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_ADDRESS_TOGGLE_SOLD_TO,
    REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_ADDRESS_TOGGLE_BILLED_TO,
    REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_ADDRESS_SELECT,

    TOGGLE_SOLD_TO_MODAL,
    TOGGLE_BILLED_TO_MODAL,
    UPDATE_SERVICE_CONTRACT_CONTACT_REQUEST,
    UPDATE_SERVICE_CONTRACT_CONTACT_LOADING,
    UPDATE_SERVICE_CONTRACT_CONTACT_SUCCESS,
    UPDATE_SERVICE_CONTRACT_CONTACT_FAILED,

    UPDATE_SERVICE_CONTRACT_CONTACT_ADDRESS_REQUEST,
    UPDATE_SERVICE_CONTRACT_CONTACT_ADDRESS_LOADING,
    UPDATE_SERVICE_CONTRACT_CONTACT_ADDRESS_SUCCESS,
    UPDATE_SERVICE_CONTRACT_CONTACT_ADDRESS_FAILED,

    SAVE_CONTRACT_NAME_TO_SIDEBAR_LINK,
}
export default types;

import React, {useEffect} from 'react';
import {Box, Breadcrumbs, Container, Grid, makeStyles, Typography} from '@material-ui/core';
import Page from "../Page";
import ServiceContractList from "./list/ServiceContractList";
import {useAuth0} from "@auth0/auth0-react";
import {empty} from "../../../../state/utils/Common";
import globalTheme from "../../../theme/globalTheme";
import {Launch} from "@material-ui/icons";
import Auth0Utils from "../../../../state/utils/Auth0Utils";
import serviceContractsSideBarLinks from "../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json"
import {Link} from "react-router-dom"

/**
 * コンポーネントスタイル
 * @type {(props?: any) => ClassNameMap<"button"|"root">}
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(2)
    },
    button: {
        fontWeight: 900,
    }
}));

/**
 * /service_contractsコアコンポーネント (利用契約一覧)
 *
 * @param t - 翻訳ファイル
 * @param reLoad - コンポーネントのロード中状態
 * @param profile - ログインしたるユーザー情報
 * @param loadServiceContractList - 利用契約取得リクエスト
 * @param activeServiceContractError - 利用契約取得の失敗
 * @param loading - 利用契約の一覧ロード中状態
 * @param serviceContractList - 利用契約一覧
 * @returns {JSX.Element}
 * @constructor
 */
const ServiceContractView = (
    {
        t,
        reLoad,
        profile,

        loadServiceContractList,
        activeServiceContractError,
        loading,
        serviceContractList,

        loadSidebarItems,
    }) => {
    // スタイルのロード
    const classes = useStyles();
    // auth0 アクセストークンの取得
    const {getAccessTokenSilently} = useAuth0();
    // アプリケーションテーマ
    const globalClass = globalTheme._default();
    // 最初利用契約のロード状態。
    useEffect(() => {
        if (reLoad && !empty(profile)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    },
                );
                loadServiceContractList(access_token, profile?.account?.uuid);
            })();
        }
        //
    }, [getAccessTokenSilently, loadServiceContractList, profile, reLoad]);

    /**
     * ナビ項目のロードエベント
     */
    useEffect(() => {
        loadSidebarItems(serviceContractsSideBarLinks.navigation.service_contracts)
    }, [loadSidebarItems])

    return (
        <Page
            className={classes.root}
            title={t('service_contracts.title')}
        >
            <Container maxWidth={false}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h2" style={{float: "left"}}>{t('service_contracts.title')} </Typography>
                        <Typography variant={"body1"} style={{marginLeft: -40, marginTop: 5}}>
                            <a href={process.env.REACT_APP_EBIS_LOGIN_PAGE} target={"_blank"} rel={"noreferrer"} style={{marginLeft: 38}}
                                className={globalClass.a}>
                                {t('service_contracts.login_page')}
                            </a>
                            <Launch style={{fontSize: 15, marginBottom: -3}}>

                            </Launch>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container alignContent={"center"} alignItems={"center"} style={{paddingTop: 6}}>
                    <Grid item xs={12} md={12}>
                        <Breadcrumbs separator={">"} aria-label="breadcrumb">
                            <Link to={serviceContractsSideBarLinks.navigation.top.index.href}>
                                <Typography color="textPrimary" variant={"body2"}>{t('general.top')}</Typography>
                            </Link>
                            <Link to={serviceContractsSideBarLinks.navigation.service_contracts.index.href}>
                                <Typography color="textPrimary" variant={"body2"}>{t('service_contracts.title')}</Typography>
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Box mt={1}>
                    <Typography variant={"subtitle1"} style={{paddingBottom: 5}}>
                        {t('service_contracts.list_note')}
                    </Typography>
                    <ServiceContractList t={t} loading={loading} activeServiceContractError={activeServiceContractError}
                                      serviceContractList={serviceContractList}>
                    </ServiceContractList>
                </Box>
            </Container>
        </Page>
    );
};

export default ServiceContractView;

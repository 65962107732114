import clsx from "clsx";
import {Box, Card, Grid, makeStyles, Typography,} from "@material-ui/core";
import React from "react";
import {currencyFormatter, empty} from "../../../../../../state/utils/Common";
import Loading from "../../../../../atoms/Loading";
import userCommonStyles from "../../../../../theme/user/styles/common";
import globalTheme from "../../../../../theme/globalTheme";
import {ContentsRow} from "./ContentsRow";

/**
 * コンポーネントスタイル
 *
 * @type {(props?: any) => ClassNameMap<"paper"|"root">}
 */
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        border: "1px #CCCCCC solid",
        boxShadow: "none"
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));
/**
 * 通常ユーザー側の申込一覧
 *
 * @param {makeStyles} className - コンポーネントのスタイル
 * @param requests
 * @param {*} t - 翻訳オブジェクト
 * @returns {JSX.Element}
 * @constructor
 */
const ContentsList =
    ({
         className,
         contents,
         contentsLoading,
         t
     }) => {
        const classes = useStyles();
        console.log(contents);
        const common = userCommonStyles.commonStyles();
        const _globalTheme = globalTheme._default();
        return (
            <Card
                className={clsx(classes.root, className)}
            >
                <Box p={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={10}>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                                style={{fontWeight: 900, display: "inline", paddingRight: 20}}
                            >
                                {t('service_contracts.contents.card_header.contract_plan_header')}
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                                style={{display: "inline", paddingRight: 20}}
                            >
                                {t('service_contracts.contents.card_header.contract_amount_total_exl_tax', {total_excl_tax: !empty(contents) ? currencyFormatter(contents.price_per_month) : 0})}
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                                style={{display: "inline", paddingRight: 20}}
                            >
                                {t('service_contracts.contents.card_header.next_update', {contract_update_date: !empty(contents) ? contents.renewal_date : ''})}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                                style={{'textAlign': 'right'}}
                            >
                                {t('general.list_amount', {amount: !empty(contents) && !empty(contents.plans) ? contents.plans.options.length + 1 : 0})}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Grid container className={common.tableHeader}>
                    <Grid item xs={10}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('service_contracts.contents.table_header.plan_item_name')}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('service_contracts.contents.table_header.amount_exl_tax')}
                        </Typography>
                    </Grid>
                </Grid>
                {contentsLoading === true ?
                    <Box className={_globalTheme.list} display="flex" alignItems="center" data-testid={"loading"}>
                        <Grid container
                              direction="row"
                              justify="center"
                              alignItems="center"
                        >
                            <Grid item xs={12}>
                                <Loading/>
                            </Grid>
                        </Grid>
                    </Box> : <></>
                }
                {contentsLoading === false ?
                    !empty(contents) &&
                    !empty(contents.plans) &&
                    !empty(contents.plans.base_plan) &&
                    <ContentsRow content={contents.plans.base_plan} isBasePlan={true} basePlanName={t('service_contracts.contents.basic_plan')}/>
                    : <></>
                }
                {contentsLoading === false ?
                    !empty(contents) &&
                    !empty(contents.plans) &&
                    !empty(contents.plans.options) &&
                    contents.plans.options.map(item => (
                        <ContentsRow content={item}/>
                    )) : <></>
                }
            </Card>
        );
    };

export default ContentsList;

import React from 'react';
import {Box, Grid, makeStyles, Typography} from '@material-ui/core';
import globalTheme from "../../../../../../theme/globalTheme";
import clsx from "clsx";
import TermsAndConditionsModal from "../../../../../user/requests/detail/quote/segments/modal/TermsAndConditionsModal";
import requestTypes from "../../../../../../../state/utils/data/application_request_type_flat.json"
/**
 * /manager/notices コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    },
    button: {
        fontWeight: 900,
    },
    strong: {
        fontWeight: "bold"
    }
}));
/**
 *
 * /noticesのメインコンポーネント
 * @param managerLoadNoticeList
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const MangerNotes = (
    {
        requestId,
        quoteId,
        requestDetail,
        requestQuoteLoading,
        requestQuote,
        termsAndConditionsIsOpen,
        closeTermsAndConditionsModal,
        openTermsAndConditionsModal,
        t
    }) => {
    const classes = useStyles();
    const globalClass = globalTheme._default();
    return (
        <Box className={clsx(classes.root)}>
            <Grid container style={{marginTop: 15}}>
                <Grid item xs={4} sm={1}>
                    <Typography variant="body1"
                                className={classes.strong}>{t('requests.detail.quotes.note')}</Typography>
                </Grid>
                <Grid item xs={8} sm={11} className={globalClass.detail_items}>
                    {/** 新規 マージンなし */}
                    {requestDetail.type === requestTypes.NEW && !requestQuote.is_margin_exist &&
                    <Typography variant="body1" style={{whiteSpace: "pre-line"}}>
                        ・本契約期間は{requestQuote.period} ヶ月間となります。<br/>
                        ・契約期間内でのご解約は、残存期間分のご利用料金を一括請求させていただきます。<br/>
                        ・本契約は{requestQuote.renewal_term}ヶ月単位での自動更新となります。本契約期間満了時において契約を継続しない場合は、契約満了月の15日までに解約申込書をご提出ください。（同月20日にて契約終了となります。）<br/>
                        ・ライトプランまたはスタンダードプランの基本計測上限枠と従量課金の料金に関しては、<a
                        href='https://support.ebis.ne.jp/tool/introduction/quotation_pricelist_p.pdf'
                        target='_blank'>https://support.ebis.ne.jp/tool/introduction/quotation_pricelist_p.pdf</a>をご覧下さい。<br/>
                        ・消費税は別途申し受けます。<br/>
                        {requestQuote.terms}</Typography>
                    }

                    {/** 新規 マージンあり */}
                    {requestDetail.type === requestTypes.NEW && requestQuote.is_margin_exist &&
                    <Typography variant="body1" style={{whiteSpace: "pre-line"}}>
                        ・本契約期間は{requestQuote.period}ヶ月間ですが、最低利用期間は3ヶ月間とし、アカウント開設日（当初の契約開始日）から4ヶ月目以降は単月単位でのご解約が可能です。 <br/>
                        ・アカウント開設日から3ヶ月以内にご解約される場合、最低利用期間終了日までの残存期間分のご利用料金を一括請求させていただきます。<br/>
                        ・契約期間満了月の翌月から{requestQuote.renewal_term}ヶ月単位での自動更新となります。<br/>
                        ・契約を継続しない場合は、解約希望月の15 日までに解約申込書をご提出ご提出ください。（同月20日にて契約終了となります。） <br/>
                        ・エージェンシープランまたはスタンダードプランご契約後に、新規受付終了済のプランに変更することはできません。 <br/>
                        ・エージェンシープランまたはスタンダードプランの基本計測上限枠と従量課金の料金に関しては、<a
                        href='https://support.ebis.ne.jp/tool/introduction/quotation_pricelist_p.pdf'
                        target='_blank'>https://support.ebis.ne.jp/tool/introduction/quotation_pricelist_p.pdf</a>をご覧下さい。<br/>
                        ・消費税は別途申し受けます。<br/>
                        <br/>
                        {requestQuote.terms}</Typography>
                    }

                    {/** 変更 マージンなし */}
                    {requestDetail.type === requestTypes.CHANGE && !requestQuote.is_margin_exist &&
                    <Typography variant="body1" style={{whiteSpace: "pre-line"}}>
                        ・本契約期間は{requestQuote.period}ヶ月間となります。<br/>
                        ・契約期間内でのご解約は、残存期間分のご利用料金を一括請求させていただきます。<br/>
                        ・契約期間満了月の翌月から{requestQuote.renewal_term}ヶ月単位での自動更新となります。<br/>
                        ・契約更新後の契約期間（{requestQuote.initial_term}ヶ月間）内でご解約される場合、残存期間分のご利用料金を一括請求させていただきます。<br/>
                        ・契約を継続しない場合は、契約満了月の15 日までに解約申込書をご提出ください。（同月20日にて契約終了となります。）<br/>
                        ・ライトプランまたはスタンダードプランご契約後に、新規受付終了済のプランに変更することはできません。<br/>
                        ・ライトプランまたはスタンダードプランの基本計測上限枠と従量課金の料金に関しては<a
                        href='https://support.ebis.ne.jp/tool/introduction/quotation_pricelist_p.pdf'
                        target='_blank'>https://support.ebis.ne.jp/tool/introduction/quotation_pricelist_p.pdf</a>をご覧下さい。<br/>
                        ・消費税は別途申し受けます。<br/>
                        {requestQuote.terms}</Typography>
                    }

                    {/** 変更 マージンあり */}
                    {requestDetail.type === requestTypes.CHANGE && requestQuote.is_margin_exist &&
                    <Typography variant="body1" style={{whiteSpace: "pre-line"}}>
                        ・本契約期間は{requestQuote.period}ヶ月間ですが、最低利用期間は3ヶ月間とし、アカウント開設日（当初の契約開始日）から4ヶ月目以降は単月単位でのご解約が可能です。<br/>
                        ・アカウント開設日から3ヶ月以内にご解約される場合、最低利用期間終了日までの残存期間分のご利用料金を一括請求させていただきます。<br/>
                        ・契約期間満了月の翌月から{requestQuote.renewal_term}ヶ月単位での自動更新となります。<br/>
                        ・契約を継続しない場合は、解約希望月の15 日までに解約申込書をご提出ご提出ください。（同月20日にて契約終了となります。）<br/>
                        ・エージェンシープランまたはスタンダードプランご契約後に、新規受付終了済のプランに変更することはできません。<br/>
                        ・エージェンシープランまたはスタンダードプランの基本計測上限枠と従量課金の料金に関しては、<a
                        href='https://support.ebis.ne.jp/tool/introduction/quotation_pricelist_p.pdf'
                        target='_blank'>https://support.ebis.ne.jp/tool/introduction/quotation_pricelist_p.pdf</a>をご覧下さい。<br/>
                        ・消費税は別途申し受けます。<br/>
                        {requestQuote.terms}</Typography>
                    }

                </Grid>
            </Grid>
            <Grid container style={{marginTop: 15}}>
                <Grid item xs={4} sm={1}>
                    <Typography variant="body1"
                                className={classes.strong}>{t('requests.detail.quotes.contract_warning')}</Typography>
                </Grid>
                <Grid item xs={8} sm={11} className={globalClass.detail_items}
                      onClick={() => openTermsAndConditionsModal()}>
                    <Typography variant="overline"
                                style={{cursor: "pointer", textDecoration: "underline"}}>表示する</Typography>
                </Grid>
            </Grid>
            <TermsAndConditionsModal
                requestQuote={requestQuote}
                isOpen={termsAndConditionsIsOpen}
                handleClose={closeTermsAndConditionsModal}
                t={t}
            />
        </Box>
    );
}

export default MangerNotes;
